import React, { useState } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, Grid, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { log } from "console";




const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    color: 'inherit', // Text color
    width: '100%',
    backgroundColor: 'transparent', // Remove background
    fontSize: '16px', // Set font size
    padding: '0', // Set padding
    '&:focus': {
      outline: 'none', // Remove outline on focus
    },
  },
}));


const TechnicalSpecs = ({ technicalSpecs, handleTechnicalSpecsChange, isEditable, aircraft }: any) => {
  // console.log({aircraft})

  const classes = useStyles()
  // console.log("user input",userInput)
  return (
    <>
      {
        isEditable ? <Grid container className="aircraft-details-container">

          <Grid item xs={12} className="details-table-container">
            <Grid container className="detail-item">

              <Grid item xs={3} className='item top-left'>
                <Typography variant='body1' component='p'>
                  Exterior
                </Typography>
              </Grid>
              <Grid item xs={9} className='item top-right'>
                <Grid container>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      type="number"
                      placeholder="Length"
                      name="exteriorLength"
                      // value="hello"
                      value={technicalSpecs?.exteriorLength}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                     <span className='label'>Length (ft)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      type="number"
                      placeholder="Wingspan"
                      name="exteriorWingspan"
                      value={technicalSpecs?.exteriorWingspan}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                          <span className='label'>Wingspan (ft)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      type="number"
                      placeholder="Height"
                      name="exteriorHeight"
                      value={technicalSpecs?.exteriorHeight}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                      <span className='label'>Height (ft)</span>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className='item'>
                <Typography variant='body1' component='p'>
                  Range
                </Typography>
              </Grid>
              <Grid item xs={9} className='item'>
              <Grid container>
                <Grid item xs={12}>
                <InputBase
                  className={classes.input}
                  //   placeholder="Brand Name"
                  name="range"
                  value={technicalSpecs?.range}
                  onChange={handleTechnicalSpecsChange}
                  inputProps={{ 'aria-label': 'custom input' }}
                />
                <span className='label'>Range (Km)</span>
                </Grid>
              </Grid>
                {/* <Typography variant='body1' component='p'>
            BMW
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className='item'>
                <Typography variant='body1' component='p'>
                 Speed
                </Typography>
              </Grid>
              <Grid item xs={9} className='item'>
                <Grid container>
                  
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Aircraft speed"
                      name="speed"
                      value={technicalSpecs?.speed}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                      <span className='label'>Aircraft speed (Km/Hr)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="High speed"
                      name="highSpeed"
                      value={technicalSpecs?.highSpeed}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                      <span className='label'>High Speed (Km/Hr)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Typical cruise speed"
                      name="typicalCruiseSpeed"
                      value={technicalSpecs?.typicalCruiseSpeed}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                     <span className='label'>Typical Cruise Speed (Km/Hr)</span>
                  </Grid>

                </Grid>
               
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className='item'>
                <Typography variant='body1' component='p'>
                  Engines
                </Typography>
              </Grid>
              <Grid item xs={9} className='item'>
                <Grid container>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Engine model"
                      name="engineModel"
                      value={technicalSpecs?.engineModel}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                          <span className='label'>Engine Model</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Thrust"
                      name="thrust"
                      value={technicalSpecs?.thrust}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                    <span className='label'>Thrust (KN)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Flat rated to"
                      name="flatRelatedTo"
                      value={technicalSpecs?.flatRelatedTo}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                       <span className='label'>Flat Rated To</span>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className='item'>
                <Typography variant='body1' component='p'>
                  Airfield performance
                </Typography>
              </Grid>
              <Grid item xs={9} className='item'>
                <Grid container>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Take-off distance"
                      name="takeOffDistance"
                      value={technicalSpecs?.takeOffDistance}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                       <span className='label'>Take Off Distance (ft)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Landing distance"
                      name="landingDistance"
                      value={technicalSpecs?.landingDistance}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                        <span className='label'>Landing Distance (ft)</span>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className='item'>
                <Typography variant='body1' component='p'>
                  Avionics
                </Typography>
              </Grid>
              <Grid item xs={9} className='item'>
                <InputBase
                  className={classes.input}
                  //   placeholder=""
                  name="avionics"
                  value={technicalSpecs?.avionics}
                  onChange={handleTechnicalSpecsChange}
                  inputProps={{ 'aria-label': 'custom input' }}
                />
                
              </Grid>
            </Grid>
            <Grid container className="detail-item">
              <Grid item xs={3} className='item bottom-left'>
                <Typography variant='body1' component='p'>
                  Operating altitude
                </Typography>
              </Grid>
              <Grid item xs={9} className='item bottom-right'>
                <Grid container>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Maximum operation alt"
                      name="maximumOperationAlt"
                      value={technicalSpecs?.maximumOperationAlt}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                       <span className='label'>Maximum Operation Alt (ft)</span>
                  </Grid>
                  <Grid item xs={4}>
                    <InputBase
                      className={classes.input}
                      placeholder="Initial cruise alt"
                      name="initialCruiseAlt"
                      value={technicalSpecs?.initialCruiseAlt}
                      onChange={handleTechnicalSpecsChange}
                      inputProps={{ 'aria-label': 'custom input' }}
                    />
                           <span className='label'>Initial Cruise Alt (ft)</span>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
          :
          <Grid container className="aircraft-details-container">

            <Grid item xs={12} className="details-table-container">
              <Grid container className="detail-item">

                <Grid item xs={3} className='item top-left'>
                  <Typography variant='body1' component='p'>
                    Exterior
                  </Typography>
                </Grid>
                <Grid item xs={9} className='item top-right'>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.exteriorLength || "--"}
                      </Typography>
                      <span className='label'>Length (ft)</span>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.exteriorWingspan || "--"}
                      </Typography>
                      <span className='label'>Wingspan (ft)</span>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.exteriorHeight || "--"}
                      </Typography>
                      <span className='label'>Height (ft)</span>
                    
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <Grid container className="detail-item">
                <Grid item xs={3} className='item'>
                  <Typography variant='body1' component='p'>
                    Range
                  </Typography>
                </Grid>
                <Grid item xs={9} className='item'>
                 
               <Grid container>
                <Grid item xs={12}>
                <Typography variant='body1' component='p'>
                    {aircraft?.technicalSpecs?.range || "--"}
                  </Typography>
                  <span className='label'>Range (Km)</span>
                </Grid>
               </Grid>
                </Grid>
              </Grid>
              <Grid container className="detail-item">
                <Grid item xs={3} className='item'>
                  <Typography variant='body1' component='p'>
                   Speed
                  </Typography>
                  
                </Grid>
                <Grid item xs={9} className='item'>
                  <Grid container>
                    
                    <Grid item xs={4}>
                     
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.speed || "--"}
                      </Typography>
                      <span className='label'>Aircraft Speed (Km/Hr)</span>
                    </Grid>
                    <Grid item xs={4}>
                     
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.highSpeed || "--"}
                      </Typography>
                      <span className='label'>High Speed (Km/Hr)</span>
                    </Grid>
                    <Grid item xs={4}>
                     
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.typicalCruiseSpeed || "--"}
                      </Typography>
                      <span className='label'>Typical Cruise Speed (Km/Hr)</span>
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
              <Grid container className="detail-item">
                <Grid item xs={3} className='item'>
                  <Typography variant='body1' component='p'>
                    Engines
                  </Typography>
                </Grid>
                <Grid item xs={9} className='item'>
                  <Grid container>
                    <Grid item xs={4}>
                     
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.engineModel || "--"}
                      </Typography>
                      <span className='label'>Engine Model</span>
                    </Grid>
                    <Grid item xs={4}>
                     
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.thrust || "--"}
                      </Typography>
                      <span className='label'>Thrust (KN)</span>
                    </Grid>
                    <Grid item xs={4}>
                    
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.flatRelatedTo || "--"}
                      </Typography>
                      <span className='label'>Flat Rated To</span>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <Grid container className="detail-item">
                <Grid item xs={3} className='item'>
                  <Typography variant='body1' component='p'>
                    Airfield performance
                  </Typography>
                </Grid>
                <Grid item xs={9} className='item'>
                  <Grid container>
                    <Grid item xs={4}>
                    
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.takeOffDistance || "--"}
                      </Typography>
                      <span className='label'>Take Off Distance (ft)</span>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.landingDistance || "--"}
                      </Typography>
                      <span className='label'>Landing Distance (ft)</span>
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>
              <Grid container className="detail-item">
                <Grid item xs={3} className='item'>
                  <Typography variant='body1' component='p'>
                    Avionics
                  </Typography>
                </Grid>
                <Grid item xs={9} className='item'>
                
                  <Typography variant='body1' component='p'>
                    {aircraft?.technicalSpecs?.avionics || "--"}
                  </Typography>
                  {/* <span className='label'>Avionics</span> */}
                </Grid>
              </Grid>
              <Grid container className="detail-item">
                <Grid item xs={3} className='item bottom-left'>
                  <Typography variant='body1' component='p'>
                    Operating altitude
                  </Typography>
                </Grid>
                <Grid item xs={9} className='item bottom-right'>
                  <Grid container>
                    <Grid item xs={4}>
                     
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.maximumOperationAlt || "--"}
                      </Typography>
                      <span className='label'>Maximum Operation Alt (ft)</span>
                    </Grid>
                    <Grid item xs={4}>
                  
                      <Typography variant='body1' component='p'>
                        {aircraft?.technicalSpecs?.initialCruiseAlt || "--"}
                      </Typography>
                      <span className='label'>Initial Cruise Alt (ft)</span>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
      }
    </>
  )
}

export default TechnicalSpecs;