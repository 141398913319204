import React, { useEffect, useState } from 'react'
import { Typography, Grid, Button, TextField, InputAdornment } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AirportTable from './AirportTable';
import { serviceProvider } from '../../provider/serviceProvider';
import Loader from '../loader/Loader';
import CommonModal from '../modal/CommonModal';
import AddAirportForm from './AddAirportForm';
import FilterSection from './FilterSection';
import SearchBar from '../searchBar/SearchBar';
import { toast } from 'react-toastify';

const AirportManagement: React.FC = () => {
  // const [loader, setLoader] = React.useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,

  });



  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value || "all"; 
    const query = `airportName=${searchQuery}&city=all&state=all&runwayLength=0&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`

    setPageState(prev=>({...prev,loader:true}))
    serviceProvider.airportService.getSearchResults(query).then((res) => {
      if (res.responseCode === 200) {
       
        setPageState(prev=>({...prev,loader:false,data:res.data.data,totalPages:res.data.totalRecordCount}))
      } else {
        toast.error(res?.message||"Something Went Wrong !!")
      }
      

    }).catch((err) => {
      console.log({ err })
      setPageState(prev=>({...prev,loader:false}))
      // setLoader(false)
    })
  }



  const getAllAirports = (param:any) => {
    
    setPageState(prev=>({...prev,loader:true}))
    
    serviceProvider.airportService.getSearchResults(param).then((res) => {
     
      if (res.responseCode === 200) {
        
        setPageState(prev=>({...prev,loader:false,data:res.data.data,totalPages:res.data.totalRecordCount}))
      } else {
       
        toast.error(res?.message||"Something Went Wrong !!")
      }
      setPageState(prev=>({...prev,loader:false}))
      
    }).catch((err) => {
      setPageState(prev=>({...prev,loader:false}))
      toast.error(err?.data?.message || "Something Went Wrong !!")
    })
  }

  useEffect(() => {
    const query = `airportName=${searchQuery || "all"}&city=all&state=all&runwayLength=0&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
    // const query = `airportName=all&city=all&state=all&runwayLength=0&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=${pageState.page}&limit=${pageState.pageSize}&order=updatedTs.desc&profile=BASIC`
    getAllAirports(query)
  }, [pageState.page,pageState.pageSize,searchQuery])

  const modalBody = <AddAirportForm handleCloseModal={handleCloseModal} getAllAirports={getAllAirports} />;


  return (
    <>
      <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody}  />
      <Grid container spacing={2} className="airports-container">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5} md={5} className="fleet-heading">
              <Typography variant='h6' component='h6'>List of Airports</Typography>
            </Grid>
            <Grid item xs={7} md={7}>
              <SearchBar 
              value={searchQuery}
              // onChange={handleSearch}
              onChange={(e:any)=>setSearchQuery(e.target.value)}
               placeholder="Search Airports" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" className="rounded-btn-fill" onClick={handleOpenModal}>Add Airport</Button>

        </Grid>

        <Grid item xs={12}>
          {/* <FilterSection  /> */}
        </Grid>

        <Grid item xs={12}>
          <AirportTable pageState={pageState} setPageState={setPageState} getAllAirports={getAllAirports}  />
        </Grid>

      </Grid>
    </>
  )
}

export default AirportManagement