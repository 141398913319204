
import React,{useState} from "react"

import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';

import { serviceProvider } from "../../provider/serviceProvider";
import {toast} from "react-toastify";
import ButtonComponent from "../common/ButtonComponent";

const DeactivateEmptyLegFlight = ({handleCloseDeactivateFlightModal,flightId,pageState,getEmptyLegFlights}:any) => {
const [loader,setLoader]=useState(false)

    const handleDeactivateFlight=(flightId :any)=>{
     
setLoader(true)
        // const payload = {status:false}
        const param = `${flightId}/${false}`
    // setLoader(true);
    serviceProvider.flightService
      .put(param,{})
      .then((res) => {
        // console.log({ res })
        // setLoader(false);
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseDeactivateFlightModal()
          const query = `flightId=NA&flightType=EMPTYLEG&isActive=true`;
      getEmptyLegFlights(query);
          // setTimeout(()=>{
          //   window.location.href="empty-leg-management"
          // },100)
          // emptyInputFields()
          // setCabinSpecs()
        }else{
            toast.error(res?.message || "Something Went Wrong!!")
        }
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        toast.error(err?.data?.message || "Something Went Wrong !!");
        // setLoader(false);
        //   emptyInputFields()
        console.log({ err });
      });
      }
    
    return (
        <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6" textAlign="center">Confirm to Deactivate Flight?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={2} className="btn-container">
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <Button variant="outlined" className="rounded-btn-outlined" onClick={handleCloseDeactivateFlightModal}>Cancel</Button>
            </Grid>
            <Grid item xs={6}>
              <ButtonComponent loader={loader} disabled={loader} fullWidth={false} onClick={()=>handleDeactivateFlight(flightId)} label="Confirm" />
              {/* <Button >Confirm</Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };


  export default DeactivateEmptyLegFlight;