import { useState } from 'react';
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../configuration/config.json";
import { ValidationUtiltiy } from '../utility/ValidationUtility';
import { toast } from 'react-toastify';
// import { enqueueSnackbar } from 'notistack';

// interface imageUploaderProps{
//   folderLocation:string
  
// }
const useImageUploader = (folderLocation:any) => {
  const [loader, setLoader] = useState(false);


  const handleImageChange = (event: any,updateUserInput:any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const isValid = ValidationUtiltiy.isValidImg(fileObj.name);
    if (isValid) {
      // setFile(fileObj);
    //   let newFileName =
    //     // userDetails?.uid +
    //     "123" + "_" + event.target.files[0].name.replace(/\..+$/, "");
    //     // uploadProfileImage(fileObj, newFileName);
    //     uploadImage(fileObj, newFileName,updateUserInput);

    let newFileName = event.target.files[0].name.replace(/\..+$/, "") + Date.now();
    uploadImage(fileObj, newFileName,updateUserInput);
    } else {
      toast.error("Something went wrong !")
      // alert("error oocrued")
      // setErrorMessages({
      //   ...errorMessages,
      //   uploadTeamLogo: "Not a valid File",
      // });
    }
  };

  const uploadImage = (file:any, newFile:any,updateUserInput:any) => {
    setLoader(true);
    const ReactS3Client = new S3(awsConfig);

    ReactS3Client.uploadFile(
      file,
    //   folderLocation + '/' + newFile
         '/'+ newFile
    )
      .then((data) => {
        if (data.status === 204) {
          updateUserInput(data.location);
          
          toast.success('Image uploaded successfully.');
        } else {
            toast.error("Something went wrong !")
            // alert('File Not Uploaded !!');
          // setErrorMessages({
          //   ...errorMessages,
          //   uploadTeamLogo: 'Something Went Wrong!',
          // });
        }
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong !")
        setLoader(false);
        // console.log({ err });
      });
  };

  return {loader, handleImageChange };
};

export default useImageUploader;