import React, { useRef } from 'react';
import { Card, Grid } from '@mui/material';
import addImageIcon from '../../assets/icons/add-image.svg';

const ImageUploadComponent = ({  chooseFileRef,handleChooseFile, handleFileChange,isMultiple=false }:any) => {
    return (

        <Card className='image-card'>
        <input type="file" multiple={isMultiple} accept="image/*" ref={chooseFileRef} style={{ display: "none" }} onChange={handleFileChange} />
        <img src={addImageIcon} alt="img" onClick={handleChooseFile} style={{cursor:"pointer"}} className='add-img-icon' />
    </Card>

    );
};

export default ImageUploadComponent;
