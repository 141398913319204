import { Grid } from '@mui/material'
import React from 'react'
import Loader from '../loader/Loader'
import { IQuotation } from '../../interface/IQuotation'
import QuotationCard from './QuotationCard'
import NoResult from '../noResult/NoResult'

const CancelledQuotation = ({loader,quotations}:any) => {
  return (
    <Grid container spacing={4}>
              {
                loader ?
                  new Array(3).fill(0).map((_) => {
                    return <Grid item xs={12} md={6}>
                      <Loader height='300px' width='100%' />
                    </Grid>
                  }) : quotations.length >= 1 && loader === false ? quotations?.map((quotation: IQuotation) => {
                    return <Grid item xs={12} md={6} display='flex'>
                      <QuotationCard index={3} quotation={quotation} />
                    </Grid>
                  }) :
                    <Grid item xs={12}>
                      <NoResult title={"Cancelled Quotation"} />
                    </Grid>
              }
            </Grid>
  )
}

export default CancelledQuotation