import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import InputFieldComponent from "../common/InputFieldComponent";
import { serviceProvider } from "../../provider/serviceProvider";
import ButtonComponent from "../common/ButtonComponent";
import { toast } from "react-toastify";
import { formateCurrency } from "../../utility/formateCurreny";
import { useLocation, useNavigate } from "react-router-dom";

const PricingDetails = ({ bookingDetails }: any) => {
  const [loader, setLoader] = useState(false);
  const [giftRewardPoints, setGiftRewardPoints] = useState<any>(null);
  const [message, setMessage] = useState<any>("");
const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  // console.log({ giftRewardPoints });

  const handleConfirmBooking = (bookingId: any, message: any) => {
    const param = `${bookingId}/CONFIRMED?bookingConfirmationMsg=${message}`;
    handleBooking(param)
  };
  const handleCompleteBooking = (bookingId: any, giftRewardPoints: any) => {
    const param = `${bookingId}/COMPLETE/complete?BmjRewardPointsForNextFlight=${giftRewardPoints}`;
    handleBooking(param)
  };

  const handleBooking=(param:any)=>{
    setLoader(true);
    serviceProvider.bookingService
      .put(param, "")
      .then((res: any) => {
        if (res.responseCode === 200) {
          toast.success(res.message);
          setGiftRewardPoints(null)

          const indexNumber =  bookingDetails?.status === "PENDING" ? 0 :1
        //  setTimeout(()=>{
        //   window.location.href =`/bookings-management?index=${indexNumber}`
        //  },100)
        navigate(`/bookings-management?index=${indexNumber}`)
        } else {
          toast.error(res?.message || "Something Went Wrong!");
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong!");
      });
  }

  return (
    <Card className="estimated-quotation-card">
      <Grid container rowSpacing={2} className="estimated-quotation-container">
        <Grid item xs={12} width="100%">
          <Grid container className="estimated-amount">
            <Grid item xs={8}>
              <Typography variant="body1" textAlign="left" component="p">
                Final Booking Amount
              </Typography>
            </Grid>
            <Grid item xs={4}>
              {" "}
              <Typography variant="body1" textAlign="right" component="p">
                {  formateCurrency(bookingDetails?.finalPrice) || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="estimated-amount">
            <Grid item xs={8}>
              <Typography
                variant="body1"
                textAlign="left"
                mt="10px"
                component="p"
              >
                Latest Booking Status
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                textAlign="right"
                mt="10px"
                component="p"
              >
                {bookingDetails?.status || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="estimated-amount">
            <Grid item xs={8}>
              <Typography
                variant="body1"
                textAlign="left"
                mt="10px"
                component="p"
              >
                Requested Reward Points
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                textAlign="right"
                mt="10px"
                component="p"
              >
                {bookingDetails?.quotationDetails?.requestedBmjRewardPoints ||
                  "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="estimated-amount">
            <Grid item xs={8}>
              <Typography
                variant="body1"
                textAlign="left"
                mt="10px"
                component="p"
              >
                Approved Reward Points
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                textAlign="right"
                mt="10px"
                component="p"
              >
                {bookingDetails?.quotationDetails?.approvedBmjRewardPoints ||
                  "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className="estimated-amount">
            <Grid item xs={8}>
              <Typography
                variant="body1"
                textAlign="left"
                mt="10px"
                component="p"
              >
                Available Reward Points
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                textAlign="right"
                mt="10px"
                component="p"
              >
                {bookingDetails?.userDetails?.totalBmjRewardPoints  || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} width="80%">

          {
            bookingDetails?.status === "PENDING" ?   <InputFieldComponent
            type="text"
            value={message}
            maxLength={100}
            onChange={(e: any) => setMessage(e.target.value)}
            placeholder="Enter UTR Number OR Enter message"
          /> :   <InputFieldComponent
          type="number"
          value={giftRewardPoints}
          onChange={(e: any) => setGiftRewardPoints(e.target.value)}
          placeholder="Enter Reward Points for Next Flight"
        />
          }
        
        </Grid>

        <Grid item xs={12}>
          {
            bookingDetails?.status === "PENDING" ?  <ButtonComponent
            loader={loader}
            disabled={loader}
            label={"Confirm Booking"}
            onClick={() =>
              handleConfirmBooking(bookingDetails?.bookingId, message)
            }
          /> :  <ButtonComponent
          loader={loader}
          disabled={loader}
          label={"Complete Booking"}
          onClick={() =>
            handleCompleteBooking(bookingDetails?.bookingId, giftRewardPoints)
          }
        />
          }
         
          {/* Complete Booking */}
          {/* </Button> */}
        </Grid>
      </Grid>
    </Card>
  );
};

export default PricingDetails;
