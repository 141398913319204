export const formatHours = (hours:number) => {
    // Calculate hours and minutes
    const totalMinutes = hours * 60;
    const formattedHours = Math.floor(totalMinutes / 60);
    const formattedMinutes = Math.floor(totalMinutes % 60);
  
    // Create the formatted string
    const formattedString = `${formattedHours} hr ${formattedMinutes} min`;
  
    return formattedString;
  };