
import { IService } from '../../interface/IService';
import { IUser } from '../../interface/IUser';
import StatsStorage from '../../storage/statsStorage';
export class StatsMockService implements IService<any> {
    storage: StatsStorage;
    constructor() {
        this.storage = new StatsStorage();
    }
    put(param: any, entity: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    post(entity: IUser): Promise<any> {
        throw new Error('Method not implemented.');
    }
    patch(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    delete(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getList(): Promise<IUser[]> {
        return new Promise((resolve) => {
            resolve(this.storage.get())
        });
    }
    get(param: any): Promise<IUser> {
        return new Promise((resolve) => {
            resolve(this.storage.getByScenario(param as string)[0]);
        });
    }
}