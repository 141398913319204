import React, { useState } from "react";
import {
  Typography,
  Card,
  Grid,
  Button,
  TextField,
  InputAdornment,
  Box,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import dividerPlane from "../../assets/icons/divider-plane.svg";
import { useNavigate } from "react-router-dom";
import { CustomTabPanel, a11yProps } from "../Tab/CustomTabPanel";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../utility/formateDateAndTime";
import { serviceProvider } from "../../provider/serviceProvider";
import { toast } from "react-toastify";
import ButtonComponent from "../common/ButtonComponent";
import CommonModal from "../modal/CommonModal";
import { formateCurrency } from "../../utility/formateCurreny";
import ItineraryDetail from "../quotationManagement/ItineraryDetail";

const BookingCard = ({ bookingDetails }: any) => {
  const navigate = useNavigate();



  const [loader, setLoader] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);


  let parsedOrderOfCities;

  parsedOrderOfCities =
    bookingDetails &&
    bookingDetails?.itineraryDetails &&
    bookingDetails?.itineraryDetails?.orderOfCities &&
    JSON.parse(bookingDetails?.itineraryDetails?.orderOfCities);

  // console.log({ parsedOrderOfCities });

  const orderOfCitiesArr: any =
    parsedOrderOfCities !== undefined &&
    parsedOrderOfCities !== null &&
    Object.values(parsedOrderOfCities);

  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);


  const handleConfirmBooking = (bookingId: any) => {
    navigate(`/confirm-booking/${bookingId}?index=0`);
  };
  const handleCompleteBooking = (bookingId: any) => {
    navigate(`/complete-booking/${bookingId}?index=1`);
  };

  
  const handleFailedBooking = (bookingId: any) => {
    setLoader(true);
    const param = `${bookingId}/FAILED`;
    serviceProvider.bookingService
      .put(param, "")
      .then((res: any) => {
        if (res.responseCode === 200) {
          handleCloseConfirmModal();
          toast.success(res.message);
          // setTimeout(()=>{
            window.location.reload();
          // },100)
        } else {
          toast.error(res?.message || "Something Went Wrong!");
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong!");
      });
  };

  const confirmModalBody = (
    <Grid container rowSpacing={4} className='confirm-request-modal'>
        <Grid item xs={12}>
            <Typography variant='h6' component='h6'>Cancel Booking</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant="body1" component="p" textAlign="center">Are you sure you want to cancel this booking?</Typography>
        </Grid>
        <Grid item xs={12} className='btn-container'>
            <ButtonComponent className="rounded-btn-outlined" fullWidth={false} label="Cancel" onClick={handleCloseConfirmModal} />
            <ButtonComponent fullWidth={false} loader={loader} disabled={loader} label="Confirm" onClick={() => handleFailedBooking(bookingDetails?.bookingId)} />

        </Grid>
    </Grid>
)

  return (
  <>
    <CommonModal open={openConfirmModal} handleCloseModal={handleCloseConfirmModal} modalBody={confirmModalBody} />
    <Card className="quotation-card">
      <Grid container className="user-details">
        {/* <Grid item xs={12} className="item">
          <Typography variant="body1" component="span">
            Booking ID :
          </Typography>
          <Typography variant="body1" component="span">
            {bookingDetails?.bookingId || "--"}
          </Typography>
        </Grid> */}
         <Grid item xs={12}>
         <Box sx={{display:"flex",alignItems:"center",gap:"10px"}}>
         <p className="custom-chip">
            {bookingDetails?.itineraryDetails?.itineraryType || "--"}
          </p>
          <p className="custom-chip">
            {bookingDetails?.quotationDetails?.flightType || "--"}
          </p>
         </Box>
          {/* <Chip label="primary" color="primary"  className="custom-chip" /> */}
        </Grid>
        <Grid item xs={12} className="item">
          <Typography variant="body1" component="span">
            User name :
          </Typography>
          <Typography variant="body1" component="span">
            {bookingDetails?.userDetails?.fullName || "--"}
          </Typography>
        </Grid>
        <Grid item xs={12} className="item">
          <Typography variant="body1" component="span">
            Email :
          </Typography>
          <Typography variant="body1" component="span">
            {bookingDetails?.userDetails?.email || "--"}
          </Typography>
        </Grid>
        <Grid item xs={12} className="item">
          <Typography variant="body1" component="span">
            Mobile No :
          </Typography>
          <Typography variant="body1" component="span">
            {bookingDetails?.userDetails?.mobileNumber || "--"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="quotation-details">
        {/* <Grid item xs={8} className="itinerary-details">
          <Grid container>
            <Grid item xs={12} className="grid-item">
              <Typography variant="body2" component="p">
                {getFormattedDate(bookingDetails?.itineraryDetails?.startDt)}
              </Typography>
              <Typography variant="body2" component="p">
                {getFormattedDate(bookingDetails?.itineraryDetails?.endDt)}
              </Typography>
            </Grid>
            <Grid item xs={12} className="grid-item">
              <Typography variant="body2" component="p">
                {getFormattedTime(bookingDetails?.itineraryDetails?.startDt)}
              </Typography>
              <Typography variant="body2" component="p">
                {getFormattedTime(bookingDetails?.itineraryDetails?.endDt)}
              </Typography>
        
            </Grid>
            
            <Grid item xs={12}>
              <Grid container className="grid-item">
                <Grid item xs={2}>
                  <Typography variant="body2" textAlign="left" component="p">
                    {bookingDetails?.itineraryDetails?.startAirport?.iataCode ||
                      "--"}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <img src={dividerPlane} alt="plane" width="100%" />
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" textAlign="right" component="p">
                    {bookingDetails?.itineraryDetails?.endAirport?.iataCode ||
                      "--"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={8} className="itinerary-details">
              {/* {bookingDetails?.itineraryDetails?.itineraryType !== "MULTICITY" && (
                <ItineraryDetail
                  itineraryDetails={bookingDetails?.itineraryDetails}
                />
              )} */}
{/* 
              {bookingDetails?.itineraryDetails?.itineraryType === "MULTICITY" &&
                orderOfCitiesArr?.length &&
                orderOfCitiesArr?.map((city: any) => {
                  const startAirport =
                    city?.startAirportDetails &&
                    JSON.parse(city?.startAirportDetails);
                  const endAirport =
                    city?.endAirportDetails &&
                    JSON.parse(city?.endAirportDetails);
                  const updatedCity = { ...city, startAirport, endAirport };
                  console.log({ updatedCity });
                  return <ItineraryDetail itineraryDetails={updatedCity} />;
                })} */}


{bookingDetails?.pathDetails?.itineraryPathDetails?.length >= 1 &&
                  bookingDetails?.pathDetails?.itineraryPathDetails
                    .filter(
                      (itineraryPath: any) =>
                        itineraryPath?.flightType === "SEARCHED"
                    )
                    .map((filteredItineraryPath: any) => (
                      <React.Fragment
                        key={filteredItineraryPath?.itineraryId}
                      >
                        <ItineraryDetail
                          itineraryDetails={filteredItineraryPath}
                        />
                      </React.Fragment>
                    ))}
              {/* // <Grid container>
          //   <Grid item xs={12} className="grid-item">
          //     <Typography variant='body2' component="p">{getFormattedDate(quotation?.itineraryDetails?.startDt)}</Typography>
          //     <Typography variant='body2' component="p">{getFormattedDate(quotation?.itineraryDetails?.endDt)}</Typography>
          //   </Grid>
          //   <Grid item xs={12} className="grid-item">
          //     <Typography variant='body2' component="p">{getFormattedTime(quotation?.itineraryDetails?.startDt)}</Typography>
          //     <Typography variant='body2' component="p">{getFormattedTime(quotation?.itineraryDetails?.endDt)}</Typography>
          //   </Grid>
          //   <Grid item xs={12} >
          //     <Grid container className="grid-item">
          //       <Grid item xs={2}>
          //         <Typography variant='body2' textAlign="left" component="p">{quotation?.itineraryDetails?.startAirport?.icaoCode || "--"}</Typography>
          //       </Grid>
          //       <Grid item xs={8}>
          //         <img src={dividerPlane} alt="plane" width="100%" />
          //       </Grid>
          //       <Grid item xs={2}>
          //         <Typography variant='body2' textAlign="right" component="p">{quotation?.itineraryDetails?.endAirport?.icaoCode || "--"}</Typography>
          //       </Grid>
          //     </Grid>



          //   </Grid>
          //   <Grid item xs={12} className="grid-item" >
          //     <Typography variant='body2' component="p" sx={{wordWrap: "break-word"}}>{quotation?.itineraryDetails?.startAirport?.name || "--"}</Typography>
          //     <Typography variant='body2' component="p" sx={{wordWrap: "break-word",textAlign:"right"}}>{quotation?.itineraryDetails?.endAirport?.name || "--"}</Typography>
          //   </Grid>
          //   <Grid item xs={12} className="grid-item">
          //     <Typography variant='body2' component="p">{quotation?.itineraryDetails?.startAirport?.city || "--"}</Typography>
          //     <Typography variant='body2' component="p">{quotation?.itineraryDetails?.endAirport?.city || "--"}</Typography>
          //   </Grid>
          // </Grid> */}
            </Grid>
        <Grid item xs={0.5} display="flex" justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={3.5} className="total-paid">
          <Typography variant="h6" component="h6">
            Total Paid
          </Typography>
          <Typography variant="h5" component="h5">
            {formateCurrency(bookingDetails?.quotationDetails?.priceAfterGst || 0)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} mt="1px">
            {/* <Grid item xs={12} className="booking-status">
              <Typography variant="body1" component="p">
                Current Booking Status : {bookingDetails?.status || "--"}
              </Typography>
            </Grid> */}

            {bookingDetails?.status !== "PENDING" && (
              <Grid item xs={12}>
                <Typography variant="body1" component="p">
                  Booking {bookingDetails?.status?.toLowerCase()} On :{" "}
                  {getFormattedDate(bookingDetails?.recordCreatedTs)}
                </Typography>
              </Grid>
            )}

            {bookingDetails?.status === "PENDING" && (
              <Grid item xs={6} className="finalize-btn">
                <Button
                  variant="contained"
                  className="rounded-btn-fill"
                  fullWidth={true}
                  onClick={() =>
                    handleConfirmBooking(bookingDetails?.bookingId)
                  }
                >
                  Confirm Booking
                </Button>
              </Grid>
            )}
            {bookingDetails?.status === "CONFIRMED" && (
              <Grid item xs={6} className="finalize-btn">
                <Button
                  variant="contained"
                  fullWidth={true}
                  className="rounded-btn-fill"
                  onClick={() =>
                    handleCompleteBooking(bookingDetails?.bookingId)
                  }
                >
                  Complete Booking
                </Button>
              </Grid>
            )}
           { (bookingDetails?.status === "PENDING" || bookingDetails?.status === "CONFIRMED") && (
  <Grid item xs={6} className="finalize-btn">
    <ButtonComponent
      className="rounded-btn-outlined"
      fullWidth={true}
      label={"Cancel Booking"}
      onClick={handleOpenConfirmModal}
      // onClick={() => handleFailedBooking(bookingDetails?.bookingId)}
    />
  </Grid>
)}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  </>

    //     <Grid container columnSpacing={4}>

    //     <Grid item xs={12} md={6}>

    //     </Grid>
    //     {/* second grid item */}
    //     <Grid item xs={12} md={6}>
    //       <Card className="quotation-card">
    //         <Grid container className="user-details">
    //           <Grid item xs={12} className="item">
    //             <Typography variant='body1' component='span'>Booking ID :</Typography>
    //             <Typography variant='body1' component='span'>456213</Typography>
    //           </Grid>
    //           <Grid item xs={12} className="item">
    //             <Typography variant='body1' component='span'>User name :</Typography>
    //             <Typography variant='body1' component='span'>Gaurav Suthar</Typography>
    //           </Grid>
    //           <Grid item xs={12} className="item">
    //             <Typography variant='body1' component='span'>Email :</Typography>
    //             <Typography variant='body1' component='span'>gauravsuthar@gmail.com</Typography>
    //           </Grid>
    //           <Grid item xs={12} className="item">
    //             <Typography variant='body1' component='span'>Mobile No :</Typography>
    //             <Typography variant='body1' component='span'>+91-9876543210</Typography>
    //           </Grid>

    //         </Grid>
    //         <Grid container className='quotation-details'>
    //           <Grid item xs={8}>
    //             <Grid container>
    //               <Grid item xs={12} className="grid-item">
    //                 <Typography variant='body2' component="p">17 July 2023</Typography>
    //                 <Typography variant='body2' component="p">17 July 2023</Typography>
    //                 {/* <Typography variant='body2' component="p">06:55</Typography> */}
    //                 {/* <Typography variant='body2' component="p">VIDP</Typography> */}
    //               </Grid>
    //               <Grid item xs={12} className="grid-item">
    //                 <Typography variant='body2' component="p">06:55</Typography>
    //                 <Typography variant='body2' component="p">06:55</Typography>
    //                 {/* <Typography variant='body2' component="p">VIDP</Typography> */}
    //               </Grid>
    //               <Grid item xs={12} >
    //                 <Grid container className="grid-item">
    //                   <Grid item xs={2}>
    //                     <Typography variant='body2' textAlign="left" component="p">VIDP</Typography>
    //                   </Grid>
    //                   <Grid item xs={8}>
    //                     <img src={dividerPlane} alt="plane" width="100%" />
    //                   </Grid>
    //                   <Grid item xs={2}>
    //                     <Typography variant='body2' textAlign="right" component="p">VIDP</Typography>
    //                   </Grid>
    //                 </Grid>

    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item xs={1} display="flex" justifyContent="center">
    //             <Divider orientation='vertical' />
    //           </Grid>
    //           <Grid item xs={3} className='total-paid'>
    //             <Typography variant='h6' component='h6'>Total Paid</Typography>
    //             <Typography variant='h5' component="h5">INR 10,00,000</Typography>
    //           </Grid>

    //         <Grid item xs={12}>
    //         <Grid container >
    //             <Grid item xs={12} className='booking-status'>
    //               <Typography variant='body1' component='p'>Current Booking Status :</Typography>
    //             </Grid>
    //             <Grid item xs={12} className="finalize-btn">
    //             <Button variant="contained" className="rounded-btn-fill" onClick={handleConfirmBooking}>Confirm Booking</Button>
    //           </Grid>
    //           </Grid>

    //         </Grid>
    //         </Grid>
    //       </Card>
    //     </Grid>

    //   </Grid>
  );
};

export default BookingCard;
