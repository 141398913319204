import { HttpService } from '../commonService/httpService';
import { HttpMethod } from '../../enum/httpMethod';
import { sessionUtility } from '../../utility/sessionUtility';
import { IFeedService } from '../../interface/IService';

export class FeedService extends HttpService implements IFeedService {
    urls: { get: string; post: string; delete: string; put: string; patch: string; };
    userDetails = sessionUtility.getAuthentication();
    constructor() {
        super();
        this.urls={
            get:"feeds",
            post:"feeds",
            put:"feeds",
            delete:"feeds",
            patch:"feeds",
        }
        if (!this.userDetails) {
            this.userDetails = sessionUtility.getAuthentication();
        }
    }
    // header: Headers | string[][] | Record<string, string>=sessionUtility.getHeader();
    header: Headers | string[][] | Record<string, string>=sessionUtility.getHeaderWithToken(this.userDetails);
 
 
    get(param: any): Promise<any> {
        const url=this.setUrlParam(param,this.urls.get);
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    put(param: any, entity: any): Promise<any> {
        const url=this.setUrlParam(param,this.urls.put);
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    
    post<E>(entity: E): Promise<any> {
        return this.postQuery(this.urls.post, this.header, entity) as Promise<any>;
    }
    patch(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.patch);
        return this.postQuery(url, this.header) as Promise<any>;
    }
    delete(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.delete);
        return this.postQuery(url, this.header) as Promise<any>;
    }
   
} 