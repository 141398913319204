
// import { sessionStorageKey } from '../../configuration/config.json';
// import { IAuthentication } from '../../interface/IAuthentication';
// import { IAuthenticationService } from '../../interface/IAuthenticationService';
// import { ILoginRequest, ILoginResponse, IAuthority } from '../../interface/ILogin';
// import { UserStorage } from '../../storage/userStorage';
// import CryptoUtility from '../../utility/cryptoUtility';
// import { LocalStorageUtility } from '../../utility/localStorageUtility';
// export class AuthenticationMockService implements IAuthenticationService {
//     private authentication: IAuthentication = {
//         authenticated: false,
//         session: null,
//         tocken: ""
//     };
//     private userStorage: UserStorage;
//     constructor() {
//         this.userStorage = new UserStorage();
//     }
//     resendVerification(req: any): Promise<any> {
//         throw new Error("Method not implemented.");
//     }
//     pageView(req: any): Promise<any> {
//         throw new Error("Method not implemented.");
//     }
//     updatePwd(req: any,isUpdate:boolean): Promise<any> {
//         throw new Error("Method not implemented.");
//     }
//     forgotPwd(req: any): Promise<any> {
//         throw new Error("Method not implemented.");
//     }
//     verify(req: any):Promise<any> {
//         throw new Error("Method not implemented.");
//     }
//     login(req: ILoginRequest) {

//         return new Promise<IAuthentication>((resolve, reject) => {
//             const user = this.userStorage.getByEmailPassword(req.mobileNumber, req.otp);
//             if (user.length > 0) {
//                 const response: ILoginResponse = {
//                     authorities: [],
//                     token: "",
//                     responseCode: 200,
//                     message: "logedin success fully",
//                     data: {},
//                     userDetails: {
//                         fullName: user[0].fullName,
//                         mobileNumber: user[0].mobileNumber,
//                         uId:""
//                     }
//                 }
//                 this.setSuccessAuthentication(response, req.mobileNumber);
//                 resolve(this.authentication);
//             } else {
//                 this.setFailAuthentication("invalid mobilenumber");
//                 reject(this.authentication)
//             }
//         });
//     }
//     private setSuccessAuthentication(response: ILoginResponse, email: string) {
//         const authentication: IAuthentication = {
//             session: {
//                 role: this.getRoleFromResponse(response.authorities),
//                 uId: response.userDetails.uId,
//                 fullName: response.userDetails.fullName,
//                 mobileNumber: response.userDetails.mobileNumber
//             },
//             authenticated: true,
//             tocken: CryptoUtility.encryptObj(response.token)
//         }
//         this.authentication = authentication;
//         LocalStorageUtility.setStorageObj(sessionStorageKey.sessionKey, this.authentication);
//     }
//     private setFailAuthentication(err: any) {
//         const authentication: IAuthentication = {
//             session: null,
//             authenticated: false,
//             tocken: ""
//         }
//         this.authentication = authentication;
//         LocalStorageUtility.removeStorage(sessionStorageKey.sessionKey);
//     }
//     private getRoleFromResponse(authorities: string[]): string[] {
//         let userRole: string[] = [];
//         authorities?.forEach(item => {
//             const role = item;
//             userRole.push(role)
//         });
//         return userRole;
//     }

//     logOut() {
//         return new Promise((resolve, reject) => {
//             this.initAuthentication();
//             localStorage.removeItem(sessionStorageKey.sessionKey);
//             resolve(true);
//         });
//     }
//     initAuthentication() {
//         this.authentication.authenticated = false;
//         this.authentication.session = null;
//         this.authentication.tocken = "";
//     }
//     isAuthenticated() {
//         return this.authentication.authenticated;
//     }
//     getTocken() {
//         if (this.authentication.tocken !== "")
//             return CryptoUtility.decrypt(this.authentication.token);
//         return null;
//     }
//     getRole() {
//         return this.authentication.session?.role;
//     }
//     getPermissions(): string[] {
//         const permissions = this.getAuthentication().session?.role;
//         return permissions !== undefined ? permissions : [];
//     }

//     private getAuthentication() {
//         return LocalStorageUtility.getStorageObj(sessionStorageKey.sessionKey) as IAuthentication;
//     }
// }





import {sessionStorageKey} from '../../configuration/config.json';
import { IAuthentication } from '../../interface/IAuthentication';
import { IAuthenticationService } from '../../interface/IAuthenticationService';
import { ILoginRequest, ILoginResponse, IAuthority } from '../../interface/ILogin';
import { UserStorage } from '../../storage/userStorage';
import CryptoUtility from '../../utility/cryptoUtility';
import { LocalStorageUtility } from '../../utility/localStorageUtility';
export class AuthenticationMockService implements IAuthenticationService {
    private authentication: IAuthentication = {
        authenticated: false,
        session: null,
        token: ""
    };
    private userStorage: UserStorage;
    constructor() {
        this.userStorage = new UserStorage();
    }
    resendVerification(req: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    pageView(req: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    updatePwd(req: any,isUpdate:boolean): Promise<any> {
        throw new Error("Method not implemented.");
    }
    forgotPwd(req: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    verify(req: any):Promise<any> {
        throw new Error("Method not implemented.");
    }
    login(req: ILoginRequest) {

        return new Promise<IAuthentication>((resolve, reject) => {
            const user = this.userStorage.getByEmailPassword(req.mobileNumber, req.otp);
            if (user.length > 0) {
                const response: ILoginResponse = {
                    authorities: [],
                    token: "",
                    responseCode: 200,
                    message: "logedin success fully",
                    data: {},
                    uid: "",
                    userDetails: {
                        fullName: user[0].fullName,
                        uId: user[0].srno.toString(),
                        mobileNumber: user[0].mobileNumber,
                        role: []
                    }
                }
                this.setSuccessAuthentication(response, req.mobileNumber);
                resolve(this.authentication);
            } else {
                this.setFailAuthentication("invalid email or password");
                reject(this.authentication)
            }
        });
    }
    private setSuccessAuthentication(response: ILoginResponse, mobileNumber: string) {
        const authentication: IAuthentication = {
            session: {
                role: this.getRoleFromResponse(response.authorities),
                uId: response.userDetails.uId,
                fullName: response.userDetails.fullName,
                mobileNumber: mobileNumber,
            },
            authenticated: true,
            token: CryptoUtility.encryptObj(response.token)
        }
        this.authentication = authentication;
        LocalStorageUtility.setStorageObj(sessionStorageKey.sessionKey, this.authentication);
    }
    private setFailAuthentication(err: any) {
        const authentication: IAuthentication = {
            session: null,
            authenticated: false,
            token: ""
        }
        this.authentication = authentication;
        LocalStorageUtility.removeStorage(sessionStorageKey.sessionKey);
    }
    private getRoleFromResponse(authorities: string[]): string[] {
        let userRole: string[] = [];
        authorities?.forEach(item => {
            const role = item;
            userRole.push(role)
        });
        return userRole;
    }
    logOut() {
        return new Promise((resolve, reject) => {
            this.initAuthentication();
            localStorage.removeItem(sessionStorageKey.sessionKey);
            resolve(true);
        });
    }
    initAuthentication() {
        this.authentication.authenticated = false;
        this.authentication.session = null;
        this.authentication.token = "";
    }
    isAuthenticated() {
        return this.authentication.authenticated;
    }
    getToken() {
        if (this.authentication.token !== "")
            return CryptoUtility.decrypt(this.authentication.token);
        return null;
    }
    getRole() {
        return this.authentication.session?.role;
    }
    getPermissions(): string[] {
        const permissions = this.getAuthentication().session?.role;
        return permissions !== undefined ? permissions : [];
    }
    private getAuthentication() {
        return LocalStorageUtility.getStorageObj(sessionStorageKey.sessionKey) as IAuthentication;
    }
}
