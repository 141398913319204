import React from 'react'
import { Grid } from "@mui/material";
import AircaftCard from './AircaftCard';
import { IAircraft } from '../../../interface/IAircraft';
import Loader from '../../loader/Loader';
import NoResult from '../../noResult/NoResult';

interface AircraftsProps {
    fleetAircrafts: IAircraft[];
    loader:boolean;
    fleetType:string;
    index:any;
}

const Aircrafts: React.FC<AircraftsProps> = ({fleetType, fleetAircrafts,loader,index }) => {
    return (
        <Grid container spacing={4}>      
            {
                loader ?
                new Array(3).fill(0).map((_, id) => {
                  return <Grid item xs={12} sm={6} md={4} key={id}>
                    <Loader height='300px' width='100%' />
                  </Grid>
                }) : fleetAircrafts.length >=1 && loader === false ?  fleetAircrafts.map((aircraft: IAircraft) => {
                    return <Grid key={aircraft.aircraftId} item xs={12} sm={6} md={4}>
                        <AircaftCard  item={aircraft} />
                    </Grid>
                }) : 
                <Grid item xs={12}>
                    <NoResult title={`${fleetType} Aircrafts`}/>
                </Grid>
            }
        </Grid>
    )
}

export default Aircrafts