import { Grid, Typography } from '@mui/material'
import React from 'react'
import { getFormattedDate, getFormattedTime } from '../../utility/formateDateAndTime'
import dividerPlane from "../../assets/images/divider-plane.svg";


const ItineraryDetails = ({itinerary,startAirport,endAirport}:any) => {


    // console.log({startAirport,endAirport});
    
    // console.log("sdsf",itineraryPath?.startDt);
    // alert(itineraryPath?.startDt)
    
  return (
    <Grid container className="quotation-details">
    <Grid item xs={12} className="grid-item">
        <Typography variant="body2" component="p">
            {getFormattedDate(itinerary?.startDt)}
        </Typography>
        <Typography
            variant="body2"
            component="p"
            textAlign="right"
        >
            {getFormattedDate(itinerary?.endDt)}
        </Typography>
    </Grid>
    <Grid item xs={12} className="grid-item">
        <Typography variant="body2" component="p">
            {getFormattedTime(itinerary?.startDt)}
        </Typography>
        <Typography
            variant="body2"
            component="p"
            textAlign="right"
        >
            {getFormattedTime(itinerary?.endDt)}
        </Typography>
    </Grid>
    <Grid item xs={12} className="grid-item">
        <Typography variant="h6" component="h6">
            {startAirport?.city || "--"}
        </Typography>
        <Typography variant="h6" component="h6" textAlign="right">
            {endAirport?.city || "--"}
        </Typography>
    </Grid>
    <Grid item xs={12}>
        <Grid container className="grid-item">
            <Grid item xs={2}>
                <Typography
                    variant="body2"
                    component="p"
                    className="btn"
                >
                    {startAirport?.icaoCode || "--"}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <img src={dividerPlane} alt="plane" width="100%" height="50px" />
            </Grid>
            <Grid item xs={2}>
                <Typography
                    variant="body2"
                    component="p"
                    className="btn"
                >
                    {endAirport?.icaoCode || "--"}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
</Grid>
  )
}

export default ItineraryDetails