import {  Box, Typography } from '@mui/material';
import BmjLogo from "../../assets/logo/bmjLogo.svg";
const Footer: React.FC = () => {
  return (
   <Box className="footer-container">
      <Box className="img">
      <img src={BmjLogo} alt="bmj-logo" height="auto" width="100px" />
      </Box>
      <Typography variant='body1' component='p'>© Copyright {new Date().getFullYear()} BookMyJet All Rights Reserved</Typography>
   </Box>
  )
}

export default Footer