import React from 'react';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { InputAdornment } from '@mui/material';
import dateIcon from "../../assets/icons/date-icon.svg";

const useStyles = makeStyles((theme) => ({
    input: {
        height: "100%",
        color: 'inherit', // Text color
        width: '100%',
        backgroundColor: 'transparent', // Remove background
        fontSize: '16px', // Set font size
        padding: '0', // Set padding
        '&:focus': {
            outline: 'none', // Remove outline on focus
        },
    },
}));
const DateTimePickerComponent = ({ placeholder = "",className="", defaultValue = null,disabled=false, disablePast = true, minDateTime = null, maxDateTime = null, value, onChange }:any) => {
    const classes = useStyles();


    const defaultDate= defaultValue ? dayjs(defaultValue) : undefined;
    return (
        // <LocalizationProvider dateAdapter={AdapterDayjs}>
        //   <MobileDateTimePicker
        //     value={value}
        //     className={classes.input}
        //     slotProps={{
        //       textField: {
        //         variant: 'standard',
        //         inputProps: {},
        //       },
        //     }}
        //     disablePast
        //     onChange={onChange}
        //   />
        // </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <MobileDateTimePicker value={value}
                className={classes.input}
                slotProps={{
                    textField: {
                        variant: 'standard', inputProps: {
                        }
                    }
                }}
                disablePast
                onChange={onChange}
            /> */}

<MobileDateTimePicker 
                value={value ? value : defaultDate}
                // value={value }
                disabled={disabled}
                format='DD-MM-YYYY hh:mm a'
                 minDateTime={minDateTime}
                // defaultValue={"81738hd"}
                
                //    maxDateTime={maxDateTime}
                // slotProps={{
                    
                //     textField: {
                        
                //         placeholder: placeholder,
                //         variant: 'standard', inputProps: {
                            
                //         },
                //         InputProps:{
                //             disableUnderline:true
                //         }
                //     }

                // }}
             

                
              

                slotProps={{
                    
                    textField: {
                        fullWidth:true,
                        placeholder:placeholder,
                        variant: 'standard', inputProps: {
                            
                        },
                        className:className,
                        InputProps:{
                            disableUnderline:true,
                            fullWidth:true,
                             endAdornment: (
                                <InputAdornment position="start">
                                    {/* <EventIcon /> */}
                                    <img src={dateIcon} alt="hell" height="20px" width="20px" />
                                </InputAdornment>
                            ),
                            placeholder: placeholder,
                            // variant: 'standard',
                            inputProps: {},
                            // InputProps: {
                            //     disableUnderline: true,
                            // },
                        }
                    },
                    
                }}

                disablePast={disablePast}
                onChange={onChange} />
        </LocalizationProvider>
    );
};

export default DateTimePickerComponent;







