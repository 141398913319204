import React,{useState,useEffect} from 'react'
import { Grid,Tabs,Tab, Typography, Card, Box, Button, IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import backArrow from "../../assets/icons/back-arrow.svg";
import { serviceProvider } from '../../provider/serviceProvider';
import SpinLoader from '../loader/SpinLoader';
import AircraftDetails from '../fleetManagement/Aircraft/AircraftDetails';
import TechnicalSpecs from '../fleetManagement/Aircraft/TechnicalSpecs';
import { IAircraft } from '../../interface/IAircraft';



interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const OperatorSingleAircraft = () => {
    const [value, setValue] = React.useState(0);
    const [loader,setLoader]=useState(false);
    const [aircraft,setAircraft]=useState<IAircraft | any>({});

    const {aircraftId}= useParams();
    const navigate= useNavigate();
    const location = useLocation();

    // Accessing the search property which contains the query parameters
    const queryParams = new URLSearchParams(location.search);
  
    // Getting the value of the 'index' parameter
    const index = queryParams.get('index');
  
    // Convert the 'index' value to a number if needed
    // const indexNumber = parseInt(index, 10);
  
  
    // const aircraftImages= JSON.parse(aircraft.imageUrl)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
      };
console.log({aircraft})

     
      const getAircraftDetails = () => {
        setLoader(true)
        serviceProvider.aircraftService.getAircraftDetails(aircraftId+"").then((res) => {
          setLoader(false)
          // console.log("res",res)
          if(res.responseCode===200) {
            const parsedImages= JSON.parse(res.data[0].imageUrl);
            const parsedCabinSpecs =JSON.parse(res.data[0].cabinSpecs);
            const parsedtechnicalSpecs =JSON.parse(res.data[0].technicalSpecs);

            const newAircraftObj= {...res.data[0],imageUrl:parsedImages,cabinSpecs:parsedCabinSpecs,technicalSpecs:parsedtechnicalSpecs}
            setAircraft(newAircraftObj);
          }
          // console.log({ res })
        }).catch((err) => {
          setLoader(false)
          console.log({ err })
        })
    
      }

  

  // const saveAircraftToURL = (aircraft:IAircraft) => {
  //   const encodedAircraftObj = encodeURIComponent(JSON.stringify(aircraft));
  //   const newURL = `/fleets/aircraft/edit/${aircraft.aircraftId}?aircraft=${encodedAircraftObj}`;
  //   navigate(newURL);
  // };
    
      useEffect(() => {
        getAircraftDetails()
      }, []);


      if(loader){
        return  <SpinLoader/>
       }
    return (
        <Grid container className="single-aircraft-container">
            <Grid item xs={12}>
              <Box className="back-btn-container">
                <Tooltip title="back">
                <IconButton onClick={()=>navigate(-1)}>
                <img src={backArrow} alt="back" />
                </IconButton>
                </Tooltip>
                <Typography variant="h6" component="h6">{aircraft.name}</Typography>
              </Box>
               
            </Grid>
            <Grid item xs={12}>
                <Box className="edit-heading-container">
                    {/* <Typography variant="h6" component="h6" textAlign="right">Edit Details</Typography> */}
                    &nbsp;
                </Box>

            </Grid>
            
            <Grid item xs={12}>
                <Grid container spacing={3} className="aircraft-image-container">
                  {
                    aircraft && aircraft?.imageUrl?.map((image:any)=>{
                      return  <Grid item xs={3}>
                      <Card className='image-card'>
                      <div className="img-box">
                   <img src={image} alt="img" width={"100%"} />       
                   </div>
                      </Card>
                  </Grid>
                    })
                  }
                   
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card className="aircraft-description-card">
                    <Typography variant='body1' component='p' className='description-heading'>
                    Description
                    </Typography>
                    <Typography variant='body1' component='p'>
                        {/* Produced by Beechcraft, King Air C90 is a twin-engine turboprop business aircraft. It comes with a capacity of five passengers with a four-place club and a seat directly across the stair door.
                        <br />
                        <br />
                        The cabin is 4.8ft in height, 4.5ft in width and 12.7ft in length. The aft cabin or lavatory area has a baggage space of 26.5 cu. ft. A cantilever low-wing monoplane, it is a pressurized aircraft which uses dual bleed air for both cabin heating and pressurization.
                        <br />
                        <br />
                        The aircraft has a quieter, smoother and reliable hydraulic retractable tricycle landing gear which is better and way easier to maintain than the older electric system. It is also equipped with cutting-edge pneumatic boots for de-icing the wing and tail surfaces, long wing spans, good load carrying capacity and three-bladed reversible propellers. */}
                        {aircraft.description}
                    </Typography>
                </Card>
            </Grid>
            <Grid item xs={12} className="tabs-container">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Aircraft details" {...a11yProps(0)} />
                <Tab label="Technical Specs" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <AircraftDetails isEditable={false} aircraft={aircraft} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TechnicalSpecs isEditable={false}  aircraft={aircraft}/>
            </CustomTabPanel>
 
           
          </Box>

        </Grid>
        </Grid>
    )
}

export default OperatorSingleAircraft