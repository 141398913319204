import { Grid, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ButtonComponent from '../common/ButtonComponent';
import { Box } from '@mui/system';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import CommonModal from '../modal/CommonModal';
import EditAvailability from './EditAvailability';
import TechnicalSpecs from './TechnicalSpecs';
import AircraftDetails from './AircraftDetails';
import FleetDetails from './FleetDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import backArrow from "../../assets/icons/back-arrow.svg";
import { IAircraft } from '../../interface/IAircraft';
import { serviceProvider } from '../../provider/serviceProvider';

const OperatorAircraftDetails = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const [aircraft,setAircraft]=useState<IAircraft | any>({});
  const [loader,setLoader]=useState(false);
  const {aircraftId}= useParams();


  const getAircraftDetails = () => {
    setLoader(true)
    serviceProvider.aircraftService.getAircraftDetails(aircraftId+"").then((res) => {
      setLoader(false)
      // console.log("res",res)
      if(res.responseCode===200) {
        const parsedImages= JSON.parse(res.data[0].imageUrl);
        const parsedCabinSpecs =JSON.parse(res.data[0].cabinSpecs);
        const parsedtechnicalSpecs =JSON.parse(res.data[0].technicalSpecs);

        const newAircraftObj= {...res.data[0],imageUrl:parsedImages,cabinSpecs:parsedCabinSpecs,technicalSpecs:parsedtechnicalSpecs}
        setAircraft(newAircraftObj);
      }
      // console.log({ res })
    }).catch((err) => {
      setLoader(false)
      console.log({ err })
    })

  }



  useEffect(()=>{
    getAircraftDetails()
  },[])
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const encodedAircraftDetails: any = queryParams.get('aircraftDetails');
//   const decodedAircraftDetails = decodeURIComponent(encodedAircraftDetails);
//   const aircraftDetails: any = JSON.parse(decodedAircraftDetails);
// console.log({aircraftDetails});



  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const navigate= useNavigate()

  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false);
  return (
   <>
    {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={<EditAvailability operatorUid={aircraftDetails?.operatorUid} aircraftId={aircraftDetails?.aircraftDetails?.aircraftId} />} /> */}
    <Grid container spacing={2} className='operator-aircraft-container'>
      {/* ... (other components) */}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5} md={5}>
          <Box className="back-btn-container">
                <Tooltip title="back">
                <IconButton onClick={()=>navigate(-1)}>
                <img src={backArrow} alt="back" />
                </IconButton>
                </Tooltip>
                <Typography variant='h6' component='h6'>
                  {aircraft?.name || "--"}
                  {/* Name of the aircraft */}
                </Typography>
              </Box>
            
          </Grid>
          <Grid item xs={7} md={7}>

           <Grid container rowSpacing={1} >
            <Grid item xs={12}>
            <Typography variant='h6' component='h6' textAlign="right">Current status :  {aircraft?.active ? "Active":"Inactive"}</Typography>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
            <ButtonComponent fullWidth={false} variant="contained" label="Edit availability" onClick={handleOpenModal} />
            </Grid>
           </Grid>
            {/* <SearchBar onChange={handleSearch} placeholder={`Search ${value === 0 ? "Fleet" : "Aircraft"}`} /> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="tabs-container">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Aircraft details" {...a11yProps(0)} />
                <Tab label="Technical Specs" {...a11yProps(1)} />
                {/* <Tab label="Fleet details" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            <AircraftDetails aircraft={aircraft}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {/* <TechnicalSpecs isEditable={false}  aircraft={aircraft}/> */}
              <TechnicalSpecs technicalSpecs={aircraft?.techinicalSpecs}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              {/* <OperatorDetails/> */}
              {/* <FleetDetails fleet={aircraftDetails?.fleetDetails}/> */}
            </CustomTabPanel>
            
           
          </Box>

        </Grid>
    </Grid>
   </>
  )
}

export default OperatorAircraftDetails;