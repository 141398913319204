import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useRef, useState } from "react";
import img from "../../assets/images/cessena.svg";
import ButtonComponent from "../common/ButtonComponent";
import CommonModal from "../modal/CommonModal";
import EditAvailability from "./EditAvailability";
import { useNavigate } from "react-router-dom";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../utility/formateDateAndTime";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { toast } from "react-toastify";
import { ValidationUtiltiy } from "../../utility/ValidationUtility";
import UserContext from "../../context/UserContext";
import { serviceProvider } from "../../provider/serviceProvider";

const initialPayload = {
  aircraftId: "",
  approvedByUid: "",
  imageUrl: "",
  operatorAdminRequestId: "",
  operatorUid: "",
  reasonForCancellation: "",
  status: "PENDING",
};
const AssingedAircraftCard = ({ aircraft, isOperator = false }: any) => {
  const images =
    aircraft &&
    aircraft?.imageUrl !== "" &&
    JSON.parse(aircraft?.imageUrl);

  // console.log({ aircraft });

  const navigate = useNavigate()


  
  const handleNavigateToAircraftDetails = (aircraftId:any) => {
    // const encodedAircraftDetails = encodeURIComponent(JSON.stringify(aircraft));
    // const queryParams = new URLSearchParams({
    //     aircraftDetails: encodedAircraftDetails,
    // });
    // navigate(`/search-results/${itineraryId}?${queryParams}`);
if(isOperator){
  
  navigate(`/operator-my-aircrafts/${aircraftId}?index=0`)
 
}else{
  navigate(`/operator-management/aircraft/${aircraftId}?index=2`)
}
   
}
  // new added

  return (
    <>
      <Grid container className="aircraft-card">
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Box className="card-img">
            <img
              src={images && images[0]}
              alt={aircraft?.name}
              height="100%"
              width="100%"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={7} pl="12px">
          <Grid container>
            <Grid item xs={4} lg={3}>
              <Typography variant="h6" component="h6">
                Aircraft Name
              </Typography>
            </Grid>
            <Grid item xs={8} lg={9}>
              <Typography variant="h6" component="h6">
                : {aircraft?.name || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} lg={3}>
              <Typography variant="body1" component="p">
                Operator name
              </Typography>
            </Grid>
            <Grid item xs={8} lg={9}>
              <Typography variant="body1" component="p">
                : {aircraft?.userDetails?.fullName || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} lg={3}>
              <Typography variant="body1" component="p">
                Call Sign
              </Typography>
            </Grid>
            <Grid item xs={8} lg={9}>
              <Typography variant="body1" component="p">
                : {aircraft?.callSign || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} lg={3}>
              <Typography variant="body1" component="p">
                Fleet name
              </Typography>
            </Grid>
            <Grid item xs={8} lg={9}>
              <Typography variant="body1" component="p">
                : {aircraft?.fleetDetails?.name || "--"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} lg={3}>
              <Typography variant="body1" component="p">
                Guest capacity
              </Typography>
            </Grid>
            <Grid item xs={8} lg={9}>
              <Typography variant="body1" component="p">
                : {aircraft?.passengerCapacity || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3} display="flex" flexDirection="column" justifyContent="space-between" >
          <Typography
            variant="h6"
            component="h6"
            textAlign={{ xs: "left", lg: "right" }}
          >
            Current status : {aircraft?.active ? "Active" : "Inactive"}
          </Typography>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <ButtonComponent  fullWidth={false} label="Details" onClick={()=>handleNavigateToAircraftDetails(aircraft?.aircraftId)} />
        </Box>

              
        </Grid>
        {/* <Grid item xs={12} display="flex" justifyContent="flex-end">
        <ButtonComponent  fullWidth={false} label="Details" onClick={()=>handleNavigateToAircraftDetails(aircraft?.aircraftId)} />
        </Grid> */}
      </Grid>
    </>
  );
};

export default AssingedAircraftCard;
