import { AES,enc } from "crypto-ts";
import {Md5} from "ts-md5";
export default class CryptoUtility {
    private static secureKey: string = "$#@{A2Z}&";
    public static encrypt(input: string) {
        if (input === "") {
            return "";
        }
        return AES.encrypt(input, this.secureKey).toString();
    }
    public static decrypt(input:string|null){
        if (input === "" || input==null || input==="") {
            return "";
        }
        const decryptParam=AES.decrypt(input,this.secureKey);
        return decryptParam.toString(enc.Utf8)
    }
    public static encryptObj(input: any) {
        if (input === undefined || input===null || input==="") {
            return "";
        }
        const inputString=JSON.stringify(input);
        return AES.encrypt(inputString, this.secureKey).toString();
    }
    public static decryptObj(input:string|null){
        if (input === ""|| input===null) {
            return "";
        }
        const decryptParam=AES.decrypt(input,this.secureKey);
        return JSON.parse(decryptParam.toString(enc.Utf8))
    }
    public static encryptHash(input:string){
      return Md5.hashAsciiStr(input).toString();
    }
}