// import { Card, Grid, Typography } from "@mui/material";
// import { getFormattedDate, getFormattedTime } from "../../utility/formateDateAndTime";

// const ItineraryBookingDetails = ({ bookingDetails }: any) => {
//   // const arr = [
//   //   {
//   //     title: "Departure Date",
//   //     value: "June 25, 2023",
//   //   },
//   //   {
//   //     title: "Departure Time",
//   //     value: "10:00 AM IST",
//   //   },

//   //   {
//   //     title: "Departure Airport",
//   //     value: "Indira Gandhi International Airport, Delhi, India",
//   //   },

//   //   {
//   //     title: "Destination Airport",
//   //     value: "Chhatrapati Shivaji Maharaj International Airport, Mumbai, India",
//   //   },
//   //   {
//   //     title: "Flight Duration",
//   //     value: "Approximately 2 hours",
//   //   },

//   //   {
//   //     title: "Jet Type",
//   //     value: "Gulfstream G550",
//   //   },
//   //   {
//   //     title: "Passenger Capacity",
//   //     value: "Up to 14 passengers",
//   //   },
//   //   {
//   //     title: "In-flight Services",
//   //     value:
//   //       "Gourmet meals, luxury seating, Wi-Fi connectivity, entertainment system, and dedicated cabin crew",
//   //   },
//   //   {
//   //     title: "Additional Requests",
//   //     value:
//   //       "Complimentary ground transportation from the airport to the hotel in Mumbai",
//   //   },
//   // ];
//   return (
//     <Card className="user-quotation-details-card">
//       <Grid container className="user-detail-item">
//         <Grid item xs={4}>
//           <Typography variant="body1" component="p">
//           Start Date Time
//           </Typography>
//         </Grid>
//         <Grid item xs={8}>
//           <Typography variant="body1" component="p">
//             {`${getFormattedDate(bookingDetails?.itineraryDetails?.startDt)} ${getFormattedTime(bookingDetails?.itineraryDetails?.startDt)}`}
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid container className="user-detail-item">
//         <Grid item xs={4}>
//           <Typography variant="body1" component="p">
//             End Date Time
//           </Typography>
//         </Grid>
//         <Grid item xs={8}>
//           <Typography variant="body1" component="p">
//             {`${getFormattedDate(bookingDetails?.itineraryDetails?.endDt)} ${getFormattedTime(bookingDetails?.itineraryDetails?.endDt)}`}
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid container className="user-detail-item">
//         <Grid item xs={4}>
//           <Typography variant="body1" component="p">
//           Start Airport
//           </Typography>
//         </Grid>
//         <Grid item xs={8}>
//           <Typography variant="body1" component="p">
//             {bookingDetails?.itineraryDetails?.startAirport?.name || "--"}
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid container className="user-detail-item">
//         <Grid item xs={4}>
//           <Typography variant="body1" component="p">
//           End Airport
//           </Typography>
//         </Grid>
//         <Grid item xs={8}>
//           <Typography variant="body1" component="p">
//             {bookingDetails?.itineraryDetails?.endAirport?.name || "--"}
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid container className="user-detail-item">
//         <Grid item xs={4}>
//           <Typography variant="body1" component="p">
//           Flight Duration
//           </Typography>
//         </Grid>
//         <Grid item xs={8}>
//           <Typography variant="body1" component="p">
//             {bookingDetails?.itineraryDetails?.flightDuration || "--"}
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid container className="user-detail-item">
//         <Grid item xs={4}>
//           <Typography variant="body1" component="p">
//           Passenger Capacity
//           </Typography>
//         </Grid>
//         <Grid item xs={8}>
//           <Typography variant="body1" component="p">
//             {bookingDetails?.itineraryDetails?.numberOfPassengers || "--"}
//           </Typography>
//         </Grid>
//       </Grid>
      
//     </Card>
//   );
// };

// export default ItineraryBookingDetails;



import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import {
  getFormattedDate,
  getFormattedTime,
} from "../../utility/formateDateAndTime";
import { formatHours } from "../../utility/formatHours";

const ItineraryBookingDetails = ({ itineraryDetails }: any) => {
  // console.log({itineraryDetails})
  
  return (
    <Card className="user-quotation-details-card">
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Start Date Time
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {`${getFormattedDate(itineraryDetails?.startDt)} ${getFormattedTime(
              itineraryDetails?.startDt
            )}`}
          </Typography>
        </Grid>
      </Grid>
      
        <Grid container className="user-detail-item">
          <Grid item xs={4}>
            <Typography variant="body1" component="p">
              End Date Time
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" component="p">
              {`${getFormattedDate(itineraryDetails?.endDt)} ${getFormattedTime(
                itineraryDetails?.endDt
              )}`}
            </Typography>
          </Grid>
        </Grid>
      
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Start Airport
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {itineraryDetails?.startAirportDetails?.name || "--"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            End Airport
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {itineraryDetails?.endAirportDetails?.name || "--"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Flight Duration
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {formatHours(itineraryDetails?.duration || 0)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="user-detail-item">
        <Grid item xs={4}>
          <Typography variant="body1" component="p">
            Guest Capacity
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="p">
            {itineraryDetails?.numberOfPassengers || "--"}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ItineraryBookingDetails;
