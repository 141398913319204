import React from 'react';
import {Grid,Typography} from "@mui/material";
import NoResultIcon from "../../assets/icons/no-results.gif";

const NoResult = ({title}:any) => {
  return (
    <Grid container mt="20px">
        <Grid item xs={12} display="flex" flexDirection="column" height="450px" justifyContent="center" alignItems="center">
            <img src={NoResultIcon} alt="No Results Found"  />
            <Typography variant="h6" component="h6">No Results for {title}.</Typography>
        </Grid>
    </Grid>
  )
}

export default NoResult