import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Card,
  FormControl,
  Typography,
  InputLabel,
  TextField,
  Tooltip,
  IconButton,
  Skeleton,
} from "@mui/material";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { serviceProvider } from "../../provider/serviceProvider";
// import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { sessionUtility } from "../../utility/sessionUtility";
import blogbanner from "../../assets/images/default_blog_banner.svg";

// import editIcon from "../../assets/icons/edit-icon.svg";
import blogEditIcon from "../../assets/icons/edit-blog-icon.svg";
import fileuploadIcon from "../../assets/icons/add-image.svg";
import styles from "./postBlog.module.scss";
// import { URL } from "url";
import veriryIcon from "../../assets/icons/successIcon.svg";
import SpinLoader from "../../components/loader/SpinLoader";
import CommonModal from "../../components/modal/CommonModal";
import EditorNew from "../../components/common/EditorNew";
import ButtonComponent from "../../components/common/ButtonComponent";
import useImageUploader from "../../hooks/useImageUploader";
import { toast } from "react-toastify";
// import useImageUploader from "../../../hooks/useImageUploader";
import backArrow from "../../assets/icons/back-arrow.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";

const initialUserInput = {
  active: false,
  // blogpostId: "",
  categoryId: "",
  content: "",
  // commentCount:0,
  name: "",
  summary: "",
  coverImgUrl: "",
  custUrl: "",
  minToRead: 0,
  featured: false,
  title: "",
  uid: "",
  // recordCreatedBy:"",
  // recordCreatedTs: "",
  // recordUpdatedBy: "",
  // recordUpdatedTs: "",
  // reportAbuseCount:"",
  // shareCount:"",
};

const PostBlog = () => {
  const loggedUserDetails = sessionUtility.getAuthentication().session;
  const [loaders, setLoaders] = useState<any>({
    postBlog:false,
    verfiyCustomUrl:false,
    category:false
  });
  const [categoryId, setCategoryId] = useState("");
  // const [data, setData] = useState("");
  // const [category, setCategory] = useState("");
  // const [profileImage, setProfileImage] = useState<string>(blogbanner);
  const [categories, setCategories] = useState([]);
  const [userInput, setUserInput] = useState<any>(initialUserInput);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [customUrl, setCustomUrl] = useState("");
  const [open, setOpen] = useState(false);
  // const [urlVeried, setUrlVerified] = useState(false);
  const [isCustomUrlVerified, setIsCustomUrlVerified] =
    useState<boolean>(false);
  const [imageEvent, setImageEvent] = useState<any>("");
  const [errors, setErrors] = useState<any>({});

  const navigate = useNavigate();

  // const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  //   props,
  //   ref
  // ) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });
  function calculateReadingTimeFromHTML(htmlContent: string) {
    // Convert HTML content to plain text
    const textContent = htmlContent.replace(/<[^>]*>/g, "");

    // Count words (split by space characters)
    const words = textContent.split(/\s+/).length;

    // Average reading speed (words per minute)
    const wordsPerMinute = 200; // Adjust this value as needed

    // Calculate reading time (in minutes)
    const readingTime = Math.ceil(words / wordsPerMinute);

    return readingTime;
  }
  const [imagetoupload, setImagetoUpload] = useState("");

  const { handleImageChange, loader: imageLoader } =
    useImageUploader(awsConfig);

  const coverImageRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef(null);

  const handleChooseCoverImg = (e: any) => {
    coverImageRef.current?.click();
    setImageEvent(e);
  };

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, coverImgUrl: imageUrl });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    if (value) {
      errors[name] = "";
    }
  };

  // const inputImagechangeHandler = (event: any) => {
  //   let file = event.target.files[0];
  //   const isValid = ValidationUtiltiy.isValidImg(file.name);
  //   if (isValid) {
  //     setSelectedFile(event.target.files[0]);
  //     setFile(file);
  //     let newFileName = event.target.files[0].name.replace(/\..+$/, "");
  //     setNewfile(newFileName);
  //     setProfileImage(URL.createObjectURL(file));
  //     // setImagetoUpload(URL.createObjectURL(file));
  //     uploadImage(file, newFileName);
  //     handleCloseModal()
  //   } else {
  //   }
  // };
  // const uploadImage = (file: any, newFile: any) => {
  //   const ReactS3Client = new S3(awsConfig);
  //   ReactS3Client.uploadFile(
  //     file,
  //     awsConfig.resumeSubFolder + "/" + newFile
  //   ).then((data) => {
  //     if (data.status === 204) {
  //       // updateUserImageInPersonalDetails(data.location);
  //       // setAlert("Profile Pic Uploaded", true, "success");
  //     } else {
  //     }
  //   });
  // };
  // const handleImgUpload = () => {
  //   setProfileImage(imagetoupload);
  //   handleCloseModal();
  // };
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleUploadCoverImage = () => {
    if (!imageEvent) {
      toast.error("Please select cover image");
    } else {
      handleCloseModal();
      handleImageChange(imageEvent, updateUserInput);
    }
  };
  const customUrlVerify = async (customUrl: string) => {
    setLoaders((prev:any)=>({ ...prev, verfiyCustomUrl: true }));
    try {
      const param = `customurls/valid/BLOG?customUrl=${customUrl}`;
      const res: any = await serviceProvider.blogService.checkCustomUrl(param);
      if (res?.httpStatus === 200) {
        // setUrlVerified(true);
        setIsCustomUrlVerified(true);
        toast.success(res?.result || "Custom url verified.");
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoaders((prev:any)=>({ ...prev, verfiyCustomUrl: false }));
    }
  };

  const fetchAllCategory = async () => {
    setLoaders((prev:any)=>({ ...prev, category: true }));
    try {
      const param = `categories?categoryId=NA`;
      const res: any = await serviceProvider.blogService.get(param);
      if (res?.httpStatus === 200) {
        setCategories(res?.result);
        // console.log(res?.result);
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoaders((prev:any)=>({ ...prev, category: false }));
    }
  };

  const handlePostBlog = () => {
    if (
      !userInput.title ||
      !userInput.summary ||
      !userInput.categoryId ||
      !userInput.content
    ) {
      setErrors({
        ...errors,
        // Set error messages for each field that is empty
        title: !userInput.title ? "Title is required" : "",
        summary: !userInput.summary ? "Summary is required" : "",
        categoryId: !userInput.categoryId ? "Category is required" : "",
        content: !userInput.content ? "Content is required" : "",
      });
    } else {
      const payload = {
        ...userInput,
        uid: loggedUserDetails?.uId,
        minToRead: calculateReadingTimeFromHTML(userInput.content),
        custUrl: isCustomUrlVerified ? customUrl : "",
      };

      // isCustomUrlVerified && (setUserInput({...userInput, custUrl:customUrl}))

      if (customUrl && !isCustomUrlVerified) {
        toast.error("Please verify custom url.");
        return;
      }

      setLoaders((prev:any)=>({ ...prev, postBlog: true }));
      serviceProvider.blogService
        .post(payload)
        .then((res: any) => {
          if (res?.httpStatus === 200) {
            setUserInput(initialUserInput);

            toast.success("Blog Successfully saved as draft.");
            window.location.href = "/blogs-management?index=1";
            // window.location.reload();
          } else {
            toast.error(res?.error?.message || "Something went wrong!");
          }
        })
        .catch((err) => {
          toast.error(err?.data?.error?.message || "Something went wrong!");
        })
        .finally(() => {
          setLoaders((prev:any)=>({ ...prev, postBlog: false }));
        });
    }
  };

  useEffect(() => {
    setEditorLoaded(true);
    fetchAllCategory();
  }, []);


  return (
    <>
      <CommonModal
        open={open}
        handleCloseModal={handleCloseModal}
        className={""}
        modalBody={
          <div className={styles.modalStyling}>
            <Typography variant="body2">
              Upload Cover Image/Banner for Blog
            </Typography>

            <div className={styles.imageInputContainer}>
              {imagetoupload ? (
                <img
                  src={imagetoupload}
                  alt="preview"
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover", borderRadius: "10px" }}
                />
              ) : (
                <>
                  <img
                    src={fileuploadIcon}
                    alt="profile-img"
                    width="30px"
                    height="30px"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                  <label
                    htmlFor="fileInput"
                    style={{ fontSize: "14px", color: "#337DD9" }}
                  >
                    choose file
                    <input
                      style={{ display: "none" }}
                      // ref={coverImageRef}
                      id="fileInput"
                      type="file"
                      name="coverImgUrl"
                      onChange={(e) => handleChooseCoverImg(e)}
                      // onChange={(e)=>setImageEvent(e)}
                      // onChange={(e: any) => handleImageChange(e, updateUserInput)}
                    />
                  </label>
                </>
              )}
              {imageEvent?.target?.files[0] ? (
                imageEvent?.target?.files[0].name
              ) : (
                <Typography variant="body2">No file chosen</Typography>
              )}{" "}
            </div>

            <Typography variant="caption">
            Dimensions - 1345 x 531 px
            </Typography>
            <Typography variant="caption">
              Only accepts .png, .jpg, .jpeg
            </Typography>
            <Button
              className="rounded-btn-fill"
              onClick={handleUploadCoverImage}
            >
              Click to Upload
            </Button>
          </div>
        }
      />

      {/* Component */}
      <div>
        <Box className="back-btn-container" sx={{ mb: "10px" }}>
          <Tooltip title="back">
            <IconButton onClick={() => navigate(-1)}>
              <img src={backArrow} alt="back" />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" component="h6">
            Post Blog
          </Typography>
        </Box>
        <Card
          className="card-layout"
          sx={{
            p: 2,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: 600, color: "#4A4A4A" }} shrink>
                Blog Banner/Cover Image
              </InputLabel>
              <Grid container rowGap="20px" mt="5px">
                {imageLoader ? (
                  <Grid item xs={12}>
                    <Loader height="400px" width="100%" />
                  </Grid>
                ) : (
                  <Grid item xs={12} style={{ position: "relative" }}>
                    <label
                      htmlFor="fileInput"
                      style={{
                        cursor: "pointer",
                        display: "block",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "400px",
                          position: "relative",
                        }}
                      >
                        <img
                          src={userInput?.coverImgUrl || blogbanner}
                          alt="profile-img"
                          width="100%"
                          height="100%"
                          style={{ objectFit: "cover", borderRadius: "10px" }}
                        />
                        {/* <input
                        name="image"
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        // onChange={changeHandler}
                      /> */}
                      </div>
                    </label>
                    <Button
                      className={`rounded-btn-outlined ${styles.bannerUploadBtn}`}
                      style={{ position: "absolute", top: 20, right: 20 }}
                      onClick={handleOpenModal}
                    >
                      <img
                        src={blogEditIcon}
                        alt="pencil-icon"
                        width="20px"
                        height="20px"
                      />
                      <span>Update</span>
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12}>
                  {/* <WUETypography variant={"h6"}>Blog Title</WUETypography> */}
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel
                      sx={{ fontWeight: 600, color: "#4A4A4A" }}
                      shrink
                      htmlFor="title"
                      required
                    >
                      Blog Title
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className="inputField mt-20"
                      id="title"
                      name="title"
                      required
                      value={userInput.title}
                      onChange={handleInputChange}
                      placeholder="Enter blog title"
                      InputProps={{
                        disableUnderline: true,
                        // sx:{m:"10px"}
                      }}
                    />
                    {errors?.title && (
                      <span className="modalError">{errors?.title}</span>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {/* <WUETypography variant={"h6"}>Blog Subtitle</WUETypography> */}
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel
                      sx={{ fontWeight: 600, color: "#4A4A4A" }}
                      shrink
                      htmlFor="summary"
                      required
                    >
                      Blog Summary
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      className="inputField mt-20"
                      required
                      name="summary"
                      value={userInput.summary}
                      onChange={handleInputChange}
                      placeholder="Enter blog summary"
                      // sx={{padding:"10px"}}
                      InputProps={{
                        disableUnderline: true,
                        // sx:{m:"10px"}
                      }}
                      multiline
                      minRows={4}
                    />
                    {errors?.summary && (
                      <span className="modalError">{errors?.summary}</span>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Grid container xs={12} rowGap="20px" columnSpacing="20px">
                    <Grid item xs={12} md={4}>
                      {/* <WUETypography variant={"h6"}>Blog Category</WUETypography> */}
                      <FormControl variant="standard" fullWidth required>
                        <InputLabel
                          sx={{ fontWeight: 600, color: "#4A4A4A" }}
                          shrink
                          htmlFor="categoryId"
                          required
                        >
                          Blog Category
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          className="inputField mt-20"
                          select
                          name="categoryId"
                          id="categoryId"
                          value={userInput.categoryId}
                          onChange={handleInputChange}
                          required
                          placeholder="Enter blog category"
                          InputProps={{
                            disableUnderline: true,
                            // sx:{m:"10px"}
                          }}
                        >
                          {categories.map((category: any) => (
                            <MenuItem
                              key={category.categoryId}
                              value={category.categoryId}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errors?.categoryId && (
                          <span className="modalError">
                            {errors?.categoryId}
                          </span>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {/* <WUETypography variant={"h6"}>Custom URL</WUETypography> */}
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          sx={{ fontWeight: 600, color: "#4A4A4A" }}
                          shrink
                          htmlFor="custUrl"
                        >
                          Custom URL
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          className="inputField mt-20"
                          id="custUrl"
                          value={customUrl}
                          onChange={(e) => {
                            setIsCustomUrlVerified(false);
                            setCustomUrl(e.target.value);
                          }}
                          // required
                          placeholder="Enter custom url"
                          // sx={{ mt: 1 }}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <Button
                                className="common-button-underlined"
                                onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                                sx={{ padding: "0" }}
                                disabled={isCustomUrlVerified || loaders.verfiyCustomUrl}
                              >
                                <p
                                  style={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    paddingLeft:"20px"
                                  }}
                                >
                                  <span>
                                    {isCustomUrlVerified
                                      ? "Verified"
                                      : "Verify"}
                                  </span>
                                  {isCustomUrlVerified ? (
                                    <img src={veriryIcon} alt="verify-icon" />
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </Button>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* <WUETypography
                    variant={"h6"}
                    style={{ marginBottom: "10px" }}
                  >
                    Content
                  </WUETypography> */}
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel
                      sx={{ fontWeight: 600, color: "#4A4A4A" }}
                      shrink
                      required
                    >
                      Content
                    </InputLabel>
                    <div style={{ marginTop: "20px" }}>
                      <EditorNew
                        // id="content"

                        editorLoaded={editorLoaded}
                        value={userInput.content}
                        onChange={(data: string) => {
                          if (data) {
                            errors["content"] = "";
                          }
                          setUserInput({ ...userInput, content: data });
                        }}
                        placeholder="Write content"
                      />
                      {errors?.content && (
                        <span className="modalError">{errors?.content}</span>
                      )}
                    </div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <ButtonComponent
                    loader={loaders.postBlog}
                    disabled={loaders.postBlog}
                    fullWidth={false}
                    onClick={handlePostBlog}
                    label="Save as Draft"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default PostBlog;
