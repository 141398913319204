import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Loader from "../loader/Loader";
import AircraftAvailabiltyCard from "./AircraftAvailabiltyCard";
import NoResult from "../noResult/NoResult";
import CommonPagination from "../common/CommonPagination";
import { toast } from "react-toastify";
import { serviceProvider } from "../../provider/serviceProvider";
import SearchBar from "../searchBar/SearchBar";
import CommonModal from "../modal/CommonModal";
import UserContext from "../../context/UserContext";
import DateTimePickerComponent from "../common/DateTimePickerComponent";
import AutoCompleteComponent from "../common/AutoCompleteComponent";
import ButtonComponent from "../common/ButtonComponent";
import dayjs from "dayjs";
import { IAirport } from "../../interface/IAirport";

const initialPayload = {
  active: true,
  aircraftId: "",
  availabilityEndDt: "",
  availabilityId: "",
  availabilityStartDt: "",
  operatorUid: "",
  startAirportId: "",
};

const AircraftsAvailability = () => {
  const [loader, setLoader] = React.useState<boolean>(false);
  const [aircraftAvailabilityLists, setAircraftAvailabilityLists] = useState(
    []
  );
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [userInput, setUserInput] = useState<any>(initialPayload);
  const [open, setOpen] = useState(false);
  const context = useContext(UserContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [airports, setAirports] = useState<any>([]);
  const [assginedAircrafts, setAssginedAircrafts] = useState<any>([]);
  const [operatorList, setOperatorList] = useState<any>([]);
  const [operatorId, setOperatorId] = useState<any>("");

  // const operatorId = context?.authentication?.session?.uId;

  const handleOpenModal = (operator: any) => {
    setOpen(true);
    setUserInput({ ...userInput, operatorId: operator?.uId });
  };
  const handleCloseModal = () => setOpen(false);

  const handleStartDateChange = (newDate: any) => {
    setStartDate(newDate);
  };
  const handleEndDateChange = (newDate: any) => {
    setEndDate(newDate);
  };

  const handleInputChange = (property: any, value: any) => {
    // if (property === "aircraftId") {
    //     setUserInput({ ...userInput, aircraftId: newValue?.aircraftId })

    // } else if (property === "airportId") {
    //     setUserInput({ ...userInput, startAirportId: newValue?.airportId })
    // }

    if (property === "operatorUid") setOperatorId(value);
    setUserInput({ ...userInput, [property]: value });

    // validateFieldsOnInput(property)
  };

  const getAircraftAvailabilityList = (param: any) => {
    // const param = `search?operatorUid=all&fullName=all&isActive=NA&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    // const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    setLoader(true);
    serviceProvider.aircraftAvailabilityService
      .get(param)
      .then((res: any) => {
        // console.log(res);
        if (res.responseCode === 200) {
          setAircraftAvailabilityLists([]);
          setAircraftAvailabilityLists(res.data.data);
          setTotalPages(res.data.totalRecordCount);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const getOperatorsList = () => {
    // const searchQuery = e.target.value
    // setSearchQuery(e.target.value)
    // const active = value === 0 ? true : false;

    const operatorRoleId = "eaf1aa60-fee7-4e32-9afc-e04fc8f61c48";

    const query = `search?roleId=${operatorRoleId}&uId=NA&email=all&fullName=all&isActive=NA&offset=0&limit=100000&order=recordUpdatedts.desc&profile=BASIC`;
    setLoader(true);
    serviceProvider.userService
      .get(query)
      .then((res) => {
        // console.log({ res })
        if (res.responseCode === 200) {
          setOperatorList(res.data.details);
        } else {
          toast.error(res?.message || "Something Went Wrong !!");
        }
        // if (res.responseCode === 200 && active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
        // else if (res.responseCode === 200 && !active)   setPageState(prev=>({...prev,loader:false,data:res.data.details,totalPages:res.data.totalRecordCount}))
        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
      });
  };

  //   const handleIsAircraftAvailable = (e: any) => {
  // console.log(e.target.checked);

  //     // if(e.target.checked){
  //     //   setChecked(true)
  //     // }else{
  //     //   setChecked(false)
  //     // }

  //     // handleSearch(e,e.target.checked)
  //   }

  const handleSearch = (e: any) => {
    // const searchQuery = e.target.value || "all";

    setSearchQuery(e.target.value);

    // const param = `search?operatorUid=all&fullName=all&aircraftName=${searchQuery}&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    // getAircraftAvailabilityList(param)
  };
  const handleCheckbox = (e: any) => {
    // const isChecked = e.target.value || "NA";

    setChecked(e.target.checked);
    // const isChecked
    // const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=${}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    // getAircraftAvailabilityList(param)
  };

  //   getAircraftAvailabilityList(param)

  // //     const isActiveAircraft = e.target.checked ? true : "NA";

  //     // const param = `search?operatorUid=all&fullName=all&aircraftName=${searchQuery}&isActive=${isActiveAircraft}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  //     // setLoader(true);
  //     // serviceProvider.aircraftAvailabilityService.get(param).then((res: any) => {
  //     //   // console.log(res);
  //     //   if (res.responseCode === 200) {

  //     //     setAircraftAvailabilityLists(res.data.data)
  //     //     setTotalPages(res.data.totalRecordCount)

  //     //   } else {
  //     //     toast.error("Something Went Wrong!!")
  //     //   }
  //     //   setLoader(false)

  //     // }).catch((err: any) => {
  //     //   setLoader(false)
  //     // })
  //   }

  const getAssignedAircrafts = (operatorId: any) => {
    setLoader(true);
    serviceProvider.aircraftService
      .getAllAssingedAircrafts(operatorId, true,"NA")
      .then((res: any) => {
        if (res.responseCode === 200) {
          setAssginedAircrafts(res.data);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const getAllAirports = () => {
    setLoader(true);
    serviceProvider.airportService
      .getAllAirports()
      .then((res) => {
        if (res.responseCode === 200) {
          setAirports(res.data);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
      });
  };

  //   const handleCheckBox = (e:any) => {
  // //     const searchQuery = e.target.value === "" ? "all":e.target.value;
  // // console.log(searchQuery);

  //     const isActiveAircraft = e.target.checked ? true : "NA";

  //     const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=${isActiveAircraft}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  //     getAircraftAvailabilityList(param)
  //   }

  const handleAddAvailibility = (operatorUid: any) => {
    // const formattedStartDate = startDate != undefined ? new Date(startDate).toISOString() : new Date();
    // const formattedEndDate = endDate != undefined ? new Date(endDate).toISOString() : new Date();

    const formattedStartDate = startDate
      ? dayjs(new Date(startDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const formattedEndDate = endDate
      ? dayjs(new Date(endDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
      : "";

    const payload = {
      ...userInput,
      availabilityStartDt: formattedStartDate,
      availabilityEndDt: formattedEndDate,
      operatorUid,
    };

    setLoader(true);
    serviceProvider.aircraftAvailabilityService
      .post(payload)
      .then((res) => {
        setLoader(false);
        console.log(res);
        if (res.responseCode === 200) {
          toast.success(res.message);
          handleCloseModal();
          // setTimeout(() => {
          //     window.location.reload()
          // }, 100)
          // setTimeout(() => {
          //   // window.location.reload();
          //   window.location.href=`/operator-management?index=${1}`
          // }, 100);
          const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`;
          getAircraftAvailabilityList(param);
        } else {
          toast.error(res?.message || "Something Went Wrong!!");
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoader(false);
        toast.error(err?.data?.message || "Something Went Wrong!!");
      });
  };

  useEffect(() => {
    // console.log("in useeffect");

    const isActive = checked ? checked : "NA";
    const query = searchQuery ? searchQuery : "all";
    // alert(checked);
    // alert(searchQuery)
    const param = `search?operatorUid=all&fullName=all&aircraftName=${query}&isActive=${isActive}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`;
    getAircraftAvailabilityList(param);
    // else if (value === 2) getOperatorRequestsList()
  }, [page, checked, searchQuery]);

  useEffect(() => {
    getAllAirports();
    getOperatorsList();
  }, []);

  useEffect(() => {
    if (operatorId) getAssignedAircrafts(operatorId);
  }, [operatorId]);

  const modalBody = (
    <Grid container rowSpacing={3} className="edit-availability-modal">
      <Grid item xs={12}>
        <Typography variant="h6" component="h6">
          Add availability
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteComponent
          id="select-operatorId"
          options={operatorList}
          className="select-box"
          // defaultValue={searchResults?.startAirport}
          getOptionLabel={(option: any) => `${option.fullName}`}
          // position="end"
          // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
          // renderOption={(props: any, option: any) => (
          //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
          //         <Typography textAlign="left">{option.city}</Typography>
          //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
          //     </Box>
          // )}
          onChange={(e: any, newValue: any) =>
            handleInputChange("operatorUid", newValue?.uid)
          }
          placeholder="Select Operator"
        />
        <span className="modalError" id="errorDiv-select-aircraftId"></span>
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteComponent
          id="select-aircraftId"
          options={assginedAircrafts}
          className="select-box"
          // defaultValue={searchResults?.startAirport}
          getOptionLabel={(option: any) => `${option?.name} (${option?.callSign})`}
          // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
          // renderOption={(props: any, option: any) => (
          //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
          //         <Typography textAlign="left">{option.city}</Typography>
          //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
          //     </Box>
          // )}
          onChange={(e: any, newValue: any) =>
            handleInputChange("aircraftId", newValue?.aircraftId)
          }
          placeholder="Select Aircraft"
        />
        <span className="modalError" id="errorDiv-select-aircraftId"></span>
      </Grid>
      <Grid item xs={12}>
        <DateTimePickerComponent
          className="date-input"
          placeholder="Start Date and Time"
          disablePast={true}
          value={startDate}
          onChange={handleStartDateChange}
        />
      </Grid>
      <Grid item xs={12}>
        <DateTimePickerComponent
          className="date-input"
          placeholder="End Date and Time"
          disablePast={true}
          value={endDate}
          onChange={handleEndDateChange}
        />
      </Grid>
      <Grid item xs={12}>
    
        <Grid item xs={12}>
          <AutoCompleteComponent
            id="select-aiportId"
            options={airports}
            className="select-box"
            getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode}) - ${option.city}`}
            renderOption={(props: any, option: any) => (
              <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props} style={{display:"block"}}>
                <Typography textAlign="left" mb="8px">{option.city}</Typography>
                <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
              </Box>
            )}
            onChange={(e: any, newValue: any) =>
              handleInputChange("startAirportId", newValue?.airportId)
            }
            placeholder="Select Airport"
          />
          <span className="modalError" id="errorDiv-select-aiportId"></span>
        </Grid>
        <Typography variant="body1" component="p">
          Airport is not listed? Please <span>contact us</span>
        </Typography>
      </Grid>

      <Grid item xs={12} className="btn-container">
        <ButtonComponent
          label="Save"
          loader={loader}
          disabled={loader}
          fullWidth={false}
          onClick={() => handleAddAvailibility(operatorId)}
        />
      </Grid>
    </Grid>
  );

  return (
    <>
      <CommonModal
        open={open}
        handleCloseModal={handleCloseModal}
        modalBody={modalBody}
      />
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={5}>
              <SearchBar
                onChange={handleSearch}
                placeholder={`Search by aircraft name`}
              />
            </Grid>
            <Grid item xs={7}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <FormControlLabel
                  value={checked}
                  control={<Checkbox size="small" onChange={handleCheckbox} />}
                  label="Available Aircraft"
                  labelPlacement="end"
                />
              </Box>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <ButtonComponent
                fullWidth={false}
                label="Add Availability"
                onClick={handleOpenModal}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid rowSpacing={3} container>
            {/* {
aircraftAvailabilityLists?.length>=1 && aircraftAvailabilityLists?.map((aircraft:any)=>{
return   <Grid item xs={12} key={aircraft?.availabilityId}>
<AircraftAvailabiltyCard aircraft={aircraft}/>
</Grid>
})
}  */}

            {loader ? (
              new Array(3).fill(0).map((_, id) => {
                return (
                  <Grid item xs={12} key={id}>
                    <Loader height="250px" width="100%" />
                  </Grid>
                );
              })
            ) : aircraftAvailabilityLists.length >= 1 && loader === false ? (
              aircraftAvailabilityLists.map((aircraft: any) => {
                return (
                  <Grid item xs={12}>
                    <AircraftAvailabiltyCard aircraft={aircraft} />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <NoResult title={"Aircrafts Availability"} />
              </Grid>
            )}
          </Grid>
          {
            aircraftAvailabilityLists?.length >=1 && <Box className="pagination-box">
            <CommonPagination
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          </Box>
          }
          
        </Grid>
      </Grid>
    </>
  );
};

export default AircraftsAvailability;
