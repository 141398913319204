import FleetStorage from '../../storage/FleetStorage';

export class FleetMockService  {
    storage: FleetStorage;
    constructor() {
       this.storage=new FleetStorage();
    }
    put(param: any, entity: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    post(entity: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    patch(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    delete(param: any): Promise<any> {
        throw new Error('Method not implemented.');
    }
    getAllFleets(): Promise<any[]> {
        return new Promise((resolve) => {
           resolve(this.storage.get())
        });
    }
    get(param: any): Promise<any> {
        return new Promise((resolve) => {
            resolve(this.storage.getByScenario(param as string)[0]);
        });
    }

    // sendJobMail(jobId:string, uId:string, emailIdList:string, mailSubject:string, mailBody:string): Promise<any> {
    //     return new Promise((resolve) => {
    //         resolve(this.storage.getByScenario(jobId as string)[0]);
    //     });
    // }
}