// import React from 'react'

// const UserBookingDetails = () => {
//   return (
//     <div>UserBookingDetails</div>
//   )
// }

// export default UserBookingDetails


import { Card, Grid, Typography } from '@mui/material'

const UserBookingDetails = ({bookingDetails}:any) => {

  const arr=[
    {
      title:"Booking ID",
      value:456213
    },
    {
      title:"User Name",
      value:"Gaurav Suthar"
    },
    {
      title:"Email",
      value:"gauravsuthar@gmail.com"
    },
    {
      title:"Mobile No.",
      value:"+91-9876543210"
    },
  ]
  return (
    <Card className='user-quotation-details-card'>

     <Grid container className='user-detail-item'>
    <Grid item xs={4}>
      <Typography variant="body1" component="p">Booking ID</Typography>
    </Grid>
    <Grid item xs={8}>
    <Typography variant="body1" component="p">{bookingDetails?.bookingId || "--"}</Typography>
    </Grid>
</Grid>
     <Grid container className='user-detail-item'>
    <Grid item xs={4}>
      <Typography variant="body1" component="p">User Name</Typography>
    </Grid>
    <Grid item xs={8}>
    <Typography variant="body1" component="p">{bookingDetails?.userDetails?.fullName || "--"}</Typography>
    </Grid>
</Grid>
<Grid container className='user-detail-item'>
    <Grid item xs={4}>
      <Typography variant="body1" component="p">Email</Typography>
    </Grid>
    <Grid item xs={8}>
    <Typography variant="body1" component="p">{bookingDetails?.userDetails?.email || "--"}</Typography>
    </Grid>
</Grid>
<Grid container className='user-detail-item'>
    <Grid item xs={4}>
      <Typography variant="body1" component="p">Mobile No.</Typography>
    </Grid>
    <Grid item xs={8}>
    <Typography variant="body1" component="p">{bookingDetails?.userDetails?.mobileNumber || "--"}</Typography>
    </Grid>
</Grid>

    </Card>
  )
}

export default UserBookingDetails