// // import { useContext } from "react";
// // import React from "react";
// // import { Route, Redirect } from 'react-router-dom';
// // import { IAuthenticationRoute } from "../../interface/IAuthenticationRoute";
// // import { UnAuthorizedError } from "../error/unAuthorizedError";
// // import UserContext from "../../context/UserContext";
// // import { StringUtility } from "../../utility/stringUtility";
// // export const AuthenticatedRoute: React.FC<IAuthenticationRoute> = ({ component: Component, ...rest }) => {
// //     const userContext = useContext(UserContext);
// //     return <Route {...rest} 
// //     render={props => {
// //         switch (true) {
// //             case (userContext.authentication.authenticated === true): const isAuthroized = StringUtility.isCollectionContent(rest.authorizedRoles, userContext.authentication?.session?.role);
// //                 return isAuthroized ? <Component {...props} /> : <UnAuthorizedError />;
// //             case (userContext.authentication.authenticated === false): 
// //                 return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
// //             default: return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
// //         }
// //     }}>
// //     </Route>
// // }


// // import React, { useContext } from "react";
// // import { Route, Routes, Navigate } from 'react-router-dom';
// // import { IAuthenticationRoute } from "../../interface/IAuthenticationRoute";
// // import { UnAuthorizedError } from "../error/unAuthorizedError";
// // import UserContext from "../../context/UserContext";
// // import { StringUtility } from "../../utility/stringUtility";
// // import Auth from "../auth/Auth";

// // const AuthenticatedRoute: React.FC<IAuthenticationRoute> = ({ element: Element, ...rest }) => {
// //   const userContext = useContext(UserContext);
// //   const [open, setOpen] = React.useState(false);
// //   const handleOpenModal = () => setOpen(true);
// //   const handleCloseModal = () => setOpen(false);
// //   return (
// //    <>
// //     <Auth open={open} handleCloseModal={handleCloseModal} setOpen={setOpen} />
// //     <Route
// //       {...rest}
// //       element={
// //         <Routes>
// //           <Route
// //             element={
// //               userContext.authentication.authenticated === true ? (
// //                 StringUtility.isCollectionContent(
// //                   rest.authorizedRoles,
// //                   userContext.authentication?.session?.role
// //                 ) ? (
// //                   <Element />
// //                 ) : (
// //                   <UnAuthorizedError />
// //                 )
// //               ) : (
// //                 handleOpenModal()
// //                 // <Navigate to="/login" state={{ from: rest.location }} replace />
               
// //               )
// //             }
// //           />
// //           <Route
// //             path="*"
// //             element={<Navigate to="/" state={{ from: rest.location }} replace />}
// //           />
// //         </Routes>
// //       }
// //     />
// //    </>
// //   );
// // };

// // export default AuthenticatedRoute;



// import React, { useContext } from "react";
// import { Route, Routes, Navigate } from 'react-router-dom';
// import { IAuthenticationRoute } from "../../interface/IAuthenticationRoute";
// // import { UnAuthorizedError } from "../error/unAuthorizedError";
// import UserContext from "../../context/UserContext";
// import { StringUtility } from "../../utility/stringUtility";
// import Auth from "../auth/Auth";

// const AuthenticatedRoute = ({path, element: Element, ...rest }:any) => {
//   const userContext = useContext(UserContext);
//   const [open, setOpen] = React.useState(true);

//   const handleOpenModal = () => setOpen(true);
//   const handleCloseModal = () => setOpen(false);

//   return (
//     <Route
//       path={path}
//       element={
//         <Routes>
//           <Route
//             element={
//               userContext.authentication.authenticated === true ? (
//                 StringUtility.isCollectionContent(
//                   rest.authorizedRoles,
//                   userContext.authentication?.session?.role
//                 ) ? (
//                   <Element />
//                 ) : (
//                   <div>error</div>
//                 )
//               ) : (
//                 <Auth open={open} handleCloseModal={handleCloseModal} setOpen={setOpen} />
//               )
//             }
//           />
//           <Route
//             path="*"
//             element={<Navigate to="/" state={{ from: rest.location }} replace />}
//           />
//         </Routes>
//       }
//     />
//   );
// };

// export default AuthenticatedRoute;


import { Navigate, useNavigate } from "react-router-dom";
import {useContext,useState,useEffect} from "react";

import { Link } from "react-router-dom";
import UserContext from "../context/UserContext";
// import { useAuth } from "../hooks/useAuth";

export const AuthenticatedRoute = ({ children }:any) => {
  const context = useContext(UserContext);

// console.log("context",context)

  const isAuthenticated = context.authentication.authenticated;

  // const isAuthroized = context?.authentication?.session?.role[0]?.authority === ("ROLE_ADMIN:EDIT" || "ROLE_ADMIN:VIEW")

  const isAuthroized = context?.authentication?.session?.role[0]?.authority &&["ROLE_ADMIN:EDIT", "ROLE_ADMIN:VIEW", "ROLE_OPERATOR:EDIT", "ROLE_OPERATOR:VIEW"].includes(context?.authentication?.session.role[0].authority)
  
// console.log({isAuthenticated,isAuthroized})
  
  if (!isAuthenticated || !isAuthroized) {
   return <Navigate to="/login"/>
  }
  return children;
};

// export const AuthenticatedRoute = ({ children }: any) => {
//   const context = useContext(UserContext);
//   const navigate = useNavigate();

//   const [open, setOpen] = useState(false);

//   const handleOpenModal = () => {
//     redirectToLogin();
//   };

//   const handleCloseModal = () => setOpen(false);

//   const isAuthenticated = context.authentication.authenticated;

//   const redirectToLogin = () => {
//     navigate('/'); // Change '/login' to the path you want to show for authentication
//     setOpen(true); // Open the modal after navigation
//   };

//   useEffect(() => {
//     if (!isAuthenticated) {
//       redirectToLogin();
//     }
//   }, [isAuthenticated]);

//   if (!isAuthenticated) {
//     return <Auth open={open} handleCloseModal={handleCloseModal} setOpen={setOpen} />; // Render nothing here, as the redirection is handled in redirectToLogin
//   }

//   return children;
// };

