import React from 'react';
import { InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  input: {
    height: "100%",
    color: 'inherit', // Text color
    width: '100%',
    backgroundColor: 'transparent', // Remove background
    fontSize: '16px', // Set font size
    padding: '0', // Set padding
    '&:focus': {
      outline: 'none', // Remove outline on focus
    },
  },
}));

const InputFieldComponent = ({className="",fullWidth=false,name, value, onChange,type,maxLength=524288, isMultiline = false, minRows = 0, id = "", placeholder = "",disabled=false }: any) => {

  const classes = useStyles()
  return (
    <InputBase
      id={id}
      className={className || classes.input}
      name={name}
      
      slotProps={{
        input:{
          // pattern:"[A-Z]",
          type:type,
          maxLength:maxLength,
          disabled:disabled
        }
      }}
      // type={type}
      value={value}
      placeholder={placeholder}
      multiline={isMultiline}
      minRows={isMultiline && minRows}
      onChange={onChange}
      fullWidth={fullWidth}
      inputProps={{ 'aria-label': 'custom input' }}
    />
  );
};

export default InputFieldComponent;
