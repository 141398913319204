import React, { useState, useEffect } from 'react'
import { Box, Button, Autocomplete, Grid, TextField, Typography, InputBase, Tooltip } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import editIcon from "../../assets/icons/edit-icon.svg";
import CommonModal from '../modal/CommonModal';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import InputFieldComponent from '../common/InputFieldComponent';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import { serviceProvider } from '../../provider/serviceProvider';
import { toast } from 'react-toastify';
import SelectFieldComponent from '../common/SelectFieldComponent';
import ButtonComponent from '../common/ButtonComponent';

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    border: none;
    background-color: transparent;
    outline: none;
  }

  .MuiAutocomplete-inputRoot {
    &:before {
      border-bottom: none;
    }
  }
 

  &:hover .MuiAutocomplete-inputRoot:before,
  &.Mui-focused .MuiAutocomplete-inputRoot:before,
  &.Mui-focused .MuiAutocomplete-inputRoot:hover:before,
  &.Mui-focused .MuiAutocomplete-inputRoot.Mui-focused:before {
    border-bottom: none;
  }
  
`;

const useStyles = makeStyles((theme) => ({
  input: {
    // border:"1px solid red",
    height: "100%",
    color: 'inherit', // Text color
    width: '100%',
    backgroundColor: 'transparent', // Remove background
    fontSize: '16px', // Set font size
    padding: '0', // Set padding
    '&:focus': {
      outline: 'none', // Remove outline on focus
    },
  },
}));


const initalUserInput = {
    active: true,
    airportHandlingCharges: 0.0,
    airportId: "",
    city: "",
    contactDetails: "",
    governingBody: "",
    iataCode: "",
    icaoCode: "",
    imageUrlList: "",
    latitude: 0.0,
    longitude: 0.0,
    name: "",
    nightLanding: true,
    operatingEndTime: "",
    operatingStartTime: "",
    otherInformation: "",
    permanentNotoms: "",
    restrictedAircraftsList: "",
    runwayLength: 0,
    state: "",
    timezone: "",
    baseLocation: true
}


const EditAirport = ({ airportDetails,getAllAirports }:any) => {
  const [open, setOpen] = useState<boolean>(false);
  // const [flightDetails,setFlightDetails]=useState({})
  const [userInput, setUserInput] = useState(initalUserInput)
  const [startDateTime, setStartDateTime] = useState<any>(dayjs(null));
  const [arrivalDateTime, setArrivalDateTime] = useState<any>(dayjs(null));
  const [loader, setLoader] = useState(false);
//   const [airports, setAirports] = useState([]);

  const classes = useStyles()
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserInput({ ...userInput, [name]: value })
  }


  const handleStartDateTime = (newDate: any) => {
    setStartDateTime(newDate);
  }
  const handleArrivalDateTime = (newDate: any) => {
    setArrivalDateTime(newDate);
  }

//   const getAllAirportsList = () => {
//     setLoader(true);
//     serviceProvider.airportService.getAllAirports().then((res) => {
//       // console.log({res})
//       if (res.responseCode === 200) {
//         setAirports(res.data)
//       }
//       setLoader(false)

//     }).catch((err) => {
//       console.log({ err })
//       setLoader(false)
//     })
//   }

  const handleEditAirport = (airportId:any) => {
    // const params = airportId;
    // console.log({airportId});
    const startDt = startDateTime ? dayjs(new Date(startDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    const endDt = arrivalDateTime ? dayjs(new Date(arrivalDateTime).toISOString()).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : '';
    const payload = { ...userInput , operatingStartTime:startDt, operatingEndTime:endDt };

    
    setLoader(true);
    serviceProvider.airportService.put(airportId, payload).then((res:any) => {
      if (res.responseCode === 200) {
        toast.success(res.message)
        handleCloseModal()
        const query = `airportName=all&city=all&state=all&runwayLength=0&operatingStartTime=all&operatingEndTime=all&aircraftName=all&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`
        getAllAirports(query)
        // setTimeout(()=>{
        //     window.location.reload()
        // },100)
      }else{
        toast.error(res?.message||"Something Went Wrong.")
      }
      setLoader(false)
      console.log({ res })
    }).catch((err) => {
        toast.error(err?.data?.message||"Something Went Wrong.")
      console.log({ err })
      setLoader(false)
    })
  }
  



  useEffect(() => {
    setUserInput(airportDetails)
    setStartDateTime(dayjs(airportDetails?.operatingStartTime));
    setArrivalDateTime(dayjs(airportDetails?.operatingEndTime));
    // getAllAirportsList()
    // console.log({ flightDetails })
  }, [airportDetails])


//   const handleEditAirport=()=>{

//   }

  const modalBody = (
    <Grid container spacing={1}>
            <Grid item xs={12} className="modal-table-container">
                <Grid container className='heading'>
                    <Grid item xs={12}>
                        <Typography variant='h6' component='h6'>Edit Airport</Typography>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-name"
                                type="text"
                                name="name"
                                value={userInput.name}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-name'></span>
                        </div>


                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            City
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-city"
                                type="text"
                                name="city"
                                value={userInput.city}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-city'></span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            State
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-state"
                                type="text"
                                name="state"
                                value={userInput.state}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-state'></span>
                        </div>

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            IATA Code
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                        type="text"
                            name="iataCode"
                            maxLength={3}
                            value={userInput.iataCode}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            ICAO Code
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            type="text"
                            name="icaoCode"
                            maxLength={4}
                            value={userInput.icaoCode}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Governing Body
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                        type="text"
                            name="governingBody"
                            value={userInput.governingBody}
                            onChange={handleInputChange}
                        />

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Runway Length (in meters)
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-runway-length"
                                type="number"
                                name="runwayLength"
                                value={userInput.runwayLength}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-runway-length'></span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Latitude
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-latitude"
                                type="number"
                                name="latitude"
                                value={userInput.latitude}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-latitude'></span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Longitude
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <div>
                            <InputFieldComponent
                                id="airport-longitude"
                                type="number"
                                name="longitude"
                                value={userInput.longitude}
                                onChange={handleInputChange}
                            />
                            <span className="modalError" id='errorDiv-airport-longitude'></span>
                        </div>

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Operating StartTime
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <DateTimePickerComponent
                            placeholder="Start Time"
                            value={startDateTime}
                            onChange={handleStartDateTime}
                        />

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Operating EndTime
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <DateTimePickerComponent
                            placeholder="End Time"
                            value={arrivalDateTime}
                            onChange={handleArrivalDateTime}
                        />
                    </Grid>
                </Grid>

                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Timezone
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="timezone"
                            type="text"
                            value={userInput.timezone}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">
                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Handling Charges
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                <div>
                <InputFieldComponent
                            id="airport-handling-charge"
                            name="airportHandlingCharges"
                            type="number"
                            value={userInput.airportHandlingCharges}
                            onChange={handleInputChange}
                        />
                        <span className="modalError" id='errorDiv-airport-handling-charge'></span>
                </div>

                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Contact Details
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="contactDetails"
                            type="text"
                            placeholder="Mobile Number, Email"
                            // maxLength={10}
                            value={userInput.contactDetails}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>

                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Other Information
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="otherInformation"
                            type="text"
                            value={userInput.otherInformation}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Grid container className="edit-detail-item">

                    <Grid item xs={6} className='edit-item dark-bg'>
                        <Typography variant='body1' component='p'>
                            Permanent Notoms
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className='edit-item'>
                        <InputFieldComponent
                            name="permanentNotoms"
                            type="text"
                            value={userInput.permanentNotoms}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} >
                <Grid container columnSpacing={2} display="flex" justifyContent="center" alignItems="center" mt="20px">
                    <Grid item xs={2}>
                        <ButtonComponent loader={loader} disabled={loader} label="Save" onClick={()=>handleEditAirport(airportDetails?.airportId)} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
  )
  return (
    <>
      <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} />
     <Tooltip title="Edit">
     <Button onClick={handleOpenModal}>
        <img src={editIcon} alt="edit icon" height="20px" width="20px" />
      </Button>
     </Tooltip>
    </>
  )
}

export default EditAirport;