// import React from 'react'

// const SpinLoader = () => {
//   return (
//     <div>SpinLoader</div>
//   )
// }

// export default SpinLoader

import React, { useEffect } from "react";
import LoaderGif from '../../assets/gifs/bmj-loader.gif';
// import "../../style/css/loader.css";

const SpinLoader  = () => {
//   useEffect(() => {}, [props.isVisible]);
  return (
    <div>
        <div className="lds-roller">
        <div id="containerLoader">
        </div>
        <img src={LoaderGif} alt="loading" height={200} width={200}  />
        </div>
    </div>
  );
};

export default SpinLoader

