export const color = {
    black: '#000000',
    red: '#ff0000',
    blue: '#494EB8',
    white: '#ffffff',
    primary: '#CA3657',
    colorTwo: '#ffcc00',
    green: "#198754"
}

export const remSize = {
    rem_half: '0.5rem',
    rem_1: '1rem',
    rem_2: '2rem',
    rem_5: '5rem',
}

export const percentSize = {
    hundred: '100%',
}