import React, { useState, useEffect } from 'react'
import { Typography, Grid, Box, Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import { serviceProvider } from '../../provider/serviceProvider';
import SearchBar from '../searchBar/SearchBar';
import CommonPagination from '../common/CommonPagination';
import { toast } from 'react-toastify';
import OngoingBooking from './OngoingBooking';
import SentBooking from './SentBooking';
import CompletedBooking from './CompletedBooking';
import FailedBooking from './FailedBooking';



const BookingManagement: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [searchQuery,setSearchQuery]=useState("");
  // const navigate = useNavigate()
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/bookings-management?index=${newValue}`)
  };

  // const handleSearch = (e: any) => {
  //   const searchQuery = e.target.value || "all";
  //   // setSearchQuery(e.target.value)
  //   let status;
  //   if (value === 0) status = "PENDING"
  //   else if (value === 1) status = "CONFIRMED"
  //   else if (value === 2) status = "COMPLETED"
  //   else if (value === 3) status = "FAILED"


  //   // const query = `search?query=${searchQuery}&status=${status}&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`
  //   const query = `search?query=${searchQuery}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  //   setLoader(true)
  //   serviceProvider.bookingService.get(query).then((res: any) => {
  //     if (res.responseCode === 200) {
  //       setBookings(res.data.data)
  //       setTotalPages(res.data.totalRecordCount);


  //     } else {
  //       toast.error(res?.message || "Something Went Wrong!")
  //     }
  //     // setBookings(res.data)
  //     // if (res.responseCode === 200 && value === 0) setActiveUsers(res.data.details)
  //     // else if (res.responseCode === 200 && value === 1) setInActiveUsers(res.data.details)
  //     setLoader(false)
  //   }).catch((err) => {
  //     console.log({ err })
  //     setLoader(false)
  //   })
  // }


  const getBookings = (query:string) => {
    
    setLoader(true);

    serviceProvider.bookingService.get(query).then((res: any) => {
      if (res.responseCode === 200) {
        setBookings(res.data.data);
        setTotalPages(res.data.totalRecordCount);

      } else {
        toast.error(res?.message ||"Something Went Wrong!")
      }
      setLoader(false)
    }).catch((err) => {
      setLoader(false)
      console.log({ err })
    })
  }


  useEffect(() => {
    let status="";
    if (value === 0) status = "PENDING"
    else if (value === 1) status = "CONFIRMED"
    else if (value === 2) status = "COMPLETED"
    else if (value === 3) status = "FAILED"

    let query="";

    if (value === 0) {
      // query = `search?query=all&status=pending&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`
      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    }
    else if (value === 1) {
      // setQuotations([])
      // query = `search?query=all&status=completed&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`

      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    }  else if (value === 2) {
      // setQuotations([])
      // query = `search?query=all&status=completed&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`

      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    } else if (value === 3) {
      // setQuotations([])
      // query = `search?query=all&status=cancelled&offset=0&limit=10&order=updatedTs.desc&profile=BASIC`
      query = `search?query=${searchQuery || "all"}&status=${status}&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
    }
    getBookings(query)
  }, [value,page,searchQuery])

  useEffect(()=>{
    if(indexNumber){
      setValue(indexNumber)
    }else{
      setValue(0)
    }
  },[indexNumber])


  // value,page
  return (
    <Grid container spacing={2} className="quotation-container">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={5} md={5} className="quotation-heading">
            <Typography variant='h6' component='h6'>Bookings</Typography>
          </Grid>
          <Grid item xs={7} md={7}>
            <SearchBar 
            value={searchQuery} 
            onChange={(e:any)=>setSearchQuery(e.target.value)}
            // onChange={handleSearch}
               placeholder="Search by Phone number" />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} >
        <Box className="quotation-tabs-container">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Ongoing" {...a11yProps(0)} />
              <Tab label="Confirmed" {...a11yProps(1)} />
              <Tab label="Completed" {...a11yProps(2)} />
              <Tab label="Failed" {...a11yProps(3)} />
            </Tabs>
          </Box>
          {/* <CustomTabPanel value={value} index={0}>


          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={0}>
            <OngoingBooking loader={loader} bookings={bookings}/>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
          <SentBooking loader={loader} bookings={bookings}/>   
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
          <CompletedBooking loader={loader} bookings={bookings}/>   
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <FailedBooking loader={loader} bookings={bookings}/>
          </CustomTabPanel>
        </Box>
       {
        bookings?.length >= 1 &&  <Box className="pagination-box">
        <CommonPagination page={page} setPage={setPage} totalPages={totalPages} />
      </Box>
       }
      </Grid>
    </Grid>
  )
}

export default BookingManagement;