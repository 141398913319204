import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Card,
  FormControl,
  Typography,
  InputLabel,
  TextField,
} from "@mui/material";
import S3 from "react-aws-s3-typescript";
import { awsConfig } from "../../configuration/config.json";
import { serviceProvider } from "../../provider/serviceProvider";
// import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { sessionUtility } from "../../utility/sessionUtility";
// import WUEInput from "../../UI/InputComponent/WUEInput";
// import WUEButton from "../../UI/Button/WUEButton";
import blogbanner from "../../assets/images/default_blog_banner.svg";
import blogEditIcon from "../../assets/icons/edit-blog-icon.svg";
// import editIcon from "../../assets/icons/edit-icon.svg";
import fileuploadIcon from "../../assets/icons/add-image.svg";
import styles from "./postBlog.module.scss";
// import { URL } from "url";
import veriryIcon from "../../assets/icons/successIcon.svg";
import SpinLoader from "../../components/loader/SpinLoader";
import CommonModal from "../../components/modal/CommonModal";
import EditorNew from "../../components/common/EditorNew";
import ButtonComponent from "../../components/common/ButtonComponent";
import useImageUploader from "../../hooks/useImageUploader";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
const EditBlogModalBody = ({isActive,handleCloseModal:onEditModalClose,blogDetails,pageState,getBlogsList}:any) => {

  const loggedUserDetails = sessionUtility.getAuthentication().session;
  const [loaders, setLoaders] = useState<any>({
    editBlog:false,
    verfiyCustomUrl:false,
    category:false
  });
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [userInput, setUserInput] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [imagetoupload, setImagetoUpload] = useState("");
  const [imageEvent, setImageEvent] = useState<any>("");
  const [customUrl, setCustomUrl] = useState("");
  const [isCustomUrlVerified, setIsCustomUrlVerified] = useState<boolean>(false);



  const { handleImageChange, loader: imageLoader } = useImageUploader(
    awsConfig 
  );

  const coverImageRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const handleChooseCoverImg = (e:any) => {
    coverImageRef.current?.click();
    setImageEvent(e)
  };

  const updateUserInput = (imageUrl: string) => {
    if (imageUrl !== "") setUserInput({ ...userInput, coverImgUrl: imageUrl });
  };

  const handleInputChange = (e:any) => {
    const {name, value} = e.target;
    setUserInput({...userInput, [name]:value})
    if (value) {
      errors[name] = "";
    }
  }

  const handleUploadCoverImage =()=>{
    if(!imageEvent){
      toast.error("Please select cover image");
    }else{
      handleCloseModal();
      handleImageChange(imageEvent, updateUserInput)
    }
      
    
  }
  function calculateReadingTimeFromHTML(htmlContent: string) {
    const textContent = htmlContent.replace(/<[^>]*>/g, "");
    const words = textContent.split(/\s+/).length;
    const wordsPerMinute = 200;
    const readingTime = Math.ceil(words / wordsPerMinute);
    return readingTime;
  }

  // console.log({blogDetails})

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const fetchAllCategory = async () => {
    setLoaders((prev:any)=>({ ...prev, category: true }));
    try {
      const param = `categories?categoryId=NA`;
      const res: any = await serviceProvider.blogService.get(param);
      if (res?.httpStatus === 200) {
        setCategories(res?.result);
        console.log(res?.result);
      } else {
        toast.error("error while fetching categoris");
        console.log(res);
      }
    } catch (err: any) {
      toast.error(err?.error?.message || "something went wrong");
    } finally {
      setLoaders((prev:any)=>({ ...prev, category: false }));
    }
  };


  const customUrlVerify = async (customUrl: string) => {
    setLoaders((prev:any)=>({ ...prev, verfiyCustomUrl: true }));
    try {
      const param = `customurls/valid/BLOG?customUrl=${customUrl}`;
      const res: any = await serviceProvider.blogService.checkCustomUrl(param);
      if (res?.httpStatus === 200) {
        // setUrlVerified(true);
        setIsCustomUrlVerified(true)
        toast.success(res?.result || 'Custom url verified.');
      } else {
        toast.error(res?.error?.message || "Something went wrong!");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "Something went wrong!");
    } finally {
      setLoaders((prev:any)=>({ ...prev, verfiyCustomUrl: false }));
    }
  };

  const handleUpdateBlog = async () => {
    if (
      !userInput.title ||
      !userInput.summary ||
      !userInput.categoryId ||
      !userInput.content 
    ){
      setErrors({
        ...errors,
        // Set error messages for each field that is empty
        title: !userInput.title
          ? "Title is required"
          : "",
          summary: !userInput.summary
          ? "Summary is required"
          : "",
          categoryId: !userInput.categoryId ? "Category is required" : "",
          content: !userInput.content ? "Content is required" : "",
       
      });
    } else{
      setLoaders((prev:any)=>({ ...prev, editBlog: true }));
      const payload = {
        ...userInput,
        uid:loggedUserDetails?.uId,
        minToRead:calculateReadingTimeFromHTML(userInput.content),
        custUrl: isCustomUrlVerified ? customUrl : "",
      }

      if(customUrl && !isCustomUrlVerified) {
        toast.error("Please verify custom url.");
        return
      } 
    try {
      const res: any = await serviceProvider.blogService.put(
        blogDetails?.blogpostId,
        payload
      );

      if (res?.httpStatus === 200) {
        toast.success("successfully updated blog post");
        onEditModalClose();
        const query = `search?uId=NA&categoryId=NA&name=${"NA"}&tags=NA&content=NA&categoryName=NA&blogpostId=NA&custUrl=NA&offset=${pageState.page}&limit=${pageState.pageSize}&order=recordUpdatedTs.desc&profile=BASIC&title=NA&isActive=${isActive}&isFeatured=false`;
        getBlogsList(query);
      } else {
        toast.error(res?.error?.message || "something went wrong");
      }
    } catch (err: any) {
      toast.error(err?.data?.error?.message || "error");
    } finally {
      setLoaders((prev:any)=>({ ...prev, editBlog: false }));
    }
    }
    
  };

  useEffect(() => {
    setEditorLoaded(true);
    fetchAllCategory();
  }, []);

  useEffect(() => {
    setIsCustomUrlVerified(true);
    setCustomUrl(blogDetails?.custUrl);
    setUserInput(blogDetails)
  }, [blogDetails])


  // {
  //   "active": true,
  //   "blogpostId": "string",
  //   "categoryId": "string",
  //   "commentCount": 0,
  //   "content": "string",
  //   "coverImgUrl": "string",
  //   "custUrl": "string",
  //   "featured": true,
  //   "minToRead": 0,
  //   "name": "string",
  //   "reactionCount": 0,
  //   "recordCreatedBy": "string",
  //   "recordCreatedTs": "2024-08-28T06:34:37.392Z",
  //   "recordUpdatedBy": "string",
  //   "recordUpdatedTs": "2024-08-28T06:34:37.392Z",
  //   "reportAbuseCount": 0,
  //   "shareCount": 0,
  //   "summary": "string",
  //   "tags": "string",
  //   "title": "string",
  //   "uid": "string"
  // }


  return   <>
     
  <CommonModal
    open={open}
    handleCloseModal={handleCloseModal}
    className={""}
    modalBody={ <div className={styles.modalStyling}>
    <Typography variant="body2">
      Upload Cover Image/Banner for Blog
    </Typography>
    
    <div className={styles.imageInputContainer}>
      {
        imagetoupload ?   
        <img
        src={imagetoupload}
        alt="preview"
        width="100%"
        height="100%"
        style={{ objectFit: "cover", borderRadius: "10px" }}
      /> :
        <>
      <img
        src={fileuploadIcon}
        alt="profile-img"
        width="30px"
        height="30px"
        style={{ objectFit: "cover", borderRadius: "10px" }}
      />
      <label
        htmlFor="fileInput"
        style={{ fontSize: "14px", color: "#337DD9" }}
        
      >
        choose file
        <input
          style={{ display: "none" }}
          // ref={coverImageRef}
          id="fileInput"
          type="file"
          name="coverImgUrl"
          onChange={(e)=>handleChooseCoverImg(e)}
          // onChange={(e)=>setImageEvent(e)}
          // onChange={(e: any) => handleImageChange(e, updateUserInput)}
        />
      </label>
      </>
      }

      { imageEvent?.target?.files[0] ? imageEvent?.target?.files[0].name : (
        <Typography variant="body2">No file chosen</Typography>
      )}{" "}
    </div>
    <Typography variant="caption">
            Dimensions - 1345 x 531 px
            </Typography>
    <Typography variant="body2">
      Only accepts .png, .jpg, .jpeg
    </Typography>
    <Button className="rounded-btn-fill" onClick={handleUploadCoverImage}>
      Click to Upload
    </Button>  
    </div>}
  />  
      <Grid container rowGap="20px">
        <Grid item xs={12} >
        <Typography variant="h6" style={{marginBottom:"10px"}} >
            Edit Blog
          </Typography>
        </Grid>
        <Grid item xs={12}>
     
          <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}} shrink>
              Blog Banner/Cover Image
              </InputLabel>
          <Grid container rowGap="20px">

          {imageLoader ? (
                  <Grid item xs={12}>
                    <Loader height="400px" width="100%" />
                  </Grid>
                ) :(
            <Grid
              item
              xs={12}
              
              style={{ position: "relative" }}
            >
              <label
                htmlFor="fileInput"
                style={{
                  cursor: "pointer",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",

                    width: "100%",
                    height: "400px",
                    position: "relative",
                  }}
                >
                  <img
                    src={userInput?.coverImgUrl || blogbanner}
                    alt="profile-img"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                  {/* <input
                    name="image"
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    // onChange={changeHandler}
                  /> */}
                </div>
              </label>
              <Button
                className={`rounded-btn-outlined ${styles.bannerUploadBtn}`}
                style={{ position: "absolute", top: 20, right: 20 }}
                onClick={handleOpenModal}
              >
                <img
                  src={blogEditIcon}
                  alt="pencil-icon"
                  width="20px"
                  height="20px"
                />
                <span>
                  Update
                </span>
              </Button>
            </Grid>
                )}

            <Grid item xs={12}>
              {/* <WUETypography variant={"h6"}>Blog Title</WUETypography> */}
              <FormControl variant="standard" fullWidth required>
          <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}} shrink htmlFor="title" required>
          Blog Title
          </InputLabel>
              <TextField
                fullWidth
                size="small"
                  variant="outlined"
                    className='inputField mt-20'
                id="title"
                name = 'title'
                required
                value={userInput?.title}
                onChange={handleInputChange}
                placeholder="Enter blog title"
                InputProps={{
                      disableUnderline: true,
                      // sx:{m:"10px"}
                    }}
                
              />
                {errors?.title && <span className="modalError">{errors?.title}</span>}
              </FormControl>
            </Grid>

            <Grid item xs={12} >
              {/* <WUETypography variant={"h6"}>Blog Subtitle</WUETypography> */}
              <FormControl variant="standard" fullWidth required>
              <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}}shrink htmlFor="summary" required>
          Blog Summary
          </InputLabel>
              <TextField
                fullWidth
                size="small"
                   variant="outlined"
                    className='inputField mt-20'
                required
                name = 'summary'
                value={userInput?.summary}
                onChange = {handleInputChange} 
                placeholder="Enter blog summary"
                sx={{padding:"10px"}}
                InputProps={{
                      disableUnderline: true,
                      // sx:{m:"10px"}
                    }}
                    multiline
                    minRows={4}
              
               
              />
                 {errors?.summary && <span className="modalError">{errors?.summary}</span>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
            <Grid container xs={12} rowGap="20px" columnSpacing="20px">
              <Grid item xs={12} md={4}>
                {/* <WUETypography variant={"h6"}>Blog Category</WUETypography> */}
                <FormControl variant="standard" fullWidth required>
                <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}} shrink htmlFor="categoryId"  required>
          Blog Category
          </InputLabel>
              <TextField
                fullWidth
                size="small"
                  variant="outlined"
                    className='inputField mt-20'
                  select
                  name = 'categoryId'
                  id="categoryId"
                  value={userInput?.categoryId || blogDetails?.categoryId}
                  onChange={handleInputChange}
                  required
                  placeholder="Enter blog category"
                  InputProps={{
                      disableUnderline: true,
                      // sx:{m:"10px"}
                    }}
               
                  
                >
                  {categories?.map((category: any) => (
                    <MenuItem
                      key={category.categoryId}
                      value={category.categoryId}
                    >
                      {category?.name}
                    </MenuItem>
                  ))}
                </TextField>
                {errors?.categoryId && <span className="modalError">{errors?.categoryId}</span>}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                    {/* <WUETypography variant={"h6"}>Custom URL</WUETypography> */}
                    <FormControl variant="standard" fullWidth >
                    <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}} shrink htmlFor="custUrl" >
              Custom URL
              </InputLabel>
                  <TextField
                    fullWidth
                    size="small"
                        variant="outlined"
                    className='inputField mt-20'
                      id="custUrl"
                      value={customUrl}
                      onChange={(e) => {
                        setIsCustomUrlVerified(false);
                        setCustomUrl(e.target.value);
                      }}
                      // required
                      placeholder="Enter custom url"
                     
                      // sx={{ mt: 1 }}
                      InputProps={{
                      disableUnderline: true,
                        endAdornment: (
                          <Button
                          className="common-button-underlined"
                          onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                          sx={{ padding:"0" }}
                          disabled={isCustomUrlVerified || loaders.verfiyCustomUrl}
                        >
                        <p style={{fontSize:"14px",display:"flex",alignItems:"center",gap:"4px",paddingLeft:"20px"}}>  
                          <span>{isCustomUrlVerified ? "Verified" : "Verify"}</span>
                          {isCustomUrlVerified ? (
                            <img src={veriryIcon} alt="verify-icon" />
                          ) : (
                            ""
                          )}</p>
                        </Button>
                        ),
                      }}
                    />
                    </FormControl>
                  </Grid>

              {/* <Grid item xs={12} md={4}>
               
                <FormControl variant="standard" fullWidth >
                <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}} shrink htmlFor="custUrl" >
          Custom URL
          </InputLabel>
              <TextField
                fullWidth
                size="small"
                variant="standard"
                className='search-input mt-20'
                  id="custUrl"
                  value={customUrl}
                  onChange={(e) => {
                    setCustomUrl(e.target.value);
                  }}
                  // required
                  placeholder="Enter custom url"
                 
                  // sx={{ mt: 1 }}
                  InputProps={{
                  disableUnderline: true,
                    endAdornment: (
                      <Button
                        className="common-button-underlined"
                        onClick={() => customUrlVerify(customUrl)} // Replace handleVerify with your verify logic function
                        sx={{ mr: 2,padding:"0" }}
                      >
                      <p style={{fontSize:"14px"}}>  {isCustomUrlVerified ? "Verified" : "Verify"}
                        {isCustomUrlVerified ? (
                          <img src={"veriryIcon"} alt="verify-icon" />
                        ) : (
                          ""
                        )}</p>
                      </Button>
                    ),
                  }}
                />
                </FormControl>
              </Grid> */}
            </Grid>
            </Grid>

            <Grid item xs={12} >
              {/* <WUETypography
                variant={"h6"}
                style={{ marginBottom: "10px" }}
              >
                Content
              </WUETypography> */}
              <FormControl variant="standard" fullWidth required>
              <InputLabel  sx={{fontWeight:600,color:"#4A4A4A"}} shrink required>
          Content
          </InputLabel>
          <div style={{marginTop:"20px"}}>
          <EditorNew
                // id="content"
                
                editorLoaded={editorLoaded}
                value={userInput?.content}
                onChange={(data: string) => {
                  if (data) {
                    errors["content"] = "";
                  }
                  setUserInput({...userInput, content:data})
                }}
                placeholder="Write content"
              />
               {errors?.content && <span className="modalError">{errors?.content}</span>}
          </div>
             
              </FormControl>
            </Grid>

            <Grid item  xs={12} display="flex" justifyContent="center" alignItems="center">
            <ButtonComponent loader={loaders.editBlog}
                    disabled={loaders.editBlog} fullWidth={false} onClick={handleUpdateBlog} label="Update" />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
</>
}

export default EditBlogModalBody;
