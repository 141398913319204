// import {
//   Box,
//   Checkbox,
//   FormControlLabel,
//   Grid,
//   Typography,
// } from "@mui/material";
// import React, { useContext, useEffect, useState } from "react";
// import Loader from "../loader/Loader";
// import NoResult from "../noResult/NoResult";
// import CommonPagination from "../common/CommonPagination";
// import { toast } from "react-toastify";
// import { serviceProvider } from "../../provider/serviceProvider";
// import SearchBar from "../searchBar/SearchBar";
// import AircraftAvailabiltyCard from "../operatorManagement/AircraftAvailabiltyCard";
// import UserContext from "../../context/UserContext";
// import ButtonComponent from "../common/ButtonComponent";
// import CommonModal from "../modal/CommonModal";
// import AutoCompleteComponent from "../common/AutoCompleteComponent";
// import DateTimePickerComponent from "../common/DateTimePickerComponent";
// import dayjs from "dayjs";

// const initialPayload = {
//   active: true,
//   aircraftId: "",
//   availabilityEndDt: "",
//   availabilityId: "",
//   availabilityStartDt: "",
//   operatorUid: "",
//   startAirportId: "",
// };
// const MyAircrafts = () => {
  // const [open, setOpen] = useState(false);
  // const [loader, setLoader] = React.useState<boolean>(false);
  // const [operatorAircrafts, setOperatorAircrafts] = useState([]);
  // const [page, setPage] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
  // const [checked, setChecked] = useState<boolean>(false);
  // const [searchQuery, setSearchQuery] = useState<string>("");
  // const [userInput, setUserInput] = useState<any>(initialPayload);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [airports, setAirports] = useState<any>([]);
  // const [assginedAircrafts, setAssginedAircrafts] = useState<any>([]);
  // const context = useContext(UserContext);
  // const operatorId = context?.authentication?.session?.uId;

  // const handleStartDateChange = (newDate: any) => {
  //   setStartDate(newDate);
  // };
  // const handleEndDateChange = (newDate: any) => {
  //   setEndDate(newDate);
  // };

  // const handleInputChange = (property: any, value: any) => {
  //   setUserInput({ ...userInput, [property]: value });
  //   // if (property === "aircraftId") {
  //   //     setUserInput({ ...userInput, aircraftId: newValue?.aircraftId })

  //   // } else if (property === "airportId") {
  //   //     setUserInput({ ...userInput, startAirportId: newValue?.airportId })
  //   // }
  //   // validateFieldsOnInput(property)
  // };

  // const handleOpenModal = (operator: any) => {
  //   setOpen(true);
  //   setUserInput({ ...userInput, operatorId: operator?.uId });
  // };
  // const handleCloseModal = () => setOpen(false);

  // // const handleSearch=(e:any)=>{

  // //     const searchQuery = e.target.value || "all";

  // //     const param = `search?operatorUid=${operatorId}&fullName=all&aircraftName=${searchQuery}&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  // //     getOperatorAircrafts(param)
  // // }

  // const getOperatorAircrafts = (param: any) => {
  //   setLoader(true);
  //   serviceProvider.aircraftAvailabilityService
  //     .get(param)
  //     .then((res: any) => {
  //       if (res.responseCode === 200) {
  //         setOperatorAircrafts(res.data.data);
  //         setTotalPages(res.data.totalRecordCount);
  //       } else {
  //         toast.error(res?.message || "Something Went Wrong!!");
  //       }
  //       setLoader(false);
  //     })
  //     .catch((err: any) => {
  //       setLoader(false);
  //     });
  // };
  // const getAssignedAircrafts = (operatorId: any) => {
  //   setLoader(true);
  //   serviceProvider.aircraftService
  //     .getAllAssingedAircrafts(operatorId, true)
  //     .then((res: any) => {
  //       if (res.responseCode === 200) {
  //         setAssginedAircrafts(res.data);
  //       } else {
  //         toast.error(res?.message || "Something Went Wrong!!");
  //       }
  //       setLoader(false);
  //     })
  //     .catch((err: any) => {
  //       setLoader(false);
  //     });
  // };

  // const handleSearch = (e: any) => {
  //   setSearchQuery(e.target.value);
  // };

  // const handleCheckbox = (e: any) => {
  //   setChecked(e.target.checked);
  // };

  // const handleAddAvailibility = (operatorUid: any) => {
  //   const formattedStartDate = startDate
  //     ? dayjs(new Date(startDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
  //     : "";
  //   const formattedEndDate = endDate
  //     ? dayjs(new Date(endDate).toISOString()).format("YYYY-MM-DDTHH:mm:ss")
  //     : "";
  //   // const formattedStartDate = startDate != undefined ? new Date(startDate).toISOString() : new Date();
  //   // const formattedEndDate = endDate != undefined ? new Date(endDate).toISOString() : new Date();

  //   const payload = {
  //     ...userInput,
  //     availabilityStartDt: formattedStartDate,
  //     availabilityEndDt: formattedEndDate,
  //     operatorUid,
  //   };

  //   setLoader(true);
  //   serviceProvider.aircraftAvailabilityService
  //     .post(payload)
  //     .then((res) => {
  //       setLoader(false);
  //       console.log(res);
  //       if (res.responseCode === 200) {
  //         toast.success(res.message);
  //         handleCloseModal();

  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 100);
  //       } else {
  //         toast.error(res?.message || "Something Went Wrong!!");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       setLoader(false);
  //       toast.error(err?.data?.message || "Something Went Wrong!!");
  //     });
  // };

  // //   const handleIsAircraftAvailable = (e: any) => {
  // // console.log(e.target.checked);

  // //     // if(e.target.checked){
  // //     //   setChecked(true)
  // //     // }else{
  // //     //   setChecked(false)
  // //     // }

  // //     // handleSearch(e,e.target.checked)
  // //   }

  // //   const handleSearch = (e:any) => {
  // //     const searchQuery = e.target.value === "" ? "all":e.target.value;
  // //     const param = `search?operatorUid=all&fullName=all&aircraftName=${searchQuery}&isActive=NA&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`

  // //   getAircraftAvailabilityList(param)

  // // //     const isActiveAircraft = e.target.checked ? true : "NA";

  // //     // const param = `search?operatorUid=all&fullName=all&aircraftName=${searchQuery}&isActive=${isActiveAircraft}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  // //     // setLoader(true);
  // //     // serviceProvider.aircraftAvailabilityService.get(param).then((res: any) => {
  // //     //   // console.log(res);
  // //     //   if (res.responseCode === 200) {

  // //     //     setAircraftAvailabilityLists(res.data.data)
  // //     //     setTotalPages(res.data.totalRecordCount)

  // //     //   } else {
  // //     //     toast.error("Something Went Wrong!!")
  // //     //   }
  // //     //   setLoader(false)

  // //     // }).catch((err: any) => {
  // //     //   setLoader(false)
  // //     // })
  // //   }

  // //   const handleCheckBox = (e:any) => {
  // // //     const searchQuery = e.target.value === "" ? "all":e.target.value;
  // // // console.log(searchQuery);

  // //     const isActiveAircraft = e.target.checked ? true : "NA";

  // //     const param = `search?operatorUid=all&fullName=all&aircraftName=all&isActive=${isActiveAircraft}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`
  // //     getAircraftAvailabilityList(param)
  // //   }

  // const modalBody = (
  //   <Grid container rowSpacing={3} className="edit-availability-modal">
  //     <Grid item xs={12}>
  //       <Typography variant="h6" component="h6">
  //         Add availability
  //       </Typography>
  //     </Grid>
  //     <Grid item xs={12}>
  //       <AutoCompleteComponent
  //         id="select-aircraftId"
  //         options={assginedAircrafts}
  //         className="select-box"
  //         // defaultValue={searchResults?.startAirport}
  //         getOptionLabel={(option: any) => `${option.name}`}
  //         // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
  //         // renderOption={(props: any, option: any) => (
  //         //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
  //         //         <Typography textAlign="left">{option.city}</Typography>
  //         //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
  //         //     </Box>
  //         // )}
  //         onChange={(e: any, newValue: any) =>
  //           handleInputChange("aircraftId", newValue?.aircraftId)
  //         }
  //         placeholder="Select Aircraft"
  //       />
  //       <span className="modalError" id="errorDiv-select-aircraftId"></span>
  //     </Grid>
  //     <Grid item xs={12}>
  //       <DateTimePickerComponent
  //         className="date-input"
  //         placeholder="Start Date and Time"
  //         disablePast={true}
  //         value={startDate}
  //         onChange={handleStartDateChange}
  //       />
  //     </Grid>
  //     <Grid item xs={12}>
  //       <DateTimePickerComponent
  //         className="date-input"
  //         placeholder="End Date and Time"
  //         disablePast={true}
  //         value={endDate}
  //         onChange={handleEndDateChange}
  //       />
  //     </Grid>
  //     <Grid item xs={12}>
  //       <Grid item xs={12}>
  //         <AutoCompleteComponent
  //           id="select-aiportId"
  //           options={airports}
  //           className="select-box"
  //           getOptionLabel={(option: any) => `${option.name}`}
  //           onChange={(e: any, newValue: any) =>
  //             handleInputChange("startAirportId", newValue?.airportId)
  //           }
  //           placeholder="Select Airport"
  //         />
  //         <span className="modalError" id="errorDiv-select-aiportId"></span>
  //       </Grid>
  //       <Typography variant="body1" component="p">
  //         Airport is not listed? Please <span>contact us</span>
  //       </Typography>
  //     </Grid>

  //     <Grid item xs={12} className="btn-container">
  //       <ButtonComponent
  //         label="Save"
  //         fullWidth={false}
  //         onClick={() => handleAddAvailibility(operatorId)}
  //       />
  //     </Grid>
  //   </Grid>
  // );

  // const getAllAirports = () => {
  //   setLoader(true);
  //   serviceProvider.airportService
  //     .getAllAirports()
  //     .then((res) => {
  //       if (res.responseCode === 200) {
  //         setAirports(res.data);
  //       } else {
  //         toast.error(res?.message || "Something Went Wrong!!");
  //       }
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       setLoader(false);
  //     });
  // };

  // console.log("operatorAircrafts.length",operatorAircrafts.length)

  // useEffect(() => {
  //   const isActive = checked ? checked : "NA";
  //   const query = searchQuery ? searchQuery : "all";
  //   const param = `search?operatorUid=${operatorId}&fullName=all&aircraftName=${query}&isActive=${isActive}&airportId=all&from=all&to=all&offset=${page}&limit=2&order=updatedTs.desc&profile=BASIC`;
  //   getOperatorAircrafts(param);
  // }, [page, operatorId, checked, searchQuery]);


  // useEffect(() => {
  //   getAssignedAircrafts(operatorId);
  // }, [operatorId]);


  // useEffect(() => {
  //   getAllAirports();
  // }, []);


//   return (
    // <>
    //   <CommonModal
    //     open={open}
    //     handleCloseModal={handleCloseModal}
    //     modalBody={modalBody}
    //   />
    //   <Grid container>
    //     <Grid item xs={12} display="flex" mb="5px" justifyContent="flex-end">
    //       <ButtonComponent
    //         fullWidth={false}
    //         label="Add Availability"
    //         onClick={handleOpenModal}
    //       />
    //     </Grid>
    //     <Grid item xs={5}>
    //       <SearchBar
    //         onChange={handleSearch}
    //         placeholder={`Search by aircraft name`}
    //       />
    //     </Grid>
    //     <Grid item xs={7}>
    //       <Box
    //         display="flex"
    //         alignItems="center"
    //         justifyContent="flex-end"
    //         mb="20px"
    //       >
    //         <FormControlLabel
    //           value={checked}
    //           control={<Checkbox size="small" onChange={handleCheckbox} />}
    //           label="Available Aircraft"
    //           labelPlacement="end"
    //         />
    //       </Box>
    //     </Grid>
    //   </Grid>
    //   <Grid rowSpacing={3} container>
    //     {loader ? (
    //       new Array(3).fill(0).map((_, id) => {
    //         return (
    //           <Grid item xs={12} key={id}>
    //             <Loader height="250px" width="100%" />
    //           </Grid>
    //         );
    //       })
    //     ) : operatorAircrafts.length >= 1 && loader === false ? (
    //       operatorAircrafts.map((aircraft: any) => {
    //         return (
    //           <Grid key={aircraft?.aircraftId} item xs={12}>
    //             <AircraftAvailabiltyCard
    //               aircraft={aircraft}
    //               isOperator={true}
    //             />
    //           </Grid>
    //         );
    //       })
    //     ) : (
    //       <Grid item xs={12}>
    //         <NoResult title={"Aircraft Availability"} />
    //       </Grid>
    //     )}
    //   </Grid>
    //   <Box className="pagination-box">
    //     <CommonPagination
    //       page={page}
    //       setPage={setPage}
    //       totalPages={totalPages}
    //     />
    //   </Box>
    // </>
//   );
// };

// export default MyAircrafts;




import React, { useContext, useEffect, useState } from 'react'
import { Typography, Grid, Button, TextField, InputAdornment, Box, Tabs, Tab, FormControlLabel, Checkbox } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import AirportTable from './AirportTable';
import { serviceProvider } from '../../provider/serviceProvider';
import Loader from '../loader/Loader';
import CommonModal from '../modal/CommonModal';
// import AddAirportForm from './AddAirportForm';
// import FilterSection from './FilterSection';
import SearchBar from '../searchBar/SearchBar';
import { toast } from 'react-toastify';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';

import CommonPagination from '../common/CommonPagination';
import NoResult from '../noResult/NoResult';
import { useLocation, useNavigate } from 'react-router-dom';
import AssignedAircrafts from '../operatorManagement/AssignedAircrafts';
import AircraftsAvailability from '../operatorManagement/AircraftsAvailability';
import AircraftAvailabiltyCard from '../operatorManagement/AircraftAvailabiltyCard';
import ButtonComponent from '../common/ButtonComponent';
import AutoCompleteComponent from '../common/AutoCompleteComponent';
import DateTimePickerComponent from '../common/DateTimePickerComponent';
import UserContext from '../../context/UserContext';
import dayjs from 'dayjs';
import OperatorAircrafts from './OperatorAircrafts';
import OperatorAircraftAvailability from './OperatorAircraftAvailability';


// function valuetext(value: number) {
//   return `${value}°C`;
// }



// const useStyles = makeStyles((theme) => ({
//   input: {
//     // border:"1px solid red",
//     height: "100%",
//     color: 'inherit', // Text color
//     width: '100%',
//     backgroundColor: 'transparent', // Remove background
//     fontSize: '16px', // Set font size
//     padding: '0', // Set padding
//     '&:focus': {
//       outline: 'none', // Remove outline on focus
//     },
//   },
// }));

// const initalUserInput = {
//   active: true,
//   airportId: "",
//   city: "",
//   contactDetails: "",
//   governingBody: "",
//   iataCode: "",
//   icaoCode: "",
//   imageUrlList: "",
//   latitude: "",
//   longitude: "",
//   name: "",
//   nightLanding: true,
//   operatingEndTime: "",
//   operatingStartTime: "",
//   otherInformation: "",
//   permanentNotoms: "",
//   recordCreatedBy: "",
//   recordCreatedTs: "",
//   recordUpdatedBy: "",
//   recordUpdatedTs: "",
//   restrictedAircraftsList: "",
//   runwayLength: "",
//   state: "",
//   timezone: ""
// }


const MyAircrafts: React.FC = () => {
  const [value, setValue] = React.useState(0);
 const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get('index');

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);


  

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(event)
    setValue(newValue);
    navigate(`/?index=${newValue}`)
  };

  useEffect(()=>{
    if(indexNumber){
      setValue(indexNumber)
    }else{
      setValue(0)
    }
  },[indexNumber])

  return (
    <>
      {/* <CommonModal open={open} handleCloseModal={handleCloseModal} modalBody={modalBody} /> */}
      <Grid container spacing={2} className="operator-container">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}  className="operator-heading">
              {/* <Typography variant='h6' component='h6'>List of Airports</Typography> */}
            </Grid>
            {/* <Grid item xs={7} md={7}>
              <SearchBar onChange={value === 0 ? handleSearchOperator : value === 1 ? null : null} placeholder={`Search by ${value === 0 ? "operator name" : value === 1 ? "aircraft name" : "operator name, aircraft name"}`} />
            </Grid> */}
            
          </Grid>
        </Grid>
      

        <Grid item xs={12} className="tabs-container">
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="List of Aircrafts" {...a11yProps(0)} />
                <Tab label="List of Aircrafts Availability" {...a11yProps(1)} />
                {/* <Tab label="Request of Operators" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            
            <CustomTabPanel value={value} index={0}>
             
              {/* <AssignedAircrafts /> */}
              <OperatorAircrafts/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            {/* <AircraftsAvailability /> */}
            <OperatorAircraftAvailability/>
      
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2}>
              <OperatorRequestList />
            </CustomTabPanel> */}


          </Box>




        </Grid>

      

      </Grid>
    </>
  )
}

export default MyAircrafts;
