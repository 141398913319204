// // import React from 'react'

// // const EmptyLegManagement = () => {
// //   return (
// //     <div>EmptyLegManagement</div>
// //   )
// // }

// // export default EmptyLegManagement

// // import React from 'react'

// // const QuotationManagement = () => {
// //   return (
// //     <div>QuotationManagement</div>
// //   )
// // }

// // export default QuotationManagement

// import React from 'react'
// import { Typography, Card, Grid, Button, TextField, InputAdornment, Box, Tabs, Tab, CardActionArea, CardMedia, CardContent, Divider } from "@mui/material";
// import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import dividerPlane from "../../assets/icons/divider-plane.svg"
// import { useNavigate } from 'react-router-dom';
// import EmptyLegTable from './EmptyLegTable';
// import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';

// const EmptyLegManagement:React.FC = () => {
//   const [value, setValue] = React.useState(0);
//   const navigate= useNavigate()
//   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
//     setValue(newValue);
//   };

//   const handleFinalizeQuotation=()=>{
//     navigate("/finalize-quotation-amount")
//   }
//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={12}>
//             <Typography variant='h6' component='h6'>Empty Leg</Typography>
//           </Grid>
//           <Grid item xs={12}>
//           <Button variant="contained" className="rounded-btn-fill" onClick={()=>navigate("/empty-leg-management/add")}>Add Empty Leg</Button>
//         </Grid>

//         </Grid>
//       </Grid>

//       <Grid item xs={12} >
//         <Box className="tabs-container">
//           <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//               <Tab label="Active" {...a11yProps(0)} />
//               <Tab label="Inactive" {...a11yProps(1)} />
//             </Tabs>
//           </Box>
//           <CustomTabPanel value={value} index={0}>
//             <EmptyLegTable/>

//           </CustomTabPanel>
//           <CustomTabPanel value={value} index={1}>
//              Item two
//           </CustomTabPanel>
//         </Box>

//       </Grid>
//     </Grid>
//   )
// }

// export default EmptyLegManagement;

import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Tabs, Tab, Button } from "@mui/material";
// import OfferedFlightTable from './OfferedFlightTable';
import { serviceProvider } from "../../provider/serviceProvider";
import { CustomTabPanel, a11yProps } from "../Tab/CustomTabPanel";
import { useLocation, useNavigate } from "react-router-dom";
// import OfferedFlightTabs from './OfferedFlightTabs';
import ButtonComponent from "../common/ButtonComponent";
import EmptyLegTabs from "./EmptyLegTabs";
import { IFlight } from "../../interface/IFlight";

const EmptyLegManagement: React.FC = () => {
  const [value, setValue] = React.useState(0);
  // const [loader,setLoader]=useState(true);
  // const [emptyLegFlights,setEmptyLegFlights] =useState([]);
  const [pageState, setPageState] = React.useState({
    loader: false,
    data: [],
    totalPages: 0,
    page: 0,
    pageSize: 5,
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const index = searchParams.get("index");

  // Convert the 'index' value to a number if needed
  const indexNumber = parseInt(index + "", 10);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/empty-leg-management?index=${newValue}`);
  };

  const getEmptyLegFlights = (params: any) => {
    // const params= `flightType=EMPTYLEG`;
    // setLoader(true)

    setPageState((prev) => ({ ...prev, loader: true }));
    serviceProvider.flightService
      .get(params)
      .then((res: any) => {
        // serviceProvider.flightService.getAllFlights(params).then((res:any)=>{
        if (res.responseCode === 200) {
          // setPageState(prev=>({...prev,loader:false,data:res.data,totalPages:10}))
          const totalPages = res?.data?.length;

          setPageState((prev) => ({
            ...prev,
            loader: false,
            data: res?.data,
            totalPages: totalPages,
          }));
          // setEmptyLegFlights(res.data)
        }
        setPageState((prev) => ({ ...prev, loader: false }));
        // setLoader(false)
      })
      .catch((err) => {
        console.log({ err });
        setPageState((prev) => ({ ...prev, loader: false }));
      });
  };

  // const activeFlights= emptyLegFlights?.filter((flight:IFlight)=>flight.active === true);
  // const inActiveFlights= emptyLegFlights?.filter((flight:IFlight)=>flight.active === false);

  // useEffect(()=>{
  //   // getEmptyLegFlights()
  // },[])

  // useEffect(() => {

  //   if (value === 0) {
  //     // alert(value)
  //     // const query = `flightId=NA&flightType=EMPTYLEG&isActive=true`;
  //     // getEmptyLegFlights(query);
  //   } else if(value ===1) {
  //     // alert(value)
  //     // const query = `flightId=NA&flightType=EMPTYLEG&isActive=false`;
  //     // getEmptyLegFlights(query);
  //   }
  // }, [value]);

  useEffect(() => {
    console.log({indexNumber})
    if (indexNumber) {
      setValue(indexNumber);
      const query = `flightId=NA&flightType=EMPTYLEG&isActive=false`;
      getEmptyLegFlights(query);
    } else {
      setValue(0);
      const query = `flightId=NA&flightType=EMPTYLEG&isActive=true`;
      getEmptyLegFlights(query);
    }
  }, [indexNumber,value]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" component="h6">
              Empty Leg
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonComponent
              // variant="contained"
              // className="rounded-btn-fill"
              fullWidth={false}
              label="Add Empty Leg"
              onClick={() => navigate("/empty-leg-management/add")}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* <EmptyLegTabs activeFlights={activeFlights} inActiveFlights={inActiveFlights} loader={loader} value={value} handleChange={handleChange}/> */}
        <EmptyLegTabs
          pageState={pageState}
          setPageState={setPageState}
          value={value}
          handleChange={handleChange}
          getEmptyLegFlights={getEmptyLegFlights}
        />
      </Grid>
    </Grid>
  );
};

export default EmptyLegManagement;
