import React,{useEffect,useState} from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import S3 from "react-aws-s3-typescript";
import { IValidationResponse } from '../../interface/IValidation';
import { default as awsConf } from "../../configuration/config.json";
const awsConfig = awsConf.awsConfig;

interface EditorNewProps {
  onChange: (data: string) => void;
  editorLoaded: boolean;
  value: string;
  placeholder: string;
}

function MyCustomUploadAdapterPlugin(editor:any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader:any) => {
      return new MyUploadAdapter(loader)
}
  // editor.plugins.get("ImageResize").isEnabled=true;
}


class MyUploadAdapter{
  loader: any;
  constructor(props:any) {
      // CKEditor 5's FileLoader instance.
      this.loader = props;
      // URL where to send files.
  }

  // Starts the upload process.
  upload() {
      return new Promise((resolve, reject) => {
          this?.loader?.file?.then((file:any)=>{
              const ReactS3Client = new S3(awsConfig);
              ReactS3Client?.uploadFile(file, file?.name+Date?.now()).then((data:any) => {
                  if (data.status === 204) {
                      console.log(data);
                      resolve({default:data?.location})
                  } else {
                      reject("Currently Unable to upload image")
                  }
               });  
            //  file=res;
          })
         
      });
  }

  // Aborts the upload process.
  abort() {
      window?.location?.reload();
  } 

}

const EditorNew: React.FC<EditorNewProps> = ({ onChange, editorLoaded, value, placeholder}) => {
return (
    <div>
      {editorLoaded ? (
        <div className="editorFocus">
        <CKEditor 
          config={{ placeholder: placeholder, extraPlugins: [MyCustomUploadAdapterPlugin] }}
          editor={Editor}
          data={value}
          onChange={(event: any, editor: any) => {
            const data = editor.getData()
            onChange(data);
          }}
        />
      
        </div>
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default EditorNew;