import { IAuthentication } from "../interface/IAuthentication";
import { sessionStorageKey, apiConfig } from '../configuration/config.json';
import CryptoUtility from "../utility/cryptoUtility";
import { LocalStorageUtility } from "../utility/localStorageUtility";
class SessionUtility {
    public getSessionToken() {
        return CryptoUtility.decrypt(this.getAuthentication().token);
    }
    public getAuthentication() {
        return LocalStorageUtility.getStorageObj(sessionStorageKey.sessionKey) as IAuthentication;
    }
    public getHeader(): Headers | string[][] | Record<string, string> {
        // var token = this.getAuthentication().token;
        return { ...apiConfig.headers }
    }
    public getHeaderWithToken(authObj: any): Headers | string[][] | Record<string, string> {
        let header = {
            'Content-type': '',
            'Authorization': '',
            'AUTH_UID': ''
        };
        header['Content-type'] = apiConfig.headers["Content-type"];
        header['Authorization'] = authObj?.token;
        header['AUTH_UID'] = authObj?.session?.uId;
        return header;
    }
}
export const sessionUtility = new SessionUtility();
