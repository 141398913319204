// // import React, { useState,useContext } from 'react';
// // import { Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
// // import { MuiOtpInput } from 'mui-one-time-password-input';
// // import { makeStyles } from '@mui/styles';
// // import UserContext, { initAuthenticationTrue } from '../../context/UserContext';
// // import { useNavigate } from 'react-router-dom';
// // import { serviceProvider } from '../../provider/serviceProvider';
// // import {toast} from "react-toastify";
// // const useStyles = makeStyles({
// //   heading: {
// //     color: "#4A4A4A",
// //     fontFamily: "Microsoft Sans Serif",
// //     fontSize: "18px",
// //     fontStyle: "normal",
// //     fontWeight: "400",
// //   },
// //   text: {
// //     color: "#808080",
// //     fontFamily: "Microsoft Sans Serif",
// //     fontSize: "16px",
// //     fontStyle: "normal",
// //     fontWeight: "400",
// //   },



// // })
// // interface verifyOtpProps{
// //   VerifyOtpModal:boolean,
// //   handleCloseVerifyOtpModal():any,
// //   mobileNumber:string
// // }
// // const VerifyOtp:React.FC<verifyOtpProps> = ({ VerifyOtpModal, handleCloseVerifyOtpModal, mobileNumber }:any) => {

// //   const [otp, setOtp] = React.useState('');
// //   const [loader, setLoader] = useState(false);
// //   const context:any = useContext(UserContext);
// //   const navigate= useNavigate()



// //   // const handleVerifyOtp = () => {
// //   //   if (otp === "123456") {
// //   //     toast.success("user verified")
// //   //     handleCloseVerifyOtpModal()
// //   //   } else {
// //   //     toast.error("wrong otp")
// //   //   }
// //   // }
// //   // const handleVerifyOtp = () => {
// //   //   setLoader(true);

// //   //   const payload= {
// //   //     mobileNumber,
// //   //     otp
// //   //   }

// //   //   // isValidRequest().then(x => {
// //   //       serviceProvider.authenticationService.login(payload).then(response => {
// //   //        console.log({response}) 
// //   //           context.setAuthentication(initAuthenticationTrue).then(res=>{
// //   //             console.log({res})
// //   //               //props.updateShow();
// //   //               if(res){
// //   //               //   toast.success("User Verified.")
// //   //               alert("user verified")
// //   //                 handleCloseVerifyOtpModal()
// //   //                 navigate("/profile")
// //   //                 // window.location.href="/signup";
// //   //               }
// //   //               setLoader(false);
// //   //           });
// //   //       }).catch(err => {
// //   //           setLoader(false);
// //   //           console.log(err)
// //   //           // console.log(err.data.message);
// //   //           // toast.error(err.error.message || "Something Went Wrong!" )
// //   //           alert("something went wrong")
// //   //           //setAlert("not logged in", true, "success");
// //   //           // if(err.error.data.message=="User email is not verified. Please resend verification link"){
// //   //           //     setIsEmailVerified(false);
// //   //           //     // setAlert("User email is not verified. Please resend verification link", true, "error");
// //   //           // }
// //   //           // else if(err.error.data.message=="User is not registered yet. Please register"){
// //   //           //     // setAlert("User is not registered yet. Please register", true, "error");
// //   //           // }

// //   //           // const errorSpan = document.getElementById("errorDiv-password") as HTMLInputElement;
// //   //           // if (errorSpan !== undefined && errorSpan !== null && err.error.data.message!=='User email is not verified. Please resend verification link') {
// //   //           //     errorSpan.innerText = "Please check the credentials or verify the user";
// //   //           //     errorSpan.classList.remove("invalid-feedback");
// //   //           // }
// //   //       });
// //   // };

// //   // console.log({initAuthenticationTrue})   




// //   // login code commented
// //   // const handleVerifyOtp = () => {
// //   //   setLoader(true);

// //   //   const payload= {
// //   //     mobileNumber,
// //   //     otp
// //   //   }

// //   //   // isValidRequest().then(x => {
// //   //       serviceProvider.authenticationService.login(payload).then((response:any) => {
// //   //        console.log("loginresposne", response) 

// //   //        console.log({initAuthenticationTrue})

// //   //           context.setAuthentication(initAuthenticationTrue).then((res:any)=>{
// //   //             console.log("context res",res)
// //   //               //props.updateShow();
// //   //               if(res.responseCode === 200){
// //   //                 toast.success("User Verified.")
// //   //                 handleCloseVerifyOtpModal()
// //   //                 navigate("/profile")
// //   //                 // window.location.href="/signup";
// //   //               }else{
// //   //                 alert("err")
// //   //               }
// //   //               setLoader(false);
// //   //           });
// //   //       }).catch((err:any) => {
// //   //           setLoader(false);
// //   //           console.log(err)
// //   //           // console.log(err.data.message);
// //   //           toast.error(err.error.message || "Something Went Wrong!" )
// //   //           //setAlert("not logged in", true, "success");
// //   //           // if(err.error.data.message=="User email is not verified. Please resend verification link"){
// //   //           //     setIsEmailVerified(false);
// //   //           //     // setAlert("User email is not verified. Please resend verification link", true, "error");
// //   //           // }
// //   //           // else if(err.error.data.message=="User is not registered yet. Please register"){
// //   //           //     // setAlert("User is not registered yet. Please register", true, "error");
// //   //           // }

// //   //           // const errorSpan = document.getElementById("errorDiv-password") as HTMLInputElement;
// //   //           // if (errorSpan !== undefined && errorSpan !== null && err.error.data.message!=='User email is not verified. Please resend verification link') {
// //   //           //     errorSpan.innerText = "Please check the credentials or verify the user";
// //   //           //     errorSpan.classList.remove("invalid-feedback");
// //   //           // }
// //   //       });
// //   // };



// //   // login code for checking 

// //   const handleVerifyOtp = () => {
// //     setLoader(true);

// //     const payload= {
// //       mobileNumber,
// //       otp
// //     }

// //     // isValidRequest().then(x => {
// //         serviceProvider.authenticationService.login(payload).then((response:any) => {
// //         //  console.log({response}) 
// //          console.log({initAuthenticationTrue})
// //             context.setAuthentication(initAuthenticationTrue);
// //                   //alert("User Verified.")
// //                   handleCloseVerifyOtpModal()
// //                   navigate("/fleets-management")
// //                 setLoader(false);

// //         }).catch((err:any) => {
// //             setLoader(false);
// //             console.log(err)
// //             // console.log(err.data.message);
// //             //alert(err.error.message || "Something Went Wrong!" )
// //             //setAlert("not logged in", true, "success");
// //             // if(err.error.data.message=="User email is not verified. Please resend verification link"){
// //             //     setIsEmailVerified(false);
// //             //     // setAlert("User email is not verified. Please resend verification link", true, "error");
// //             // }
// //             // else if(err.error.data.message=="User is not registered yet. Please register"){
// //             //     // setAlert("User is not registered yet. Please register", true, "error");
// //             // }

// //             // const errorSpan = document.getElementById("errorDiv-password") as HTMLInputElement;
// //             // if (errorSpan !== undefined && errorSpan !== null && err.error.data.message!=='User email is not verified. Please resend verification link') {
// //             //     errorSpan.innerText = "Please check the credentials or verify the user";
// //             //     errorSpan.classList.remove("invalid-feedback");
// //             // }
// //         });
// //   };


// //   const classes = useStyles()

// //   console.log(otp)

// //   function showLastThreeDigits(mobileNumber:string) {

// //    if(mobileNumber.length===10){
// //     const lastThreeDigits = mobileNumber.substring(mobileNumber.length - 3);
// //     const hiddenPart = 'x'.repeat(mobileNumber.length - 3);
// //     const maskedNumber = hiddenPart + lastThreeDigits;

// //     return maskedNumber;
// //    }

// //   }

// //   // const maskedNumber = showLastThreeDigits(mobileNumber);
// //   // console.log(maskedNumber); // Output: "xxxxxxxx359"


// //   const handleChange = (newValue: string) => {
// //     setOtp(newValue)
// //   }

// //   return (
// //     <Modal
// //       open={VerifyOtpModal}
// //       // onClose={handleCloseVerifyOtpModal}
// //       aria-labelledby="modal-modal-title"
// //       aria-describedby="modal-modal-description"
// //     >
// //       <Box className="modal-container">
// //         <Grid item xs={12}  >
// //           {/* <Button sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"absolute",right:"-10px",top:"-10px",height:"20px",width:"20px",minWidth:0,padding:"20px",backgroundColor:"#F8F8F8",color:"#CA3657",borderRadius:"50%"}}>
// //                 <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseVerifyOtpModal} />
// //               </Button> */}

// //         </Grid>



// //         <Grid item xs={12} sx={{display:"flex",justifyContent:"center",alignItems:"center",p:"3em 2em"}}>
// //           <Grid container>
// //             <Grid item xs={12}>
// //               <Grid container rowSpacing={1}>

// //                 <Grid item xs={12}>
// //                   <Typography variant="h6" component="h6" textAlign="center" className={classes.heading}>
// //                     Enter verification OTP
// //                   </Typography>
// //                 </Grid>
// //                 <Grid item xs={12}>
// //                   <Typography variant="body1" component="p" textAlign="center">
// //                     An OTP has been sent to {showLastThreeDigits(mobileNumber)}
// //                   </Typography>
// //                 </Grid>

// //                 <Grid item xs={12}>
// //                   <MuiOtpInput value={otp} length={6} onChange={handleChange} />
// //                 </Grid>
// //                 <Grid item xs={12}>
// //                   <Typography variant="body2" component="span" textAlign="center">
// //                     Resend OTP
// //                   </Typography>
// //                 </Grid>
// //                 <Grid item xs={12} className="d-flex justify-content-center align-items-center">
// //                   <Button variant="contained" className="rounded-btn-fill"
// //                     disabled={loader || otp.length <= 5}
// //                     startIcon={loader ? <CircularProgress size={20} color="inherit" /> : null} onClick={handleVerifyOtp}>{loader ? "loading.." : "Verify"}</Button>
// //                 </Grid>
// //               </Grid>
// //             </Grid>
// //           </Grid>
// //         </Grid>
// //       </Box>
// //     </Modal>

// //   )
// // }
// // export default VerifyOtp;













// import React, { useState, useContext } from 'react';
// import { Box, Button, CircularProgress, Grid, Modal, Typography } from '@mui/material';
// // import { MuiOtpInput } from 'mui-one-time-password-input';
// import { makeStyles } from '@mui/styles';
// import CloseIcon from "@mui/icons-material/Close";
// import { toast } from "react-toastify";
// import { serviceProvider } from '../../provider/serviceProvider';
// import UserContext, { initAuthenticationTrue } from '../../context/UserContext';
// import { useNavigate } from 'react-router-dom';
// import ButtonComponent from '../common/ButtonComponent';
// import OTPInput from '../OTPInput';

// const useStyles = makeStyles({
//   heading: {
//     color: "#4A4A4A",
//     fontFamily: "Microsoft Sans Serif",
//     fontSize: "18px",
//     fontStyle: "normal",
//     fontWeight: "400",
//   },
//   text: {
//     color: "#808080",
//     fontFamily: "Microsoft Sans Serif",
//     fontSize: "16px",
//     fontStyle: "normal",
//     fontWeight: "400",
//   },



// })

// const VerifyOtp = ({ VerifyOtpModal, handleCloseVerifyOtpModal, mobileNumber }: any) => {

//   const [otp, setOtp] = React.useState('');
//   const [loader, setLoader] = useState(false);
//   const context = useContext(UserContext);
//   const navigate = useNavigate()



//   // const handleVerifyOtp = () => {
//   //   if (otp === "123456") {
//   //     toast.success("user verified")
//   //     handleCloseVerifyOtpModal()
//   //   } else {
//   //     toast.error("wrong otp")
//   //   }
//   // }
//   const handleVerifyOtp = () => {
    
//     setLoader(true);

//     const payload = {
//       mobileNumber,
//       otp
//     }

//     // isValidRequest().then(x => {
//     serviceProvider.authenticationService.login(payload).then(response => {
//       // debugger;
//       console.log({ response })
//       context.setAuthentication(initAuthenticationTrue).then((res:any) => {
//         console.log({ res })
//         //props.updateShow();
//         if (res) {
//           // debugger;
//           toast.success("User Verified.")
//           handleCloseVerifyOtpModal()
//           // navigate("/profile")
//           // window.location.href="/signup";
//         }else{
//           toast.error("Something Went Wrong.")
//         }
//         setLoader(false);
//       });
//     }).catch(err => {
//       setLoader(false);
//       console.log(err)
//       toast.error(err.error.message || "Something Went Wrong!")
//     });
//   };

//   const classes = useStyles()

//   console.log(otp)

//   function showLastThreeDigits(mobileNumber: string) {

//     if (mobileNumber.length === 10) {
//       const lastThreeDigits = mobileNumber.substring(mobileNumber.length - 3);
//       const hiddenPart = 'x'.repeat(mobileNumber.length - 3);
//       const maskedNumber = hiddenPart + lastThreeDigits;

//       return maskedNumber;
//     }

//   }

//   // const maskedNumber = showLastThreeDigits(mobileNumber);
//   // console.log(maskedNumber); // Output: "xxxxxxxx359"


//   const handleChange = (newValue: any) => {
//     setOtp(newValue)
//   }

//   return (
//     <Modal
//       open={VerifyOtpModal}
//       // onClose={handleCloseVerifyOtpModal}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <Box className="modal-container">
//         <Grid item xs={12}  >
//           {/* <Button sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"absolute",right:"-10px",top:"-10px",height:"20px",width:"20px",minWidth:0,padding:"20px",backgroundColor:"#F8F8F8",color:"#CA3657",borderRadius:"50%"}}>
//                 <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseVerifyOtpModal} />
//               </Button> */}
//         </Grid>
//         <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: "3em 0" }}>
//           <Grid container sx={{ width: "450px" }}>
//             <Grid item xs={12}>
//               <Grid container rowSpacing={1}>

//                 <Grid item xs={12}>
//                   <Typography variant="h6" component="h6" textAlign="center" className={classes.heading}>
//                     Enter verification OTP
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Typography variant="body1" component="p" textAlign="center">
//                     An OTP has been sent to {showLastThreeDigits(mobileNumber)}
//                   </Typography>
//                 </Grid>

//                 <Grid item xs={12}>
//                   {/* <MuiOtpInput value={otp} length={6} onChange={handleChange} /> */}
//                   <OTPInput
//                     autoFocus
//                     length={6}
//                     className="otpContainer"
//                     inputClassName="otpInput"
//                     onChangeOTP={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Typography variant="body2" component="span" textAlign="center">
//                     Resend OTP
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} className="d-flex justify-content-center align-items-center">
//                   <ButtonComponent loader={loader} disabled={loader || otp.length <= 5} onClick={handleVerifyOtp} label="Verify" />
//                   {/* <Button variant="contained" className="btn-layout bg-secondary text-white disabled-btn"
//                     disabled={loader || otp.length <= 5}
//                     startIcon={loader ? <CircularProgress size={20} color="inherit" /> : null} onClick={handleVerifyOtp}>{loader ? "loading.." : "Verify"}</Button> */}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Box>
//     </Modal>

//   )
// }
// export default VerifyOtp;



import React, { useState, useContext } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toast } from "react-toastify";
import { serviceProvider } from '../../provider/serviceProvider';
import UserContext, { initAuthenticationTrue } from '../../context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonComponent from '../common/ButtonComponent';
import OTPInput from '../OTPInput';
import { sessionUtility } from '../../utility/sessionUtility';


const useStyles = makeStyles({
  heading: {
    color: "#4A4A4A",
    fontFamily: "Microsoft Sans Serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
  },
  text: {
    color: "#808080",
    fontFamily: "Microsoft Sans Serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
  }
})

const VerifyOtp = ({ VerifyOtpModal, handleCloseVerifyOtpModal, mobileNumber }: any) => {

  const [otp, setOtp] = React.useState('');
  const [loader, setLoader] = useState(false);
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();


  const handleResendOtp = (mobileNo: string) => {
    serviceProvider.userService
        .requestOtp(mobileNo)
        .then((res: any) => {
            toast.success(res.message);
        })
        .catch((err) => {
            toast.error(err.data?.message || 'Something Went Wrong!');
        });
};

const handleVerifyOtp = () => {
  setLoader(true);
  const payload = {mobileNumber,otp}

  // isValidRequest().then(x => {
  serviceProvider.authenticationService.login(payload).then((x:any) => {

    context.setAuthentication(initAuthenticationTrue);
      console.log({x});
      
      if (x) {
        
        handleCloseVerifyOtpModal();
        //handleCloseModal();
        console.log({x})
        if (x?.session?.role[0]?.authority &&
          ["ROLE_ADMIN:EDIT", "ROLE_ADMIN:VIEW", "ROLE_OPERATOR:EDIT", "ROLE_OPERATOR:VIEW"].includes(x.session.role[0].authority)) {
          toast.success("User Verified.");
          window.location.href = "/";
          // rest of your code
      } else {
          toast.error("You are not authorized.");
      }
      //   if(x?.session?.role[0]?.authority === ("ROLE_ADMIN:EDIT" || "ROLE_ADMIN:VIEW" || "ROLE_OPERATOR:EDIT" || "ROLE_OPERATOR:VIEW"     )){
      //     toast.success("User Verified.")
      //     window.location.href="/";
      //     // const authorityJson = JSON.parse(JSON.stringify(x?.session?.role[0]));

      //     // navigate("/")
      //   // const isOperator:any = authorityJson?.authority === "ROLE_OPERATOR:EDIT" ? true :false;
      //   // if(isOperator){
      //   //   navigate("/operator-my-aircrafts")
         
      //   // }else{
      //   //   navigate("/users-management")
      //   // }
      // }else{
      //   toast.error("You are not authorized.");
      // }

      }else{
        toast.error(x?.message ||"Something Went Wrong.")
      }
      setLoader(false);
    
  }).catch(err => {
    console.log(err)
    setLoader(false);
    toast.error(err?.error?.data?.message || "Something Went Wrong!")
  });
};

  const classes = useStyles();

  function showLastThreeDigits(mobileNumber: string) {

    if (mobileNumber.length === 10) {
      const lastThreeDigits = mobileNumber.substring(mobileNumber.length - 3);
      const hiddenPart = 'x'.repeat(mobileNumber.length - 3);
      const maskedNumber = hiddenPart + lastThreeDigits;
      return maskedNumber;
    }
  }

  const handleChange = (newValue: any) => {
    setOtp(newValue)
  }

  return (
    <Modal open={VerifyOtpModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="modal-container verify-otp-modal">
        <Grid item xs={12}  >
        </Grid>
        <Grid item xs={12} className="verify-otp-container">
          <Grid container>
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6" textAlign="center" className="heading">
                    Enter verification OTP
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" component="p" textAlign="center" className="sub-heading">
                    An OTP has been sent to {showLastThreeDigits(mobileNumber)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign:"center"}}>
                  <OTPInput
                    autoFocus
                    length={6}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="span" textAlign="center" className='sub-heading resend-otp' sx={{cursor:"pointer"}} onClick={()=>handleResendOtp(mobileNumber)}>
                    Resend OTP
                  </Typography>
                </Grid>
                <Grid item xs={12} className="d-flex justify-content-center align-items-center">
                  <ButtonComponent loader={loader} disabled={loader || otp.length <= 5} onClick={handleVerifyOtp} label="Verify" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>

  )
}
export default VerifyOtp;

