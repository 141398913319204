import { Grid } from "@mui/material";
import React from "react";
import Loader from "../loader/Loader";
import BookingCard from "./BookingCard";
import NoResult from "../noResult/NoResult";
import { IBooking } from "../../interface/IBooking";
import { useLocation } from "react-router-dom";

const CompletedBooking = ({ loader, bookings }: any) => {



  return (
   <Grid container spacing={4}>
              {
                loader ?
                  new Array(3).fill(0).map((_) => {
                    return <Grid item xs={12} md={6}>
                      <Loader height='300px' width='100%' />
                    </Grid>
                  }) : bookings.length >= 1 && loader === false ? bookings?.map((booking: IBooking) => {
                    return <Grid item xs={12} md={6}>
                      <BookingCard bookingDetails={booking}  />
                    </Grid>
                  }) :
                    <Grid item xs={12}>
                      <NoResult title={"Completed Bookings"} />
                    </Grid>
              }
            </Grid> 
  );
};

export default CompletedBooking;
