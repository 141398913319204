import React, { useState, useEffect } from 'react';
import { AlertColor, Box, Typography } from '@mui/material';
import ButtonComponent from './ButtonComponent';
// import { AlertBar } from "../commonComponents/alert";
import networkError from "../../assets/images/network-error.svg";
const NetworkError = (props : any) => {
    // state variable holds the state of the internet connection
    // const [isOnline, setOnline] = useState(true);
    // const [alertColor, setAlertColor] = useState<AlertColor>("success");
    // const [alertOpen, setAlertOpen] = useState(false);
    // const [alertText, setAlertText] = useState("");


    const [isOnline, setOnline] = useState(true);
    const [alertColor, setAlertColor] = useState("success");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    useEffect(() => {
        const handleOnline = () => {
            setOnline(true);
        };

        const handleOffline = () => {
            // setAlert("warning", "No Internet Connection. Please try again later.");
            setOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (!isOnline) {
        return (
            <div style={{height:"100vh"}}>
            <Box className="network-error-container">
              <Typography variant="h3" component='h3'>
              We ran into a problem
              </Typography>
              <Typography variant="body1" component='p'>
              Our team is looking into it now. If refreshing the page doesn’t work, check our <a href="/">Home Page</a> for updates.
              </Typography>
           <Box className="center-content">
           <Box className="img">
                <img src={networkError} alt="Network Error" />
             </Box>
                  <ButtonComponent fullWidth={false} label="Try Again" onClick={()=>window.location.href="/"}/>
           </Box>
              
          </Box>
        </div>
        );
    }

    return(
        <>
            {props.children}
        
            {/* < AlertBar isVisible={alertOpen} text={alertText} color={alertColor} /> */}
        </>     
    );
    // if user is online, return the child component else return a custom component

}

export default NetworkError;