// import { Button, CircularProgress, Grid, TextField, Typography, Box } from '@mui/material'
// import React, { useContext, useState } from 'react'
// import { makeStyles } from '@mui/styles'
// import { ValidationUtiltiy } from '../../utility/ValidationUtility';
// import { ValidationType } from '../../enum/validationType';
// import { IValidationResponse } from '../../interface/IValidation';
// import { serviceProvider } from '../../provider/serviceProvider';
// import UserContext from "../../context/UserContext";
// import VerifyOtp from './VerifyOtp';
// import BmjLogo from "../../assets/logo/bmjLogo.svg";
// import { toast } from 'react-toastify';

// const useStyles = makeStyles({
//   customTextField: {
//     "& input::placeholder": {
//       fontSize: "0.8rem"
//     }
//   }
// })

// const initialUserInput = {
//   mobileNumber: ""
// }


// const Login:React.FC = () => {
//   const [userInput, setUserInput] = useState(initialUserInput)
//   const [loader, setLoader] = useState(false)
//   const [mobileNumber, setMobileNumber] = useState<string>('');
//   const [isEmailVerified, setIsEmailVerified] = useState(true);
//   const [VerifyOtpModal, setVerifyOtpModal] = React.useState<boolean>(false);
//   const handleOpenVerifyOtpModal = () => setVerifyOtpModal(true);
//   const handleCloseVerifyOtpModal = () => setVerifyOtpModal(false);
//   const classes = useStyles();
//   const context = useContext(UserContext);


//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setUserInput({ ...userInput, [name]: value });

//     // setMobileNumber(userInput.mobileNumber)
//     if (name === 'mobileNumber') {
//         setMobileNumber(value);
//         validateFieldsOnInput(name);
//     }
//     validateFieldsOnInput(name);
// };

//     const validateFieldsOnInput = (key: string) => {
//     switch (key) {
//       case 'mobileNumber': validateMobile();
//         break;
//     }
//   }


//   const requestOtp = (mobileNo: string) => {
//     serviceProvider.userService
//         .requestOtp(mobileNo)
//         .then((res: any) => {
//             // toast.success(res.message);
//             //alert(res.message)
//             // setOpen(false);
//             setTimeout(() => {
//                 handleOpenVerifyOtpModal();
//             }, 1000);
//             console.log(res);
//         })
//         .catch((err) => {
//             toast.error(err.data.message || 'Something Went Wrong!');
//         });
// }

//   return (
//     <>
//     <VerifyOtp VerifyOtpModal={VerifyOtpModal} handleCloseVerifyOtpModal={handleCloseVerifyOtpModal} mobileNumber={mobileNumber} />
   
//         <Box className="login-container">
//         <Grid container className="form-container">
//           <Grid item xs={12} className="logo">
//             <img src={BmjLogo} alt="bmj-logo" width="100px" height="auto" />
//           </Grid>
//           <Grid item xs={12}>
//             <Typography variant="h6" component="h6" className="heading">
//               Welcome
//             </Typography>
//           </Grid>
//           <Grid item xs={12}>
//           <Grid container className="form" spacing={3}>
//                                 <Grid item xs={12}>
//                                     <TextField id="signup-contact"  classes={{ root: classes.customTextField }} type="tel" size='small' fullWidth variant="outlined" label="Mobile Number" placeholder='Mobile No.' name="mobileNumber" value={userInput.mobileNumber} inputProps={{ maxLength: 10 }} InputProps={{ disableUnderline: true, sx: { px: "10px" } }} onChange={handleInputChange} />
//                                     <span className="modalError" id='errorDiv-contact'></span>
//                                 </Grid>
//                                 <Grid item xs={12} className="btn">
//                                     <Button variant="contained" fullWidth className="rounded-btn-fill" disabled={!userInput.mobileNumber || loader} startIcon={loader ? <CircularProgress size={20} color="inherit" /> : null} onClick={() => requestOtp(userInput.mobileNumber)}>{loader ? "loading.." : "Login"}</Button>
//                                 </Grid>
//                             </Grid>
//             {/* <Grid container spacing={2}>
//               <Grid item xs={12} mt="30px">
//                 <Typography variant="h4" component="h4" textAlign="center" className="sign-up-using-heading">
//                   Or login using
//                 </Typography>
//               </Grid>
//               <Grid item xs={12} className='d-flex justify-content-center align-items-center gap-30-px'>
//                 <img src={googleIcon} alt="google icon" height="45px" width="45px" />
//                 <img src={facebookIcon} alt="facebook icon" height="40px" width="40px" />
//               </Grid>
//             </Grid> */}
//           </Grid>
//         </Grid>
//         </Box>
      
//     </>
//   )
// }


// const isValidRequest = (): Promise<boolean> => {
//   let responseArray: IValidationResponse[] = [];
//   responseArray.push(validateMobile());
//   let haveErrors = responseArray.filter((x) => {
//     return !x.isValid;
//   }).length <= 0;
//   return new Promise((resolve, reject) => {
//     if (haveErrors) {
//       resolve(true);
//     } else {
//       reject(false);
//     }
//   });
// }

// const validateMobile = () => {
//   return ValidationUtiltiy.validateElement("login-contact", ValidationType.Mobile, true, "errorDiv-contact", "Contact Info")
// }

// export default Login




import { Button, CircularProgress, Grid, TextField, Typography, Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { ValidationUtiltiy } from '../../utility/ValidationUtility';
import { ValidationType } from '../../enum/validationType';
import { IValidationResponse } from '../../interface/IValidation';
import { serviceProvider } from '../../provider/serviceProvider';
import UserContext from "../../context/UserContext";
import VerifyOtp from './VerifyOtp';
import BmjLogo from "../../assets/logo/bmjLogo.svg";
import { toast } from 'react-toastify';
import ButtonComponent from '../common/ButtonComponent';

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "0.8rem"
    }
  }
})

const initialUserInput = {
  mobileNumber: ""
}


const Login:React.FC = () => {
  const [userInput, setUserInput] = useState(initialUserInput)
  const [loader, setLoader] = useState(false)
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [VerifyOtpModal, setVerifyOtpModal] = React.useState<boolean>(false);
  const handleOpenVerifyOtpModal = () => setVerifyOtpModal(true);
  const handleCloseVerifyOtpModal = () => setVerifyOtpModal(false);
  const classes = useStyles();
  const context = useContext(UserContext);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });

    // setMobileNumber(userInput.mobileNumber)
    // if (name === 'mobileNumber') {
    //     setMobileNumber(value);
    //     validateFieldsOnInput(name);
    // }
    // validateFieldsOnInput(name);
};

    const validateFieldsOnInput = (key: string) => {
    switch (key) {
      case 'mobileNumber': validateMobile();
        break;
    }
  }


  const requestOtp = (mobileNo: string) => {
    console.log(userInput.mobileNumber)
     isValidRequest().then(() => {
        serviceProvider.userService
            .requestOtp(mobileNo)
            .then((res: any) => {
                if(res.responseCode === 200){
                  toast.success(res.message);
                setTimeout(() => {
                    handleOpenVerifyOtpModal();
                }, 1000);
                }else{
                  toast.error(res?.message || "Something Went Wrong!!")
                }
            })
            .catch((err) => {
                toast.error(err?.data?.message || 'Something Went Wrong!');
            });
    }).catch(() => {
        toast.error('Please provide proper data!');
        setLoader(false);
    });
};


//   const requestOtp = (mobileNo: string) => {
//     serviceProvider.userService
//         .requestOtp(mobileNo)
//         .then((res: any) => {
//             toast.success(res.message);
//             // setOpen(false);
//             setTimeout(() => {
//                 handleOpenVerifyOtpModal();
//             }, 1000);
//             console.log(res);
//         })
//         .catch((err) => {
//             toast.error(err.data?.message || 'Something Went Wrong!');
//         });
// };

  return (
    <>
    <VerifyOtp VerifyOtpModal={VerifyOtpModal} handleCloseVerifyOtpModal={handleCloseVerifyOtpModal} mobileNumber={userInput.mobileNumber} />
   
        <Box className="login-container">
        <Grid container className="form-container">
          <Grid item xs={12} className="logo">
            <img src={BmjLogo} alt="bmj-logo" width="100px" height="auto" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6" className="heading">
              Welcome
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Grid container className="form" spacing={3}>
                                <Grid item xs={12}>
                                    <TextField id="signup-contact"  classes={{ root: classes.customTextField }} type="tel" size='small' fullWidth variant="outlined" label="Mobile Number" placeholder='Mobile No.' name="mobileNumber" value={userInput.mobileNumber} inputProps={{ maxLength: 10 }} InputProps={{ disableUnderline: true, sx: { px: "10px" } }} onChange={handleInputChange} />
                                    <span className="modalError" id='errorDiv-contact'></span>
                                </Grid>
                                <Grid item xs={12} className="btn">
                                <ButtonComponent loader={loader} disabled={!userInput.mobileNumber || loader} onClick={() => requestOtp(userInput.mobileNumber)} label="Login" />
                                    {/* <Button variant="contained" fullWidth className="rounded-btn-fill" disabled={!userInput.mobileNumber || loader} startIcon={loader ? <CircularProgress size={20} color="inherit" /> : null} onClick={() => requestOtp(userInput.mobileNumber)}>{loader ? "loading.." : "Login"}</Button> */}
                                </Grid>
                            </Grid>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} mt="30px">
                <Typography variant="h4" component="h4" textAlign="center" className="sign-up-using-heading">
                  Or login using
                </Typography>
              </Grid>
              <Grid item xs={12} className='d-flex justify-content-center align-items-center gap-30-px'>
                <img src={googleIcon} alt="google icon" height="45px" width="45px" />
                <img src={facebookIcon} alt="facebook icon" height="40px" width="40px" />
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        </Box>
      
    </>
  )
}


const isValidRequest = (): Promise<boolean> => {
  let responseArray: IValidationResponse[] = [];
  responseArray.push(validateMobile());
  let haveErrors = responseArray.filter((x) => {
    return !x.isValid;
  }).length <= 0;
  return new Promise((resolve, reject) => {
    if (haveErrors) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

const validateMobile = () => {
  return ValidationUtiltiy.validateElement("signup-contact", ValidationType.Mobile, true, "errorDiv-contact", "Contact Info")
}

export default Login