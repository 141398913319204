// import React from 'react'

// const ManageOfferedFlight = () => {
//   return (
//     <div>ManageOfferedFlight</div>
//   )
// }

// export default ManageOfferedFlight



import React,{useState} from "react"

import { Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import userEditIcon from "../../assets/icons/user-edit.svg"
import userSettingIcon from "../../assets/icons/user-setting.svg"
import flightDeactivateIcon from "../../assets/icons/deactivate-flight.svg";
import flightActivateIcon from "../../assets/icons/activate-flight.svg";
import CommonModal from "../modal/CommonModal";
// import DeactivateUserModalBody from "./DeactivateUser";
// import EditUserModalBody from "./EditUser";
import { useNavigate } from "react-router-dom";
// import DeactivateFlight from "./DeactivateFlight";

// import ActivateUserModalBody from "./ActivateUser";
import editIcon from "../../assets/icons/edit-icon.svg";
import ActivateOfferedFlight from "./ActivateOfferedFlight";
import DeactivateOfferedFlight from "./DeactivateOfferedFlight";

export const ManageOfferedFlight = ({ flightId ,isActive,indexNumber,getOfferedFlights}:any) => {
    const [openDeactivateFlight, setOpenDeactivateFlight] = useState(false);
    const [openActivateFlight, setOpenActivateFlight] = useState(false);
    const handleOpenDeactivateFlightModal = () => setOpenDeactivateFlight(true)
    const handleCloseDeactivateFlightModal = () => setOpenDeactivateFlight(false);
    const [openEditFlight, setOpenEditFlight] = useState(false);
    const navigate= useNavigate()
   
    const handleOpenEditFlightModal = () => setOpenEditFlight(true)
    const handleCloseEditFlightModal = () => setOpenEditFlight(false);


    const handleOpenActivateFlightModal=()=> setOpenActivateFlight(true)
    const handleCloseActivateFlightModal=()=> setOpenActivateFlight(false)
    return (
       <>
        <CommonModal open={openDeactivateFlight} handleCloseModal={handleCloseDeactivateFlightModal} modalBody={<DeactivateOfferedFlight handleCloseDeactivateFlightModal={handleCloseDeactivateFlightModal} flightId={flightId} indexNumber={indexNumber} getOfferedFlights={getOfferedFlights}/>} />
        <CommonModal open={openActivateFlight} handleCloseModal={handleCloseActivateFlightModal} modalBody={<ActivateOfferedFlight handleCloseActivateFlightModal={handleCloseActivateFlightModal} flightId={flightId} indexNumber={indexNumber} getOfferedFlights={getOfferedFlights}/>} />
        {/* <CommonModal open={openEditUser} handleCloseModal={handleCloseEditUserModal} modalBody={<EditUserModalBody handleCloseEditUserModal={handleCloseEditUserModal} userId={userId}/>} /> */}
      <Grid container columnSpacing={2}>
        <Grid item xs={4} spacing={2}>
          <Tooltip title="Edit Flight">
            <Button onClick={()=>window.location.href=`/offered-flight-management/edit/${flightId}?index=${isActive?0:1}`}>
              <img src={editIcon} alt="edit icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title={`${isActive ? "Deactivate Flight":"Activate Flight"}`}>
            <Button onClick={isActive?handleOpenDeactivateFlightModal:handleOpenActivateFlightModal}>
              <img src={isActive ?flightDeactivateIcon : flightActivateIcon} alt="icon" height="20px" width="20px" />
            </Button>
          </Tooltip>
        </Grid>
    
      </Grid>
       </>
    );
  };
  