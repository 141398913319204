import React from 'react'
import { Tabs, Tab, Box } from '@mui/material';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import OfferedFlightTable from './OfferedFlightTable';
const OfferedFlightTabs = ({pageState,setPageState,value,handleChange,getOfferedFlights}:any) => {
  return (
    <Box className="tabs-container">
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Active" {...a11yProps(0)} />
        <Tab label="Inactive" {...a11yProps(1)} />
      </Tabs>
    </Box>
    <CustomTabPanel value={value} index={0}>
      <OfferedFlightTable pageState={pageState} setPageState={setPageState} flights={pageState.data} indexNumber={value} getOfferedFlights={getOfferedFlights}/>
    </CustomTabPanel>
    <CustomTabPanel value={value} index={1}>
    <OfferedFlightTable pageState={pageState} setPageState={setPageState} flights={pageState.data} indexNumber={value} getOfferedFlights={getOfferedFlights}/>
    </CustomTabPanel>
  </Box>
  )
}

export default OfferedFlightTabs