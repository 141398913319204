import React from 'react'
import { Tabs, Tab, Box } from '@mui/material';
import { CustomTabPanel, a11yProps } from '../Tab/CustomTabPanel';
import EmptyLegTable from './EmptyLegTable';
// import OfferedFlightTable from './OfferedFlightTable';
const EmptyLegTabs = ({pageState,setPageState,value,handleChange,getEmptyLegFlights}:any) => {
  return (
    <Box className="tabs-container">
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Active" {...a11yProps(0)} />
        <Tab label="Inactive" {...a11yProps(1)} />
      </Tabs>
    </Box>
    <CustomTabPanel value={value} index={0}>
      {/* <EmptyLegTable flights={activeFlights} loader={loader}/> */}
      <EmptyLegTable pageState={pageState} setPageState={setPageState} flights={pageState.data} indexNumber={value} getEmptyLegFlights={getEmptyLegFlights}/>
    </CustomTabPanel>
    <CustomTabPanel value={value} index={1}>
    {/* <EmptyLegTable flights={inActiveFlights} loader={loader}/> */}
    <EmptyLegTable pageState={pageState} setPageState={setPageState} flights={pageState.data} indexNumber={value} getEmptyLegFlights={getEmptyLegFlights}/>
    </CustomTabPanel>
  </Box>
  )
}

export default EmptyLegTabs;