import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DateTimePickerComponent from '../common/DateTimePickerComponent'
import AutoCompleteComponent from '../common/AutoCompleteComponent'
import ButtonComponent from '../common/ButtonComponent'
import { serviceProvider } from '../../provider/serviceProvider'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { IAirport } from '../../interface/IAirport'


const initialPayload = {
  active: true,
  aircraftId: "",
  availabilityEndDt: "",
  availabilityId: "",
  availabilityStartDt: "",
  operatorUid: "",
  startAirportId: ""
}

const EditAvailability = ({ operatorUid, aircraftId, availabilityId,aircraft,isOperator,handleCloseModal }: any) => {

  // console.log("aircraftttttttttttttt",aircraft)
  const [loader, setLoader] = useState(false);
  const [airports, setAirports] = useState<any>([]);
  const [userInput, setUserInput] = useState(initialPayload)

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleStartDateChange = (newDate: any) => {
    setStartDate(newDate);
    // setTouchedFields({ ...touchedFields, startDate: true });
  }
  const handleEndDateChange = (newDate: any) => {
    setEndDate(newDate);
    // setTouchedFields({ ...touchedFields, endDate: true });
  }

  const handleInputChange = (property: any, newValue: any) => {

    console.log({newValue});
    
    // if (property === "startAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else if (property === "endAirportId") setSearchInput({ ...searchInput, [property]: newValue.airportId })
    // else setSearchInput({ ...searchInput, [property]: newValue })

    if (property === "airportId") {
      setUserInput({ ...userInput, startAirportId: newValue?.airportId })
      // getAllFleetAircrafts(newValue?.fleetId)
    }
    // validateFieldsOnInput(property)
  };

  const handleEditAvailibility = (operatorUid: any, aircraftId: any, availabilityId: any) => {
    const formattedStartDate = startDate ? dayjs(new Date(startDate).toISOString()).format('YYYY-MM-DDTHH:mm:ss') : '';
    const formattedEndDate = endDate ? dayjs(new Date(endDate).toISOString()).format('YYYY-MM-DDTHH:mm:ss') : '';
   
    // aircraftId: "", 
    // operatorUid: "",
  
  
    var payload = { ...userInput, availabilityStartDt: formattedStartDate+"", availabilityEndDt: formattedEndDate+"", operatorUid, aircraftId };

    if(availabilityId != undefined && availabilityId != null && availabilityId != ""){
      payload = { ...payload, availabilityId }
    }

    setLoader(true);
    serviceProvider.aircraftAvailabilityService.post(payload).then((res) => {
      setLoader(false)
      console.log(res)
      if(res.responseCode===200){
        toast.success(res.message);
        handleCloseModal()
        // setTimeout(()=>{
        //   window.location.reload()
          
        // },100)

       if(isOperator){
        setTimeout(() => {
          // window.location.reload()
          
          // window.location.href=`/operator-my-aircrafts?index=${1}`
          window.location.href=`/?index=${1}`
        }, 100)
       }else{
        setTimeout(() => {
          // window.location.reload()
          
          window.location.href=`/operator-management?index=${1}`
        }, 100)
       }
       
      }else{
        toast.error(res?.message || "Something Went Wrong!!")
      }
      
    }).catch((err) => {
      console.log({ err });
      setLoader(false)
      toast.error(err?.data?.message || "Something Went Wrong!!")

    })
  }


  const getAllAirports = () => {
    // setLoader(true);
    serviceProvider.airportService.getAllAirports().then((res) => {
      // console.log({res})
      if (res.responseCode === 200) {
        setAirports(res.data)
      } else {
        toast.error(res?.messsage || "Something Went Wrong!!")
      }
      // setLoader(false)
    }).catch((err) => {
      console.log({ err });
      // setLoader(false)

    })
  }

  useEffect(() => {
    getAllAirports()
  }, []);


  useEffect(()=>{
setStartDate(dayjs(aircraft?.availabilityStartDt ))
setEndDate(dayjs(aircraft?.availabilityEndDt ))
setUserInput({...userInput,startAirportId:aircraft?.airportDetails?.airportId})
  },[])

  return (
    <Grid container rowSpacing={3} className='edit-availability-modal'>
      <Grid item xs={12}>
        <Typography variant='h6' component='h6'>Edit availability</Typography>
      </Grid>
      <Grid item xs={12} >
        <DateTimePickerComponent className="date-input" placeholder="Start Date and Time" disablePast={true} value={startDate} onChange={handleStartDateChange} />
      </Grid>
      <Grid item xs={12} >
        <DateTimePickerComponent className="date-input" placeholder="End Date and Time" disablePast={true} value={endDate} onChange={handleEndDateChange} />
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <AutoCompleteComponent
            id="select-aiportId"
            options={airports}
            className='select-box'
            // defaultValue={searchResults?.startAirport}
            // getOptionLabel={(option: any) => `${option.name}`}
            getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode}) - ${option.city}`}
            // getOptionLabel={(option: IAirport) => `${option.name} (${option.icaoCode})`}
            // renderOption={(props: any, option: any) => (
            //     <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props}>
            //         <Typography textAlign="left">{option.city}</Typography>
            //         <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
            //     </Box>
            // )}
            renderOption={(props: any, option: any) => (
              <Box sx={{ width: "100%", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} {...props} style={{display:"block"}}>
                <Typography textAlign="left" mb="8px">{option.city}</Typography>
                <Typography textAlign="left">{option.name} ({option.icaoCode})</Typography>
              </Box>
            )}
            value={aircraft?.airportDetails}
            defaultValue={aircraft?.airportDetails}
            onChange={(e: any, newValue: any) => handleInputChange("airportId", newValue)}
            placeholder="Select Airport"
          />
          <span className="modalError" id='errorDiv-select-aiportId'></span>
          {/* <img src={departureIcon} alt="departure-icon" height="25px" width="25px" /> */}
          {/* </AutoCompleteComponent> */}
        </Grid>
        <Typography variant='body1' component="p">Airport is not listed? Please <span>contact us</span></Typography>
      </Grid>

      <Grid item xs={12} className='btn-container' >
        <ButtonComponent label="Save" loader={loader} disabled={loader} fullWidth={false}  onClick={() => handleEditAvailibility(operatorUid, aircraftId, availabilityId)} />
      </Grid>

    </Grid>
  )
}

export default EditAvailability