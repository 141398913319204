export enum EndPoints {
    userLogin = "",
    adminLogin = "",
}
export enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    DELETE = "DELETE",
    TRACE = "TRACE",
    PATCH = "PATCH",
    CONNECT = "CONNECT",
    OPTIONS = "OPTIONS"
}