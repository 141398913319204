import { HttpService } from '../commonService/httpService';
import { HttpMethod } from '../../enum/httpMethod';
import { IBlogService, IApiIntegration } from '../../interface/IService';
import { IUser } from '../../interface/IUser';
// import { ICompleteUserDetails, IUpdateUserLink, IUser } from '../../interface/IUser';
import { sessionUtility } from '../../utility/sessionUtility';
export class BlogService extends HttpService implements IBlogService, IApiIntegration {
    urls: { get: string; post: string; delete: string; put: string; patch: string; };
    userDetails = sessionUtility.getAuthentication();
    constructor() {
        super();
        this.urls = {
            get: "blogs",
            post: "blogs",
            put: "blogs",
            delete: "blogs",
            patch: "blogs",
        }
        if (!this.userDetails) {
            this.userDetails = sessionUtility.getAuthentication();
        }
    }
    header: Headers | string[][] | Record<string, string>=sessionUtility.getHeaderWithToken(this.userDetails);

    getList(param:any): any {
        // const url=`${this.urls.get}?uId=${param}`;
        const url = this.setUrlParam(param, this.urls.get);
        // subscription?subscriptionId=6&frequency=MONTHLY&subscriptionStatus=PENDING&salesUId&customerUId
        return this.fetch(HttpMethod.Get, url, this.header)  as any;
    }

    get(param: any): Promise<any> {
        const url = this.setUrlParam(param, this.urls.get);
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    post<E>(entity: E): Promise<any> {
        return this.postQuery(this.urls.post, this.header, entity) as Promise<any>;
    }
    put<E>(param:any,entity: E): Promise<any> {
        const url = this.setUrlParam(param, this.urls.put)
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    patch(param: string): Promise<any> {
        const url = this.setUrlParam(param, this.urls.patch);
        return this.patchQuery(url, this.header) as Promise<any>;
    }
    delete(param: string): Promise<any> {
        const url = this.setUrlParam(param, this.urls.delete);
        return this.deleteQuery(url, this.header) as Promise<any>;
    }
    getComments(param: any): Promise<any> {
        const url = this.setUrlParam(param, "blogs/comments");
        return this.fetch(HttpMethod.Get, url, this.header) as Promise<any>;
    }
    addComment(entity:any): Promise<any>{
        const url = this.setUrlParam("comments", this.urls.post);
        return this.postQuery(url, this.header, entity) as Promise<any>;
    }
    addReaction(entity:any): Promise<any>{
        const url = this.setUrlParam("reactions", this.urls.post);
        return this.postQuery(url, this.header, entity) as Promise<any>;
    }
    // updateStatus(param:any,entity:any): Promise<any>{
    //     const url = this.setUrlParam(param,this.urls.put);
    //     return this.put(url,this.header,entity) as Promise<any>;
    // }
    updateStatus(blogPostid:string, uid:string, status:any, entity: any): Promise<any> {
        const url = this.setUrlParam(`${blogPostid}/${status}?uId=${uid}`, this.urls.put)
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    updateBlog(blogPostid:string, entity: any): Promise<any> {
        const url = this.setUrlParam(`${blogPostid}`, this.urls.put)
        return this.putQuery(url, this.header, entity) as Promise<any>;
    }
    checkCustomUrl(param: any): Promise<any> {
        // const url = this.setUrlParam(param, "");
        return this.fetch(HttpMethod.Post, param, this.header) as Promise<any>;
    }
}